import { useSelector } from "react-redux"

type questionDescription ={
  skipForNow?:boolean,
  description?:boolean
}

export default function QuestionDescription(props:questionDescription) {
    const {questionnaire, currentQuestion} = useSelector((state: any) => state.steps)
    const questionsWithDescription = [2, 3, 5, 6, 7, 8, 11, 12]
    let type = Object.keys(questionnaire)[currentQuestion]
    return (
        <div
            className={currentQuestion !== 10 ? "pt-6 short:pt-4 flex flex-col gap-3 "  : "flex flex-col gap-3"}>
            <div className="basis-3/4">
            <h2 className="md:custom-h1 custom-h2 text-left text-[#909090] pt-3 md:pt-2 md:text-left">{questionnaire[type]?.Title}</h2>
          </div>
            <div className="text-left  custom-h2 md:custom-h1 md:text-left text-primary text-2xl  md:pt-0">{questionnaire[type]?.Question}</div>
            {props.skipForNow &&
            <div>SKIP FOR NOW</div>
            }
            {questionsWithDescription.includes(currentQuestion) &&
            <div className="custom-text text-left text-base text-[#595959]">{questionnaire[type]?.Description}</div>
            }
        </div>
    )
}
