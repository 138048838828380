import {setIsLoggedIn, setLoading, setUserProfileData} from "../slices/stepSlice";
import { store} from "../store";
import {get, post} from "./common";
import {
    editMotivationUser,
    getRatingDetailsUrl,
    setRatingUrl,
    userDetailsUrl,
    userStatDetails
} from "./constants";
import {setFeelingsData, setSessionExpired, setUserStatData} from "../slices/dashboardSlice";
const dispatch = store.dispatch;

export const getUserDetails=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(userDetailsUrl, payload);
            if (response.status === 200) {
                localStorage.setItem("email", response?.data.response[0]?.email)
                localStorage.setItem("userEmail", response?.data.response[0]?.email)
                dispatch(setUserProfileData(response?.data.response[0]))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            if(e.request.status === 401){
                localStorage.setItem('loggedIn', 'false')
                dispatch(setSessionExpired(true))
                dispatch(setIsLoggedIn(false))
            }
            reject(e?.response.data.signupResult);
        }
    })
}

export const getRatingDetails=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            const currentDate = new Date();
            const timeZone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
            let response:any = await get(getRatingDetailsUrl, {params:{userId: payload, currentDate:currentDate, timeZone:timeZone}});
            if (response.status === 200) {
                dispatch(setFeelingsData(response.data.result))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            if(e.request.status === 401){
                localStorage.setItem('loggedIn', 'false')
                dispatch(setSessionExpired(true))
                dispatch(setIsLoggedIn(false))
            }
            reject(e);
        }
    })
}

export const setFeelingImage=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            const currentDate = new Date();
            const timeZone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
            let response:any = await get(setRatingUrl, {params:{userId: payload.userId, feeling_id:payload.ratingId, currentDate:currentDate, timeZone:timeZone}});
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))  
            resolve(response)    
        } catch (e:any) {
            dispatch(setLoading(false));
            if(e.request.status === 401){
                localStorage.setItem('loggedIn', 'false')
                dispatch(setSessionExpired(true))
                dispatch(setIsLoggedIn(false))
            }
            reject(e);
        }
    })
}
export const editMotivationDetails=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(editMotivationUser, payload);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            if(e.request.status === 401){
                localStorage.setItem('loggedIn', 'false')
                dispatch(setSessionExpired(true))
                dispatch(setIsLoggedIn(false))
            }
            reject(e?.response.data.signupResult);
        }
    })
}


export const getUserStatDetails=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await get(userStatDetails, {params:{userId: payload}});
            if (response.status === 200) {
                dispatch(setUserStatData(response?.data?.response[0]))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            if(e.request.status === 401){
                localStorage.setItem('loggedIn', 'false')
                dispatch(setSessionExpired(true))
                dispatch(setIsLoggedIn(false))
            }
            reject(e);
        }
    })
}