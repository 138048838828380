import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvents } from "../../common/utils/mixpanel";
import { saveAnswer } from "../../slices/stepSlice";
import { trackEventsPendo } from "../../common/utils/pendo";

type ageGender ={
  showIcon : boolean
}
export default function AgeGender(props:ageGender) {
  const dispatch = useDispatch();
  const {questionnaire,userChoice, currentQuestion } = useSelector((state: any) => state.steps);
  let type = Object.keys(questionnaire)[currentQuestion];

  function handleClick(item: any) {
    let response = {};
    response = {
      serverValue: currentQuestion === 0? item.name:item.id,
      selection: item.name,
    };
    trackEvents(currentQuestion === 0 ? "question_first_gender_selected" :  "question_second_age_selected",{"answer":item.name})
    trackEventsPendo(currentQuestion === 0 ? "question_first_gender_selected" :  "question_second_age_selected",{"answer":item.name})
    dispatch(saveAnswer(response));
  }

  return (
    <div>
      <RadioGroup
        value={
          currentQuestion === 0
            ? userChoice.gender["selection"]
            : userChoice.age["selection"]
        }
      >
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 md:mt-14 mt-10 short:mt-6">
          {questionnaire[type].Options.map(
            (item: any, index: any, array: any[]) => {
              if (index == array.length - 1 && array.length % 2 !== 0) {
                return (
                  <RadioGroup.Option
                    key={index}
                    value={item.name}
                    className="col-span-full lg:col-span-1"
                    onClick={() => handleClick(item)}
                  >
                    {({ checked }) => (
                      <div
                        className={
                          checked
                            ? "col-span-full  h-20 md:h-[148px] border-[1.5px] border-secondary rounded-lg flex items-center py-4 px-6"
                            : "h-20 md:h-[148px] flex  items-center border border-[#BFBFBF] rounded-lg py-4 px-6"
                        }
                      >
                        <div className="flex gap-3 w-full lg:justify-center ">
                          {props.showIcon && (
                            <img
                              src={item.imageUrl}
                              alt="icon"
                              className={"block"}
                            />
                          )}

                          <span
                            data-gender={item.value}
                            id={item.id}
                            className={
                              checked
                                ? "custom-text block"
                                : "custom-text block"
                            }
                          >
                            {item.name}
                          </span>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                );
              } else {
                return (
                  <RadioGroup.Option
                    value={item.name}
                    key={index}
                    onClick={() => handleClick(item)}
                  >
                    {({ checked }) => (
                      <div
                        className={
                          checked
                            ? "h-[85px] md:h-[148px]  border-[1.5px] border-secondary rounded-lg flex justify-center items-center py-[16px] px-[22px] short:px-[20px] "
                            : "h-[85px] md:h-[148px] flex justify-center items-center border border-[#BFBFBF] rounded-lg py-[16px] px-[22px] short:px-[20px]"
                        }
                      >
                        <div className="flex gap-2">
                  
                          {props.showIcon  && (
                            <img
                              src={item.imageUrl}
                              alt="icon"
                              className={"block"}
                            />
                          )}

                          <span
                            data-gender={item.value}
                            id={item.id}
                            className={
                              checked
                                ? "custom-text block"
                                : "custom-text block"
                            }
                          >
                            {item.name}
                          </span>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                );
              }
            }
          )}
        </div>
      </RadioGroup>
    </div>
  );
}
