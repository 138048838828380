import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/spinner/Loader";
import { saveAnswer, setFlavours } from "../../slices/stepSlice";
import {store} from "../../store";
import {getFilteredProducts} from "../../api/onboarding";

export default function ProductOptions() {
    const dispatch = useDispatch();
    const [isLoading,setIsLoading]= useState(false)
    let errorMessage = ""
    let productRequest: any = {};
    const {questionnaire, currentQuestion, userChoice, userProfileData} = useSelector((state: any) => state.steps);
    let type = Object.keys(questionnaire)[currentQuestion];
    useEffect(()=>{
        async function fetchData() {
            if (currentQuestion === 9) {
                let selectedOptions = store.getState().steps.userChoice;
                productRequest.phase = selectedOptions.maintenance.serverValue;
                productRequest.currentUsage = selectedOptions.duration.serverValue;
                productRequest.productForm = selectedOptions.productForm.serverValue;
                productRequest.productFlavor = "";
                productRequest.isFinal = false;
                productRequest.user_id = userProfileData?.id
                setIsLoading(true)
                try {
                    const res: any = await getFilteredProducts(productRequest)
                    let flavours: any[] = [];
                    let option: any[] = [];
                    let count = 0;
                    if(res.status === 200 && res.data.result.length > 0) {
                        res.data.result.forEach((item: any) => {
                            let radioOptions = {id: count, value: "", serverValue: ""};
                            let flavor = item.flavor.trim();
                            if (!flavours.includes(flavor)) {
                                flavours.push(flavor);
                                radioOptions.id = count;
                                radioOptions.value = item.flavor;
                                radioOptions.serverValue = item.flavor;
                                option.push(radioOptions);
                                count += 1;
                            }
                        })
                        dispatch(setFlavours(option))
                        setIsLoading(false)
                    }
                } catch
                    (error: any) {
                    errorMessage = error.message
                    setIsLoading(false)
                }
            }
        }
        fetchData()
    },[currentQuestion])
    function handleClick(item: any) {
      let response = {};
      response = {
        serverValue: item.serverValue,
        selection: item.value,
      };
      dispatch(saveAnswer(response));
    }
    let options = "";
    if (currentQuestion === 7) {
      options = userChoice.productForm["selection"];
    }
    if (currentQuestion === 8) {
      options = userChoice.duration["selection"];
    }
    if(currentQuestion === 9){
      options = userChoice.flavour["selection"];
    }
    if(isLoading){
      return(
        <div className="h-full flex justify-center items-center">
          <Loader/>
        </div>
      )
    }
    if(errorMessage.length > 0){
      return(
        <div className="h-full flex justify-center items-center">
          {errorMessage}
        </div>
      )
    }
    return (
        <RadioGroup value={options}>
          <div className="grid grid-cols-1 gap-2">
            {questionnaire[type].Options.map((item: any, index: number) => {
            return(
                <RadioGroup.Option
                  value={item.value}
                  key={index}
                  onClick={() => handleClick(item)}
                >
                  {({ checked }) => (
                    <div
                      className={
                        checked
                          ? "h-[58px] border-[1.5px] border-secondary rounded-lg flex justify-left items-center py-[16px] px-[24px]"
                          : "h-[58px] flex justify-left items-center border border-[#BFBFBF] rounded-lg py-[16px] px-[24px]"
                      }
                    >
                      <div className="flex gap-2">
                        <input
                          type="radio"
                          onChange={() => !checked}
                          checked={checked}
                          className="block h-[20px] w-[20px] accent-secondary"
                        />
                        <span
                          id="0"
                          className={
                            checked ? "custom-text block" : "custom-text block"
                          }
                        >
                          {item.value}
                        </span>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
                
              );
            })}
          </div>
        </RadioGroup>
    );
}
