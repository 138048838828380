import DashboardHeader from "../dashboard/DashboardHeader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addUserFriends, removeUserFriends, sendProgressMail } from "../../api/onboarding";
import add from "../../assets/icons/add.svg";
import { userEmailIdRegex, userNameRegex } from "../../common/utils/Constants";
import { getUserDetails } from "../../api/dashboard";
import { CustomButton } from "../../common/CustomButton";
import { ROUTES } from "../../routes";
import './index.css'
import { InputField } from "../../common/InputField";
import mixpanel from "mixpanel-browser";
import deleteIcon from '../../assets/icons/delete.svg'
import badgebg from '../../assets/images/badgebg.png'
import { Dialog } from "@headlessui/react";
import { trackEvents } from "../../common/utils/mixpanel";
import { Events } from "../../common/Mixpanel_Events_constant";
import { trackEventsPendo } from "../../common/utils/pendo";

const MyContacts = () => {
    const navigate = useNavigate();
    const { userProfileData } = useSelector(
        (state: any) => state.steps
    );
    // const { userStatData } = useSelector(
    //     (state: any) => state.steps
    // );
    const userStatData = useSelector(((state: any) => state.dashboard.userStatData))

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [showInputs, setShowInputs] = useState(false);
    const [sharedProgressOpen, setSharedProgressOpen] = useState(false)
    const [removeContactOpen, setRemoveContactOpen] = useState(false)
    const [badgeProgressOpen, setbadgeProgressOpen] = useState(false)
    let [contactsOpen, setContactsOpen] = useState(false);
    let [removeMember, setRemoveMember] = useState()
    let [shareMembers, setShareMembers] = useState([])
    const [loading, setLoading] = useState(false)
    console.log("userProfileData", userStatData);


    useEffect(() => {
        trackEvents('on_my_contacts')
        trackEventsPendo('on_my_contacts')
        let addContact = localStorage.getItem("addContact")
        if (addContact == "true") {
            localStorage.removeItem("addContact")
            setContactsOpen(true)
        }

        if((userProfileData?.UserFriends).length == 0) {
            setContactsOpen(true)
        }
    }, [])

    const handleNameChange = (e: any) => {
        const result: boolean = userNameRegex.test(e.target.value);
        if (e.target.value === "") {
            setName("");
            setError("");
        } else if (result) {
            setName(e.target.value);
            setError("");
        }
    }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
        setError("");
    }

    const handleAddClick = () => {
        setShowInputs(true)
    }

    const addMember = async () => {
        if (email && name) {
            trackEvents('added_new_contacts')
            trackEventsPendo('added_new_contacts')
            const result: boolean = userEmailIdRegex.test(email);
            if (result) {
                const isExist = userProfileData?.UserFriends?.find((memberDetail: any) => memberDetail.email === email)
                if (isExist) {
                    setError("Contact's Email already exist.");
                } else {
                    setEmail("");
                    setName("");
                    await addUserFriends({ userId: userProfileData?.id, memberDetails: [{ "name": name, "email": email }] })
                    trackEvents(Events.CONTACTS_ADDED)
                    trackEventsPendo(Events.CONTACTS_ADDED)
                    getUserDetails({ email_id: userProfileData?.email })
                    setContactsOpen(false)
                    setShowInputs(false)
                }
            } else {
                setError("Invalid Contact's Email.");
            }
        } else {
            setError("Contact's Name & Email is required.");
        }
    }

    const handleRemoveClick = async () => {
        let selectedMember = removeMember || { email: '' }
        trackEvents('removed_existing_contacts')
        trackEventsPendo('removed_existing_contacts')
        await removeUserFriends({ userId: userProfileData?.id, email: selectedMember?.email })
        getUserDetails({ email_id: userProfileData?.email })
        setShowInputs(false)
    }

    const handleShareProgress = async () => {
        trackEvents('shared_progress_with_contacts')
        trackEventsPendo('shared_progress_with_contacts')
        if (shareMembers.length > 0) {
            let result: any = await sendProgressMail(shareMembers)
            // let result: any = await sendProgressMail(userProfileData?.UserFriends)
            if (result.status === 200) {
                // navigate(ROUTES.HOME)
                setSharedProgressOpen(true)
            }
            setLoading(false)
        }
    }

    return <div className={"h-full bg-white"}>
        <div className="myContactsDashboardContainer h-[19%]">
            <DashboardHeader back={true} title="My Contact" handleBack={() => {
                navigate(ROUTES.HOME)
            }} />
        </div>
        <div className="flex grow overflow-scroll flex-col h-[81%] pb-[160px]">
        <div className="px-4 mt-10 ">
            <div className="font-medium text-2xl text-left text-slate-700 font-[Helvetica-Neue]">
                Share progress with Friends & Family
            </div>
            {/* <p className="custom-h3 pageHeaderText">
                {'My Contacts'}
            </p> */}
        </div>
        <div className="px-4 mt-2">
            <p className="font-normal text-[#595959] text-sm short:text-xs font-[Helvetica-Neue]">
                Your accomplishments are worth celebrating. Share your progress with your contacts.
            </p>
        </div>
        {/* <hr /> */}
        <div className="px-2 pt-4" style={{ borderBottom: '1px solid #d9d9d9', marginLeft: '1 rem', width: '100%' }}> </div>

        <div
            className="pt-4 flex justify-start px-4"
            onClick={() =>
                setContactsOpen(true)
            }
        >
            <img src={add} alt="add" className="block w-[20px]" />
            <h3 className="subtitle-2 text-xl text-secondary  px-[10px] short:text-base">Add Contact</h3>
        </div>

        <div className="pt-6 px-4">
            <p className="menuSubtitle text-[#595959]">
                Select contacts to share progress
            </p>
        </div>

        {/* <div className="flex flex-col gap-4">
            <div className="px-4 mr-10 flex">
                <div id="1" className="border border-[#BFBFBF]  rounded-lg pt-3 px-6 pb-2 short:px-4 ">
                    <div className="flex justify-center h-full gap-2">
                        <div className="flex flex-col justify-center items-center  pt-3  gap-4">
                            <div><input type="checkbox" className=" accent-secondary w-[18px] h-[18px]" /></div>
                        </div>
                        <div className="flex flex-col justify-start items-center">
                            <div className="pl-2 text-left">
                                <h3 className="custom-subtitle text-lg text-[#002744]">Mike</h3>
                                <p className="custom-text text-left text-sm text-[#8C8C8C] "> mikeross@gmail.com </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-10 w-10 short:h-8 short:w-8">
                    <img src={deleteIcon} alt="icon" />
                </div>
            </div>
        </div> */}

        <div className="flex flex-col gap-4  mt-2">
            {/* <div className="myContactsContainer"> */}
            {[...userProfileData?.UserFriends]?.sort((a: any, b: any) => {
                return a.id - b.id;
            })?.map((member: any, index: any) => {
                return (
                    // <div className="py-[15px]">
                    //     <div className="flex">
                    //         <h2
                    //             className="custom-subtitle-2 text-[#002744]"
                    //             style={{ width: "10%" }}
                    //         >
                    //             {index + 1 + "."}
                    //         </h2>
                    //         <h2
                    //             className="custom-subtitle-2 text-[#002744]"
                    //             style={{ width: "80%" }}
                    //         >
                    //             {member.name}
                    //         </h2>
                    //         <div
                    //             className="myContactIconContainer"
                    //             style={{ width: "10%" }}
                    //             onClick={() => handleRemoveClick(member)}
                    //         >
                    //             <img src={remove} alt="Remove" className="block w-[15px]" />
                    //         </div>
                    //     </div>
                    //     <div style={{ width: "100%" }}>
                    //         <h2
                    //             className="custom-smallText text-[#8C8C8C]"
                    //             style={{ paddingLeft: "10%" }}
                    //         >
                    //             {member.email}
                    //         </h2>
                    //     </div>
                    // </div>
                    <div className="flex items-center px-4">
                        <div id={index} className="border border-[#BFBFBF]  rounded-lg py-2 w-[300px]">
                            <div className="flex justify-left h-ful gap-2 px-2 w-[300px]">
                                <div className="flex flex-col justify-center items-center">
                                    <div><input type="checkbox" className=" accent-secondary w-[18px] h-[18px]" onChange={(e) => {
                                        // console.log(e.target.checked, member);
                                        if (e.target.checked == true) {
                                            if (shareMembers.length > 0) {
                                                setShareMembers([...shareMembers, member])
                                                // console.log(shareMembers);
                                            } else {
                                                let members = [{ ...member }]
                                                setShareMembers(members)
                                                // console.log(members, shareMembers);
                                            }
                                        } else {
                                            const filteredMembers = shareMembers.filter((item) => item.id !== member?.id);
                                            setShareMembers(filteredMembers)
                                            // console.log(shareMembers);
                                        }
                                    }} /></div>
                                </div>
                                <div className="flex flex-col justify-start items-center pb-2">
                                    <div className="pl-2 text-left">
                                        <h3 className="custom-subtitle text-lg text-[#002744]">{member.name}</h3>
                                        <p className="custom-text text-left text-sm text-[#8C8C8C] "> {member.email} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-10 w-10 pl-4 short:pl-1 flex justify-center short:h-8 short:w-8">
                            <img src={deleteIcon} alt="icon" onClick={() => { setRemoveMember(member); setRemoveContactOpen(true) }} />
                        </div>
                    </div>
                )
            })}
            {/* {!showInputs && userProfileData?.UserFriends?.length < 5 && <div
                className="myContactIconContainer"
                onClick={() => handleAddClick()}
            >
                <h3 className="subtitle-2 text-secondary  px-[10px]">Add</h3>
                <img src={add} alt="add" className="block w-[15px]" />
            </div>}
            {showInputs && userProfileData?.UserFriends?.length < 5 && (
                <div>
                    <div className="flex">
                        <h2
                            className="myContactNumber custom-subtitle"
                            style={{ width: "10%" }}
                        >
                            {userProfileData?.UserFriends?.length + 1 + "."}
                        </h2>
                        <div className="myContactInput">
                            <InputField
                                type="text"
                                charLength={15}
                                inputStyleclassName="myContactInputDiv rounded-full"
                                placeholderText="Contact’s Name"
                                value={name}
                                handleChange={(e: any) => handleNameChange(e)}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <h2
                            className="myContactNumber custom-subtitle"
                            style={{ width: "10%" }}
                        />
                        <div className="myContactInput">
                            <InputField
                                type="email"
                                inputStyleclassName="myContactInputDiv rounded-full"
                                placeholderText="Contact’s Email"
                                value={email}
                                handleChange={(e: any) => handleEmailChange(e)}
                            />
                        </div>
                    </div>
                    <div className="errorContainer">
                        <h5 className="text-secondary custom-text">{error}</h5>
                    </div>

                    <div className="myContactIconContainer py-[15px]">
                        <h3 className="subtitle-2 myContactsCancelBtn" onClick={() => {
                            setShowInputs(false)
                            setEmail("")
                            setName("")
                            setError("")
                        }}><i className="fa-solid fa-xmark" /> Cancel</h3>
                        <h3 className="subtitle-2 text-[#FF0000]" onClick={() => addMember()}><i
                            className="fa-solid fa-check" /> Done</h3>
                    </div>
                </div>
            )} */}
        </div>
        </div>
        <div className="myContactsBottomContainer">
            <div className="pl-3 pt-4 short:pt-1" style={{ borderBottom: '1px solid #d9d9d9', marginLeft: '1 rem', width: '100%' }}> </div>
            <div className="flex items-center">
                <div className="flex justify-start px-3">
                    <img className="w-20" src={userStatData?.current_badge?.batchUrl} alt="badge" />
                </div>
                <div className="justify-center px-2">
                    <h3 className="custom-subtitle text-lg text-[#002744]">Sharing Badge</h3>
                    <p className="custom-text text-left text-sm text-[#8C8C8C] "> {userStatData?.current_badge?.badgeInfo} </p>
                </div>

                <CustomButton label={'View'}
                    buttonContainerClass="flex justify-end right-6 fixed"
                    buttonStyleClass="subtitle-2 text-lg text-secondary  px-[10px]"
                    handleClick={() => setbadgeProgressOpen(true)} />

                {/* <div className="flex justify-end right-6 fixed" >
                    <h3 className="subtitle-2 text-lg text-secondary  px-[10px]">View</h3>
                </div> */}
            </div>
            <CustomButton label={'Share'}
             isDisabled={!shareMembers?.length||loading}
                buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1"
                buttonStyleClass="custom-subtitle-2 py-2 px-6 text-white bg-secondary border w-40  border-secondary rounded-full disabled:bg-[#E4E4E4] disabled:text-[#434343] disabled:border-[0px]"
                handleClick={() => {handleShareProgress()}} />
        </div>

        {/* Shared progress */}
        <Dialog
            open={sharedProgressOpen}
            onClose={() => setSharedProgressOpen(false)}
            className="relative z-50 bg-blue-100"
        >
            <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
                <Dialog.Panel className="w-full max-w-sm rounded bg-white">
                    <div className="flex h-full flex-col items-center">
                        <h1 className="text-lg text-left pt-10 pb-2 px-8">Progress badge shared successfully!</h1>
                        <p className="text-left text-[#8C8C8C] pt-2 text-sm font-normal font-[Helvetica-Neue] px-8"> We have emailed your progress to your contact. Keep progressing further and share your accomplishments!</p>

                        <div className="pt-5">
                            <CustomButton label={'Back to My Journey'}
                                buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1"
                                buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border border-secondary rounded-full"
                                handleClick={() => { setSharedProgressOpen(false); navigate(ROUTES.DASHBOARD) }} />
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>

        {/* Remove Contact */}
        <Dialog
            open={removeContactOpen}
            onClose={() => setRemoveContactOpen(true)}
            className="relative z-50 bg-blue-100"
        >
            <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
                <Dialog.Panel className="w-full max-w-sm rounded bg-white">
                    <div className="flex h-full flex-col items-center">
                        <h1 className="text-lg text-left pt-10 pb-2 px-8">Are you sure you want to remove contact?</h1>
                        <p className="text-left text-[#8C8C8C] pt-2 text-sm font-normal font-[Helvetica-Neue] px-8"> Removing contact is irreversible. You can add new contacts.</p>

                        <div className="pt-5">
                            <div className="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1">
                                <button className="py-2 px-6 text-secondary w-40 font-medium" onClick={() => setRemoveContactOpen(false)}>Cancel</button>
                            </div>
                            <CustomButton label={'Yes'}
                                buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1"
                                buttonStyleClass="custom-subtitle-2 w-40 py-2 px-6 text-secondary border border-secondary rounded-full"
                                handleClick={() => { setRemoveContactOpen(false); handleRemoveClick() }} />
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>

        {/* badge progress */}
        <Dialog
            open={badgeProgressOpen}
            onClose={() => setbadgeProgressOpen(true)}
            className="relative z-50 bg-blue-100"
        >
            <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
                <Dialog.Panel className="w-full max-w-sm rounded bg-white" style={{ backgroundImage: `url(${badgebg})` }}>
                    <div className="flex h-full flex-col items-center">
                        <div className="flex justify-center px-4 pt-8">
                            <img className="w-20" src={userStatData?.current_badge?.batchUrl} alt="badge" />
                            {/* <img src={badge} alt="badge" /> */}
                        </div>
                        <h1 className="text-lg text-center pb-2 px-8">Zero Nicotine Badge</h1>
                        <p className="text-center text-[#8C8C8C] text-xs font-normal pb-8 font-[Helvetica-Neue] px-8"> {userStatData?.current_badge?.badgeUrl} by {userProfileData?.user_name} who is smoke free for {userStatData?.current_badge?.days} days.</p>

                        <button className="text-secondary font-medium" onClick={() => setbadgeProgressOpen(false)}>Dismiss</button>

                        {/* <div className="pt-5">
                            <div className="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1">
                                <button className="py-2 px-6 text-secondary w-40 font-medium" onClick={() => setSharedProgressOpen(false)}>Cancel</button>
                            </div>
                            <CustomButton label={'Yes'}
                                buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1"
                                buttonStyleClass="custom-subtitle-2 w-40 py-2 px-6 text-secondary border border-secondary rounded-full"
                                onClick={() => addMember()} />
                        </div> */}
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>

        {/* Contacts modal */}
        <Dialog
            open={contactsOpen}
            onClose={() => setContactsOpen(false)}
            className="relative z-50 bg-blue-100"
        >
            <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
                <Dialog.Panel className="h-2/3 short:h-4/5 w-full max-w-sm rounded bg-white">
                    <div className="flex h-full flex-col items-center">

                        {/* <JourneyInfo /> */}
                        <h1 className="text-lg text-left pt-10 pb-2 px-8">Add Contact</h1>
                        <p className="text-left text-[#8C8C8C] pt-5 text-sm font-normal font-[Helvetica-Neue] px-8"> Your accomplishments are worth celebrating.<br /> Add contacts to share your progress with them.</p>

                        <div className="pt-5">
                            <div className="flex">
                                <h2
                                    className="myContactNumber custom-subtitle"
                                    style={{ width: "10%" }}
                                >
                                    {/* {userProfileData?.UserFriends?.length + 1 + "."} */}
                                </h2>
                                <div className="myContactInput">
                                    <InputField
                                        type="text"
                                        inputContainerClass="flex justify-end items-center max-w-[345px] w-full m-auto"
                                        charLength={15}
                                        inputStyleClass="myContactInputDiv rounded-full"
                                        placeholderText="Contact’s Name"
                                        value={name}
                                        handleChange={(e: any) => handleNameChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="flex">
                                <h2
                                    className="myContactNumber custom-subtitle"
                                    style={{ width: "10%" }}
                                />
                                <div className="myContactInput">
                                    <InputField
                                        type="email"
                                        inputContainerClass="flex justify-end items-center max-w-[345px] w-full m-auto"
                                        inputStyleClass="myContactInputDiv rounded-full"
                                        placeholderText="Contact’s Email"
                                        value={email}
                                        handleChange={(e: any) => handleEmailChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="errorContainer">
                                <h5 className="text-secondary custom-text">{error}</h5>
                            </div>

                            <CustomButton label={'Add Contact'}
                                buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1"
                                buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border w-40 border-secondary rounded-full"
                                handleClick={() => addMember()} />

                            <div className="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1">
                                <button className="py-2 px-6 text-secondary w-40 font-medium" onClick={() => setContactsOpen(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>


        {/* <div className="myContactsButtonContainer">
            <div className="myContactsButtonDiv">
                <CustomButton label={'Share Current Progress'}
                    buttonStyleclassName="custom-subtitle-2 py-2 px-6 text-secondary border w-[220px] border-secondary rounded-full"
                    handleClick={() => {
                        handleShareProgress()
                    }}
                />
            </div>
        </div> */}
    </div>
}
export default MyContacts