import { StartRating } from "../../types/product";
export default function Rating(props:StartRating) {
    let tenthDigit = Math.floor(props.startRating);
    let decimal = ((props.startRating * 10) % 10) * 10;
    const rating = [];
    for (let index = 0; index < tenthDigit; index++) {
      rating.push(
        <svg
          key={index}
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 0.5L4.14538 4.45143L0 5.08143L3 8.15857L2.29076 12.5L6 10.4514L9.70924 12.5L9 8.15857L12 5.08571L7.85462 4.45143L6 0.5Z"
            fill="#FEC30D"
          />
        </svg>
      );
    }
    if (decimal > 0) {
      rating.push(
        <svg
          key={decimal}
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="half">
              <stop offset="0%" stopColor="#FEC30D" />
              <stop
                offset={((props.startRating * 10) % 10) * 10 + "%"}
                stopColor="#FEC30D"
              />
              <stop
                offset={((props.startRating * 10) % 10) * 10 + "%"}
                stopColor="white"
              />
              <stop offset="100%" stopColor="white" />
            </linearGradient>
          </defs>
          <path
            d="M6 0.5L4.14538 4.45143L0 5.08143L3 8.15857L2.29076 12.5L6 10.4514L9.70924 12.5L9 8.15857L12 5.08571L7.85462 4.45143L6 0.5Z"
            fill="url(#half)"
          />
        </svg>
      );
    }
    return (
      <>
        <div className="flex">{rating}</div>
      </>
    );
}
