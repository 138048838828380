import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import DashboardHeader from "./dashboard/DashboardHeader";
import {ROUTES} from "../routes";
import {CustomButton} from "../common/CustomButton";
import Step1 from "./../assets/images/Step1.png";
import Step2 from "./../assets/images/Step2.png";
import Step3 from "./../assets/images/Step3.png";
import Step4 from "./../assets/images/Step4.png";
import Step5 from "./../assets/images/Step5.png"
import { trackEvents } from "../common/utils/mixpanel";
import { trackEventsPendo } from "../common/utils/pendo";
const QuickAccessTutorial=()=> {
    const navigate = useNavigate();
    useEffect(()=>{
        trackEvents('on_quick_access_tutorial')
        trackEventsPendo('on_quick_access_tutorial')
    },[])
    const tutorialSteps = [
        {
            step: "Step 1", content: <div>
                <p className="custom-bodyText text-[#434343]">{"Tap on"}</p>
                <p className="subtitle-2 text-[#434343]">{"“Share button“"}</p>
            </div>, url: Step1
        },
        {step: "Step 2", content: <p className="custom-bodyText text-[#434343]">{"Swipe up"}</p>, url: Step2},
        {
            step: "Step 3", content: <div>
                <p className="custom-bodyText text-[#434343]">{"Tap on"}</p>
                <p className="subtitle-2 text-[#434343]">{"“Add to Home Screen “"}</p>
            </div>, url: Step3
        },
        {
            step: "Step 4", content: <div>
                <p className="custom-bodyText text-[#434343]">{"Tap on"}</p>
                <p className="subtitle-2 text-[#434343]">{"“Add“"}</p>
            </div>, url: Step4
        },
        {
            step: "Ta-da!", content: <div className="custom-bodyText text-[#434343]">
                {"Be a "}<b className="subtitle-2 text-[#434343]">{"HERO "}</b>{"at your convinence!"}
            </div>, url: Step5
        },
    ]
    return <div className="flex flex-col bg-sky-200 h-full relative">
        <DashboardHeader back={true} handleBack={() => {
            navigate(ROUTES.DASHBOARD)
        }} title={'Quick Access Tutorial'}/>
        <div className="bg-white h-full overflow-auto px-[20px]">
            <p className="custom-text text-[#595959] py-[20px]">{'Add website to iPhone app drawer for quick accessing'}</p>
            {tutorialSteps.map((step, index) => {
                return <div className="flex py-[10px]"
                            style={index !== tutorialSteps.length - 1 ? {borderBottom: '1px solid #D9D9D9'} : {}}>
                    <img className="h-[200px] w-[165px]" src={step.url}/>
                    <div className="px-[30px] flex flex-col justify-center">
                        <p className="custom-bodyText font-[500] text-[#8C8C8C]">{step.step}</p>
                        <p className="subtitle-2 text-[#434343]">{step.content}</p>
                    </div>
                </div>
            })}
            <CustomButton label={'Done'} handleClick={() => {
                navigate(ROUTES.DASHBOARD)
            }}/>
        </div>
    </div>
}
export default QuickAccessTutorial;