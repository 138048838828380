import Drawer from 'react-modern-drawer'
import progressArrows from "../../assets/images/progressArrows.svg"
import disabledLock from "../../assets/images/disabledLock.svg"
import close from "../../assets/icons/close.svg";
import SavingImage from "../../assets/icons/SavingImage.png";
import { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import './index.css'
import Card from "../../common/Card";
import { getFilteredProducts } from "../../api/onboarding";
import * as moment from "moment-timezone";
import Footer from '../../common/footer';

const UserStats = (props: any) => {
    const { showUserStatPopup,onDrawerClose } = props
    const { questionnaire, userProfileData } = useSelector(
        (state: any) => state.steps
    );
    const { userStatData } = useSelector(
        (state: any) => state.dashboard
    );

    const dashboardDate = userProfileData?.last_dashboard_checkin_date ? new Date(userProfileData?.last_dashboard_checkin_date) : new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const checkinDate = moment.tz(dashboardDate, timezone).format("ddd, MMM DD");

    // Remove this API call if product data is coming from same API
    const [productData, setProductData] = useState([])
    let errorMessage = ""
    let productRequest: any = {};
    useEffect(() => {
        async function fetchData() {
            try {
                if (questionnaire?.nicotineReplacement?.Options.find((f: any) => f.name === userProfileData.maintainance)?.serverValue === 'Maintenance') {
                    productRequest.phase = questionnaire.nicotineReplacement?.Options?.find((f: any) => f.name === userProfileData.maintainance)?.serverValue ?? '';
                    productRequest.currentUsage = questionnaire?.currentUsage?.Options?.find((f: any) => f.name === userProfileData.currentUsage)?.payloadValue ?? '';
                    productRequest.user_id = userProfileData?.id
                } else {
                    productRequest.phase = questionnaire?.nicotineReplacement?.Options?.find((f: any) => f.name === userProfileData.maintainance)?.serverValue ?? '';
                    productRequest.currentUsage = questionnaire?.duration?.Options?.find((f: any) => f.value === userProfileData.duration)?.serverValue ?? '';
                    productRequest.productForm = questionnaire?.productForm?.Options?.find((f: any) => f.value === userProfileData.productForm)?.serverValue ?? '';
                    productRequest.productFlavor = userProfileData?.flavour;
                    productRequest.isFinal = true;
                    productRequest.user_id = userProfileData?.id
                }
                const res: any = await getFilteredProducts(productRequest)
                if (res.status === 200) {
                    setProductData(res.data.result)
                }
            } catch (error: any) {
                errorMessage = error.message
            }
        }
        fetchData()
    }, [])

  
    return <div>
        <Drawer
            open={showUserStatPopup}
            onClose={() => onDrawerClose()}
            direction='bottom'
            size={600}
            style={{ borderRadius: '16px 16px 0px 0px' }}
        >
            <div className="py-[20px] h-full">
                <div>
                    <button
                        className="absolute right-4 top-4 bg-[#F0F0F0] h-[20px] w-[20px] rounded-2xl flex justify-center items-center"
                        onClick={() => onDrawerClose()}
                    >
                        <img src={close} alt="close" />
                    </button>
                </div>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <div className="pt-[16px] px-[21px]">
                        <p className="custom-h2">{`Hello ${userProfileData?.user_name}`}</p>
                        <p className="custom-smallText text-[#8C8C8C]">{`Last seen on ${checkinDate}`}</p>
                    </div>
                    <div className="px-[20px] py-[14px]">
                        <div
                            className="flex flex-col justify-center items-center border border-[#BFBFBF] rounded-lg py-[14px] px-[21px] ">
                            <div className="flex w-full justify-between">
                                <p className="custom-subtitle-2 text-primary">Current progress</p>
                                <p className="custom-subtitle-2 text-[#595959]">Next milestone</p>
                            </div>
                            <div className="flex w-full justify-between">
                                <div className="border border-[#BFBFBF] rounded-lg">
                                    <img src={userStatData?.current_badge?.batchUrl} className="h-[60px] w-[60px]" />
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                    <img src={progressArrows} className="h-[30px] w-[60px]" />
                                    <p className="custom-smallText text-[#8C8C8C]">{userStatData?.diffreence}</p>
                                </div>
                                <div className="flex justify-center items-center border border-[#BFBFBF] rounded-lg relative ">
                                    <img src={userStatData?.next_badge?.batchUrl} className="relative block h-[60px] w-[60px] rounded-lg" />
                                    <div
                                        className="absolute bg-white/30 h-[60px] w-[60px] flex justify-center items-center ">
                                        <img src={disabledLock} className="h-[17px] w-[13px] rounded-lg" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full justify-between">
                                <p>{userStatData?.current_badge?.batchName}</p>
                                <p className={"opacity-50"}>{userStatData?.next_badge?.batchName}</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-[8px] w-full bg-[#F5F5F5]" />
                    <div>
                        <div className="px-[24px] pt-[24px]">
                            <p className="custom-subtitle-2 text-[#002744]">Where are you in the journey?</p>
                        </div>
                        <div className="py-[10px] px-[21px]">
                            {userStatData?.journey[0]?.health_improvements?.title && <div className={"pt-[10px]"}>
                                <Card title={userStatData?.journey[0]?.health_improvements?.title}
                                    description={userStatData?.journey[0]?.health_improvements?.healthStat}
                                    img={userStatData?.journey[0]?.health_improvements?.batchUrl} />
                            </div>}
                            <div className={"pt-[10px]"}>
                                <Card title={userStatData?.journey[0]?.financial_savings?.title}
                                    description={userStatData?.journey[0]?.financial_savings?.finance_stat}
                                    img={SavingImage} />
                            </div>
                         
                        </div>
                    </div>
                    <Footer/>
                </div>
              
            </div>
        </Drawer>
    </div>
}
export default UserStats