import pin from "../../../assets/images/welcomscreenlogo.png";
import bgMap from '../../../assets/images/map2.svg'
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import Branding from "../../../common/header/Branding";
import { useEffect } from "react";
import Drawer from "react-modern-drawer";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../../common/utils/mixpanel";
import { trackEventsPendo } from "../../../common/utils/pendo";

export default function Journey() {
    const name = localStorage.getItem("userName")
    const navigate = useNavigate()

    function handleGetStarted() {
        trackEventsPendo('congratulations on starting')
        trackEvents('congratulations on starting')
        navigate(ROUTES.GETSTARTED)
    }   

    // useEffect(()=>{
    // mixpanel.time_event('left_Screen_2_contratulations_on_starting_your_jounery_to_freedom')
        
    // },[])

    return (
        <>
            <div className="h-full flex flex-col overscroll-auto md:border-2">
                <div style={{
                    background: `url(${bgMap})`, height: "100%", backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>
                    <Branding />

                    <div className="flex justify-center px-7">
                        <Drawer
                            open={true}
                            direction="bottom"
                            size={550}
                            style={{ borderRadius: "16px 16px 0px 0px" }}
                        >
                            <div className="flex justify-left px-8 ">
                                <p className="text-left subtitle text-[#002744]" style={{marginTop:'4%'}}><span style={{fontSize:'1.5rem'}}>Congratulations on starting your Journey to Freedom from Smoking!</span></p>
                            </div>
                            <div className="flex justify-left px-8 ">
                                <p style={{ lineHeight: '160.8%', marginBottom: '4%', marginTop: '4%' }}>
                                    With this app, you are putting the odds in your favour, helping you:
                                </p>
                            </div>
                            <div className="flex justify-center px-8">
                                <ul className="text-[#002744] text-[16px]" style={{ listStyle: 'disc', lineHeight: '160.8%', display: 'grid', position: 'inherit',    marginLeft: '14px' }}>
                                    <li style={{paddingBottom:'2%'}}>Keep track of your journey with daily check-ins and weekly milestones</li>
                                    <li style={{paddingBottom:'2%'}}>Gain insights of the real effects of quitting, like money saved, and health benefits</li>
                                    <li style={{paddingBottom:'2%'}}>Get support for dealing with the different aspects of quitting, like withdrawal symptoms</li>
                                    <li style={{paddingBottom:'2%'}}>Use our products to get rid of your craving</li>
                                </ul>
                            </div>

                            <div className="flex justify-center py-7 px-5" style={{bottom: 0, position:'relative'}}>
                                <button
                                    className=" subtitle-2 bg-secondary text-white py-2 px-6 short:px-4 rounded-full w-[340px] "
                                    onClick={handleGetStarted}>
                                    Next
                                </button>
                            </div>
                        </Drawer>
                    </div>
                </div>
            </div>
        </>
    )
}
