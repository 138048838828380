type Card = {
    img?:string,
    title:string,
    description?:string
}

export default function Card(props:Card) {
  return (
    <>
     <div className="border-[#D9D9D9] h-[126px] rounded-lg border-[1.5px] w-full">
          <div className="flex justify-evenly h-full items-center p-2">
            <div className="basis-1/4">
                {props.img && <img src={props.img} alt="image"/>}
            </div>
            <div className="flex flex-col basis-3/4 p-1  gap-1">
              <div>
                <h3 className="text-[18px] short:text-[16px] text-primary font-[Helvetica-Neue]">
                  {props.title}
                </h3>
              </div>
              <div>
                <p className="text-[13px] short:text-[11px] text-[#8C8C8C] font-[Helvetica-Neue]">
                  {props.description}
                </p>
              </div>
            </div>
          </div>
    </div>
    </>
  )
}
