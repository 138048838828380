import {combineReducers} from "@reduxjs/toolkit";
import stepSlice from "./stepSlice";
import dashboardSlice from "./dashboardSlice";
import articleSlice from "./articleSlice";

const appReducer = combineReducers({
    steps:stepSlice,
    dashboard:dashboardSlice,
    articleState:articleSlice
})

export const rootReducer = (state:any, action:any) => {
    if (action.type === 'RESET_STATE') {
        state = undefined
    }
    return appReducer(state, action)
}