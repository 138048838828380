import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Dialog } from "@headlessui/react";
import { resetState } from "./api/common";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "./common/CustomButton";
import { setSessionExpired } from "./slices/dashboardSlice";
import "./App.css";

import GetStarted from "./components/onboarding/GetStarted";
import GreetUser from "./components/onboarding/introScreens/GreetUser";
import InfoDisclaimer from "./components/onboarding/introScreens/InfoDisclaimer";
import Intro from "./components/onboarding/introScreens/Intro";
import Journey from "./components/onboarding/introScreens/journey";
import SplashCard from "./common/SplashCard";
import QuestionCard from "./components/onboarding/questionCard/QuestionCard";
import Stats from "./components/onboarding/Stats";
import SignUp from "./components/onboarding/SignUp";
import ProductDetails from "./common/productDetails/productDetails";
import Dashboard from "./components/dashboard/Dashboard";
import EarnBadge from "./components/onboarding/EarnBadge";
import LoginComponent from "./components/login/LoginComponent";
import Support from "./components/dashboard/Support";
import Achievements from "./components/dashboard/Achievements";
import Home from "./components/myAccount/Home";
import AboutMe from "./components/myAccount/AboutMe";
import TermsAndConditions from "./components/termsAndConditions";
import PrivacyPolicy from "./components/privacyPolicy";
import QuickAccessTutorial from "./components/quickAccessTutorial";
import ForgotPassword from "./components/login/ForgotPassword";
import ResetPassword from "./components/login/ResetPassword";
import ArticleDetails from "./components/dashboard/ArticleDetails";
import MyMotivations from "./components/dashboard/MyMotivations";
import Products from "./components/myAccount/Products";
import MyContacts from "./components/myAccount/MyContacts";
import Settings from "./components/myAccount/Settings";
import FAQ from "./components/FAQ";
import ChangePassword from "./components/myAccount/ChangePassword";
import MyPreference from "./components/myAccount/MyPreference";
import mixpanel from "mixpanel-browser";
import OptIn from "./components/onboarding/notifications/optin";
import Verification from "./components/onboarding/notifications/verification";
import GetOtp from "./components/onboarding/notifications/getotp";
import OtpSuccess from "./components/onboarding/notifications/otpsuccess";
import useWindowDimensions from "./common/customHooks/useWindowDimension";
import SmsTime from "./components/myAccount/Settings_for_sms_time";
import QuestionCardNew from "./components/onboarding/questionCard/QuestionCardNew";
import { withDevCycleProvider } from '@devcycle/react-client-sdk'
import QrDesktop from "./components/onboarding/QrDesktop";

const initialLoggedInState = localStorage.getItem('loggedIn') ?? false;

function source() {
  if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'Google';
  } else if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
    return 'Bing';
  } else if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
    return 'Yahoo';
  } else if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
    return 'Facebook';
  } else if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
    return 'Twitter';
  } else {
    return 'Other';
  }
}

function App() {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  let location = useLocation()
  const { height, width } = useWindowDimensions();
  const queryClient = new QueryClient()
  let [isLoading, setIsLoading] = useState(false);
  let [isError, setIsError] = useState("")
  const userLoggedIn = useSelector((state: any) => state.steps.isLoggedIn)
  const [showTimerExpiry, setShowTimerExpiry] = useState(false);
  let isMounted = true
  const { isSessionExpired } = useSelector(
    (state: any) => state.dashboard.isSessionExpired
  );

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    mixpanel.register_once({ "Source": source() });
    mixpanel.people.set_once({ "Source": source() });
    // mixpanel.identify("USER_DISTINCT_ID");
    window.pendo.initialize();

  }, [])

  useEffect(() => {
    if (isMounted && !userLoggedIn) {
      window.onpopstate = () => {
        if (location.pathname !== '/get-started') {
          dispatch(resetState())
          navigate(ROUTES.GETSTARTED);
        }
      };
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {

    if (location.pathname.includes('/login')) {
      sessionStorage.clear()
      localStorage.clear()
      dispatch(resetState())
    }
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem('loggedIn', `${userLoggedIn}`)
    if (!userLoggedIn) {
      if (!location.pathname.includes('/resetpwd') && location.pathname !== ROUTES.LOGIN) {
        navigate(ROUTES.NOTFOUND);
        sessionStorage.clear()
        localStorage.clear()
        dispatch(resetState())
      }
    }
  }, [userLoggedIn])


  useEffect(() => {
    if (isSessionExpired) {
      setShowTimerExpiry(true)
    }
  }, [isSessionExpired])


  useEffect(() => {
    console.log("PENDO", window.pendo)

  }, [])

  return (
    <>
        {isError.length > 0 && (
          <div className="container flex justify-center items-center text-secondary h-screen bg-white md:max-w-md md:h-[90vh] md:my-3 md:p-7 md:m-auto md:border-2 md:rounded-lg">
              {isError}
          </div>
        )}
        {isLoading && (
           <div className="container h-screen bg-secondary md:max-w-md md:my-3 md:h-[90vh] md:p-7 md:bg-white md:m-auto md:border-2 md:rounded-lg ">
           <SplashCard />
         </div>
        )}
        {showTimerExpiry && <Dialog open={showTimerExpiry}
                         onClose={() => {}}
                         className="absolute top-0 z-50 w-full md:w-96 md:mx-[40%]">
          <div
              className="fixed w-full md:w-96  h-full md:h-[80vh] md:mt-8 bg-black/30 "
              aria-hidden="true"
          >
            <Dialog.Panel className="relative">
              <div className="h-72 bg-[#F5F5F5] short:h-60 border rounded-lg shadow-md mx-5 my-[50%]">
                <div className="px-[30px] pt-[30px] flex flex-col justify-center items-center text-center">
                  <i className="fa-solid fa-triangle-exclamation text-[50px]"/>
                  <h3 className="pt-[30px]">Your session has expired. Please login again.</h3>
                </div>
                <CustomButton label={'Ok'}
                              buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border w-40 border-secondary rounded-full"
                              handleClick={() => {
                                setShowTimerExpiry(false)
                                navigate(ROUTES.LOGIN)
                                dispatch(setSessionExpired(false))
                              }}/>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
        } 
        <QueryClientProvider client={queryClient}>
        { isError.length === 0 &&
        <div style={{height:height||'100dvh'}}>
          <Routes>
            <Route path={ROUTES.ROOT} element={<Intro />}/>
            <Route path={ROUTES.GETSTARTED} element={<GetStarted />}/>
            <Route path={ROUTES.GREET} element={<GreetUser />}/>
            <Route path={ROUTES.INFODISCLAIMER} element={<InfoDisclaimer />}/>
            <Route path={ROUTES.QUESTIONCARD} element={<QuestionCardNew/>}/>
            <Route path={ROUTES.STATS} element={<Stats />}/>
            <Route path={ROUTES.SIGNUP} element={<SignUp />}/>
            <Route path={ROUTES.PRODUCTDETAILS} element={<ProductDetails />}/>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />}/>
            <Route path={ROUTES.EARNBADGE} element={<EarnBadge />}/>
            <Route path={ROUTES.SUPPORT} element={<Support />}/>
            <Route path={ROUTES.ACHIEVEMENTS} element={<Achievements />}/>
            <Route path={ROUTES.TERMSANDCONDITIONS} element={<TermsAndConditions />}/>
            <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />}/>
            <Route path={ROUTES.QUICKACCESSTUTORIAL} element={<QuickAccessTutorial />}/>
            <Route path={ROUTES.FORGOTPASSWORD} element={<ForgotPassword />}/>
            <Route path={ROUTES.RESETPASSWORD} element={<ResetPassword />}/>
            <Route path={ROUTES.MYMOTIVATIONS} element={<MyMotivations />}/>
            <Route path={ROUTES.LOGIN} element={<LoginComponent />}/>
            <Route path={ROUTES.ARTICLEDETAILS} element={<ArticleDetails/>}/>
            <Route path={ROUTES.HOME} element={<Home />}/>
            <Route path={ROUTES.PRODUCTS} element={<Products />}/>
            <Route path={ROUTES.ABOUTME} element={<AboutMe />}/>
            <Route path={ROUTES.MYCONTACTS} element={<MyContacts />}/>
            <Route path={ROUTES.CHANGEPASSWORD} element={<ChangePassword />}/>
            <Route path={ROUTES.MYPREFERENCES} element={<MyPreference />}/>
            <Route path={ROUTES.SETTING} element={<Settings />}/>
            <Route path={ROUTES.FAQ} element={<FAQ />}/>
            <Route path={ROUTES.NOTFOUND} element={<Intro />}/>
            <Route path={ROUTES.INTRO} element={<Intro />}/>
            <Route path={ROUTES.JOURNEY} element={<Journey />}/>
            <Route path={ROUTES.OPTIN} element={<OptIn />} />
            <Route path={ROUTES.VERIFICATION} element={<Verification />} />
            <Route path={ROUTES.GETOTP} element={<GetOtp />} />
            <Route path={ROUTES.OTPSUCCESS} element={<OtpSuccess />} />
            <Route path={ROUTES.SMS_TIME} element={<SmsTime />} />
            <Route path={ROUTES.DESKTOP_LANDING} element={<QrDesktop />} />
          </Routes>
        </div>
        }
      </QueryClientProvider>

    </>
  );
}

// export default App;
export default withDevCycleProvider({ sdkKey: process.env.REACT_APP_DEVCYCLE_ENV_KEY})(
  App,
)
