import logo from "../../assets/images/journey_logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import Branding from "../../common/header/Branding";
import { userNameRegex, userEmailIdRegex } from "../../common/utils/Constants";
import { CustomButton } from "../../common/CustomButton";
import { InputField } from "../../common/InputField";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import Footer from "../../common/footer";
import navlogo from "../../assets/images/nicoretteupNew.png";
import cvs_logo from "../../assets/images/nicoretteLogo.png";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import { setProspectUser } from "../../api/onboarding";
import { useDispatch } from "react-redux";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function GetStarted() {
  const isMobile = useCheckMobileScreen();
  const dispatch= useDispatch()
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [privacy, setPrivacy] = useState(false);
  useEffect(() => {
    trackEvents("on_get_started_screen");
    trackEventsPendo("on_get_started_screen");
  }, []);

  function handleLogin() {
    trackEvents("on_get_started_login_clicked");
    trackEventsPendo("on_get_started_login_clicked");
    navigate(ROUTES.LOGIN);
  }
  function handleChange(e: any) {
    const result: boolean = userNameRegex.test(e.target.value);
    if (e.target.value === "") {
      setName("");
      setError("");
    } else if (result) {
      setName(e.target.value);
      setError("");
    }
  }

  function handleEmailChange(e: any) {
    setEmail(e.target.value);
  }

  function getStarted() {
    localStorage.setItem("userName", name);
    localStorage.setItem("userEmail", email);

    const result: boolean = userEmailIdRegex.test(email);
    if (email.length > 0) {
      if (result) {
        trackEvents("Clicked_On_Setup_Profile");
        trackEventsPendo("Clicked_On_Setup_Profile");
        setProspectUser({"name":name, "email":email})
        navigate(ROUTES.GREET);
      } else {
        setError("email is not correct");
      }
    } else {
      navigate(ROUTES.GREET);
    }
  }
  return (
    <>
      {/* Main wrapper */}
      <div className="bg-primary-blue flex min-h-screen flex-col  justify-between ">
        {/* Logo Section */}
        {/* <Branding /> */}

        {/* nav for mobile */}
        <div className="flex sm:flex md:hidden  bg-custom-blue text-white h-[70px] px-5 justify-around items-center">
          <img src={navlogo} alt="cvs logo" className="h-[22px]" />
        </div>

        {/* nav for Desktop */}
        <div className="hidden px-10 md:flex  md:items-center md:justify-between bg-custom-blue text-white h-[80px]">
          <img src={navlogo} alt="Logo" />
        </div>

        {/* Main content */}
        <section className="flex flex-col justify-between gap-3 md:gap-20 xl:flex-row xl:px-36 xl:py-20 md:mt-16 xl:mt-0 md:hidden">
          {/* Wrapper for desktop version */}
          <div>
            <div className=" md:m-auto xl:m-0 xl:mt-10">
              <h1 className="hidden md:block lg:custom-text-lg md:custom-text-xl custom-text text-white lg:px-0 lg:py-0">
                We're ready to support you on your journey!
              </h1>
              <h2 className="custom-h2 text-slate-700 md:hidden">
                Let's get started
              </h2>
            </div>
          </div>
          {/* Form Area */}
          <div className="flex flex-col gap-2 short:gap-0">
            <h2 className="custom-h2 md:custom-h1 text-white hidden md:block">
              Let's get started
            </h2>
            <h1 className=" custom-text text-center text-slate-700 md:hidden">
              We're ready to support you on your journey!
            </h1>
            {/* desktop login link */}
            <p className="custom-text xl:text-start sm:text-center text-white hidden md:block">
              Already a user?
              <span
                className="custom-text text-secondary"
                onClick={handleLogin}
              >
                {" "}
                &nbsp; Login
              </span>
            </p>
            <div className="flex flex-col gap-2 mt-2">
              <InputField
                type="text"
                value={name}
                charLength={15}
                inputStyleClass="form-input text-slate-700"
                inputContainerClass={
                  "flex justify-end items-center max-w-[345px] w-full m-auto"
                }
                placeholderText="Enter your First Name"
                handleChange={(e: any) => handleChange(e)}
              />
              <InputField
                type="email"
                value={email}
                inputStyleClass="form-input text-slate-700"
                inputContainerClass={
                  "flex justify-end items-center max-w-[345px] w-full m-auto mt-1"
                }
                placeholderText="Enter your Email"
                handleChange={(e: any) => handleEmailChange(e)}
              />
              <div className="flex justify-center">
                <h5 className="custom-text text-secondary">{error}</h5>
              </div>

              <div className="flex justify-center px-7  mt-1">
                {/* <hr className="w-11/12 border-[#8C8C8C] md:hidden" /> */}
                <h1 className=" custom-text text-slate-700 md:hidden text-xs">
                  <></>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setPrivacy(e.target.checked);
                      localStorage.setItem(
                        "privacy",
                        e.target.checked.toString()
                      );
                    }}
                  />{" "}
                  &nbsp; Checking this box acknowledges that when you submit
                  this information, Haleon and its affiliated companies and
                  trusted partners may use your personal information for
                  purposes explained in our Privacy Notice including, but not
                  limited to, sending you emails from the Journey to Freedom
                  program. In the future, if you do not wish to receive this
                  information, you will be given the opportunity to opt-out
                </h1>
              </div>

              <CustomButton
              centeralign={true}
                label={"Set up My Profile"}
                buttonStyleClass="btn-primary w-full mt-2"
                handleClick={getStarted}
                isDisabled={!name || !email || !privacy}
              />

              <CustomButton
              centeralign={true}
                label={"Skip for now"}
                buttonStyleClass="text-secondary custom-text m-auto py-2 "
                handleClick={() => {
                  trackEvents("Clicked_On_Skip_Now");
                  trackEventsPendo("Clicked_On_Skip_Now");
                  localStorage.removeItem("userName");
                  navigate(ROUTES.GREET);
                }}
              />
            </div>
            <div className="flex justify-center py-2 md:hidden">
          <img
            src={logo}
            alt="journey to freedom"
            className="h-[100px] object-contain"
          />
        </div>
          </div>
        </section>

        

        <div className="hidden md:flex flex-col justify-start items-center  overflow-scroll pt-4">
            <div>
              <img
                  src={logo}
                  alt="journey to freedom"
                  className="h-[180px] object-contain"
                />
            </div>

            <div className="flex px-7 " > 
              <p
                className="text-custom-dark-blue  custom-h1 text-center"
                style={{ fontSize: '54px', fontWeight:500 ,lineHeight: "1" }}
              >
                {" "}
                We're ready to support you on your journey!
              </p>
            </div>
            <div className="pt-1">
            <h2 className="custom-h1 text-custom-dark-blue pt-4" style={{fontSize:'36px'}}>
              Let's get started
            </h2>
            </div>

            <div className="flex flex-col gap-2 mt-4 w-[425px]">
              <InputField
                type="text"
                value={name}
                charLength={15}
                inputStyleClass="form-input text-slate-700"
                inputContainerClass={
                  "flex justify-end items-center w-full m-auto"
                }
                placeholderText="Enter your First Name"
                handleChange={(e: any) => handleChange(e)}
              />
              <InputField
                type="email"
                value={email}
                inputStyleClass="form-input text-slate-700"
                inputContainerClass={
                  "flex justify-end items-center w-full m-auto mt-1"
                }
                placeholderText="Enter your Email"
                handleChange={(e: any) => handleEmailChange(e)}
              />
                          </div>
              <div className="flex justify-center">
                <h5 className="custom-text text-secondary">{error}</h5>
              </div>

              <div className="flex w-[50%] py-5">
                {/* <hr className="w-11/12 border-[#8C8C8C] md:hidden" /> */}
                <p className=" custom-text text-slate-700 text-sm">
                  <></>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setPrivacy(e.target.checked);
                      localStorage.setItem(
                        "privacy",
                        e.target.checked.toString()
                      );
                    }}
                  />{" "}
                  &nbsp;Checking this box acknowledges that when you submit
                  this information, Haleon and its affiliated companies and
                  trusted partners may use your personal information for
                  purposes explained in our Privacy Notice including, but not
                  limited to, sending you emails from the Journey to Freedom
                  program. In the future, if you do not wish to receive this
                  information, you will be given the opportunity to opt-out
                </p>
              </div>

              <CustomButton
               centeralign={true}
                label={"Set up My Profile"}
                buttonStyleClass="btn-primary-desktop mt-2 w-[450px] h-[60px] text-lg"
                handleClick={getStarted}
                isDisabled={!name || !email || !privacy}
              />

              <CustomButton
              centeralign={true}
                label={"Skip for now"}
                buttonStyleClass="text-secondary custom-text mt-5 py-2 h-[60px] text-lg"
                handleClick={() => {
                  trackEvents("Clicked_On_Skip_Now");
                  trackEventsPendo("Clicked_On_Skip_Now");
                  
                  localStorage.removeItem("userName");
                  navigate(ROUTES.GREET);
                }}
              />

            </div>

            {/* <div className="bg-custom-dark-blue fixed bottom-0 w-full text-center text-white flex flex-col justify-center p-2 py-5 md:py-10">
          <p className="my-[5px] px-[10px] text-center md:text-[18px] text-[10px]  text-[#999]">
            Behavioral support program increases chances of success. Use as
            directed. {!isMobile && <br></br>}The content of this website is intended for US audiences
            only.©2023 Haleon group of companies or its licensor. All rights
            reserved.
          </p>
        </div> */}

        {/* Footer */}
        <div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
          <Footer showLinks={true} showBehaviouralText={false} />
        </div>

        {/* <footer className="flex items-center justify-center p-0 short:px-2 xl:bg-primary xl:justify-start xl:px-36 mt-0">
          <div>
            <div className="flex justify-center py-2">
              <img
                src={footer}
                alt="journey to freedom"
                className="h-[100px] object-contain"
              />
            </div>
            <Footer />
          </div>
        </footer> */}
      </div>
    </>
  );
}