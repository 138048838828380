import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {userEmailIdRegex, userNameRegex} from "../../common/utils/Constants";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes";
import remove from "../../assets/icons/remove.svg";
import add from "../../assets/icons/add.svg";
import {addUserFriends, removeUserFriends} from "../../api/onboarding";
import {CustomButton} from "../../common/CustomButton";
import {InputField} from "../../common/InputField";
import mixpanel from "mixpanel-browser";
import { store } from "../../store";
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function  ShareProgress(props: any) {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const userProfileData = useSelector(
        (state: any) => state.steps.userProfileData
    );
    const memberList = useSelector(
        (state: any) => state.steps.userChoice.shareProgress.memberDetails
    );

    function handleNameChange(e: any) {
        const result: boolean = userNameRegex.test(e.target.value);
        if (e.target.value === "") {
            setName("");
            setError("");
        } else if (result) {
            setName(e.target.value);
            setError("");
        }
    }
    function handleEmailChange(e: any) {
        setEmail(e.target.value);
        setError("");
    }
    async function handleAddClick() {
        if (email && name) {
            const result: boolean = userEmailIdRegex.test(email);
            if (result) {
                const isExist  = memberList?.find((memberDetail:any)=>memberDetail.email === email)
                if(isExist){
                    setError("Contact's Email already exist.");
                }else {
                    setEmail("");
                    setName("");
                    await addUserFriends({userId: userProfileData.id, memberDetails: [{"name": name, "email": email}]})
                }
            } else {
                setError("Invalid Contact's Email.");
            }
        } else {
            setError("Contact's Name & Email is required.");
        }
    }
    async function handleRemoveClick(selectedMember:any){
        await removeUserFriends({userId:userProfileData.id, email:selectedMember.email})
    }

    return (
        <div className="flex overflow-auto flex-col h-[85%] gap-3">
            <div className="flex justify-center px-4">
                <CustomButton
                    label={'Skip for now >'}
                    buttonStyleClass="text-primary custom-text"
                    handleClick={() => {
                        trackEvents('skipped_share_progress')
                        trackEventsPendo('skipped_share_progress')
                        trackEvents(`left_question_${(store.getState().steps.currentQuestion + 1)}`)
                        trackEventsPendo(`left_question_${(store.getState().steps.currentQuestion + 1)}`)
                        navigate(ROUTES.DASHBOARD);
                    }}
                />
            </div>
            <div className="flex flex-col gap-3">
                {[...memberList]?.sort((a:any, b:any) => {
                    return a.id - b.id;
                }).map((member: any, index: number) => {
                    return (
                        <div>
                            <div className="flex">
                                <h2
                                    className="custom-subtitle text-[#002744]"
                                    style={{ width: "10%" }}
                                >
                                    {index + 1 + "."}
                                </h2>
                                <h2
                                    className="custom-subtitle text-[#002744]"
                                    style={{ width: "80%" }}
                                >
                                    {member.name}
                                </h2>
                                <div
                                    className="flex justify-end gap-2"
                                    style={{ width: "10%" }}
                                    onClick={() => handleRemoveClick(member)}
                                >
                                    <img src={remove} alt="Remove" className="block w-[15px]" />
                                </div>
                            </div>
                            <div style={{ width: "100%" }}>
                                <h2
                                    className="custom-subtitle text-[#8C8C8C]"
                                    style={{ paddingLeft: "10%" }}
                                >
                                    {member.email}
                                </h2>
                            </div>
                        </div>
                    );
                })}
                {memberList?.length < 5 && (
                    <div className="short:h-44">
                        <div className="flex">
                            <h2
                                className="flex items-center custom-subtitle text-[#002744]"
                                style={{ width: "10%" }}
                            >
                                {memberList?.length + 1 + "."}
                            </h2>
                            <div className="flex justify-center px-2 py-2 short:py-1 short:px-1">
                                <InputField
                                    type="text"
                                    inputStyleClass="px-[32px] short:px-[20px] short:py-[10px] py-[15px] w-[310px] short:w-[250px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                                    placeholderText="Contact’s Name"
                                    value={name}
                                    handleChange={(e: any) => handleNameChange(e)}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <h2
                                className="flex items-center custom-subtitle text-[#002744]"
                                style={{ width: "10%" }}
                            />
                            <div className="flex justify-center px-2 py-2 short:py-1 short:px-1">
                                <InputField
                                    type="email"
                                    inputStyleClass="px-[32px] short:px-[20px] short:py-[10px] py-[15px] w-[310px] short:w-[250px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                                    placeholderText="Contact’s Email"
                                    value={email}
                                    handleChange={(e: any) => handleEmailChange(e)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <h5 className="text-secondary custom-text">{error}</h5>
                        </div>
                        <div
                            className="flex justify-end gap-2"
                            onClick={() => handleAddClick()}
                        >
                            <h3 className="subtitle-2 text-secondary">Add</h3>
                            <img src={add} alt="add" className="block w-[15px]" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}