import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../api/common";
import { updateMyPreferences } from "../../api/constants";
import { CustomButton } from "../../common/CustomButton";
import { ROUTES } from "../../routes";
import { decrementCurrentQuestion, incrementCurrentQuestion, saveAnswer } from "../../slices/stepSlice";
import DashboardHeader from "../dashboard/DashboardHeader";
import ProductOptions from "../onboarding/ProductOptions";
import Replacement from "../onboarding/Replacement";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function MyPreference() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { fromPath } = useParams();

  let [isOpen, setIsOpen] = useState(false);
  let { questionnaire, currentQuestion }: any = useSelector(
    (state: any) => state.steps
  );
  let userChoice = useSelector((state: any) => state.steps.userChoice);

  let type = Object.keys(questionnaire)[currentQuestion];
  let userChoiceType = Object.keys(userChoice);

  let currentUserChoice = userChoice[userChoiceType[currentQuestion]];

  let question = questionnaire[type];

  let { userProfileData } = useSelector((state: any) => state.steps);

  function closeModal() {
    setIsOpen(false);
    navigate(ROUTES.DASHBOARD);
  }

  useEffect(() => {
    if (currentQuestion === 6) {
      trackEvents('on_product_preference_maintenance')
      trackEventsPendo('on_product_preference_maintenance')
    }
    if (currentQuestion === 7) {
      trackEvents('on_product_preference_productForm')
      trackEventsPendo('on_product_preference_productForm')
    }
    if (currentQuestion === 8) {
      trackEvents('on_product_preference_duration')
      trackEventsPendo('on_product_preference_duration')
    }
    if (currentQuestion === 9) {
      trackEvents('on_product_preference_flavour')
      trackEventsPendo('on_product_preference_flavour')
    }
  }, [currentQuestion])

  useEffect(() => {
    // Set user selected choice
    if (currentQuestion === 6) {
      let savedChoice = { selection: "", serverValue: "" };
      savedChoice.selection = userProfileData["maintainance"];
      if (savedChoice.selection.includes("maintenance")) {
        savedChoice.serverValue = "Maintenance";
        dispatch(saveAnswer(savedChoice));

      }
      if (savedChoice.selection.includes("control")) {
        savedChoice.serverValue = "InControl";
        dispatch(saveAnswer(savedChoice));
      }
    }
    if (currentQuestion === 7) {
      let savedChoice = {
        selection: userProfileData["productForm"],
        serverValue: userProfileData["productForm"],
      };
      dispatch(saveAnswer(savedChoice));
    }
    if (currentQuestion === 8) {
      let savedChoice = {
        selection: userProfileData["duration"],
        serverValue: "",
      };
      if (savedChoice.selection === "Yes") {
        savedChoice.serverValue = "yes";

        dispatch(saveAnswer(savedChoice));
      }
      if (savedChoice.selection === "No") {
        savedChoice.serverValue = "no";
        dispatch(saveAnswer(savedChoice));
      }
    }
    if (currentQuestion === 9) {
      let savedChoice = {
        selection: userProfileData["flavour"],
        serverValue: userProfileData["flavour"],
      };
      dispatch(saveAnswer(savedChoice));
    }
  }, []);
  function handleSave() {
    if (currentQuestion === 6) {
      trackEvents('updated_product_preference_maintenance', { selectedMaintenance: userChoice.maintenance.selection })
      trackEventsPendo('updated_product_preference_maintenance', { selectedMaintenance: userChoice.maintenance.selection })
      let payload = {
        userId: userProfileData["id"],
        maintainance: userChoice.maintenance.selection
        ,
      };

      if (currentUserChoice.serverValue === "Maintenance") {

        post(updateMyPreferences, payload)
          .then((res) => {
            if (res.status === 200) {
              setIsOpen(true);
            }
          })
          .catch((error) => alert("Request failed  : " + error.message));
      }
      if (currentUserChoice.serverValue === "InControl") {

        dispatch(incrementCurrentQuestion());
      }
    }
    if (currentQuestion === 7) {
      trackEvents('updated_product_preference_productForm', { selectedProductForm: userChoice.productForm.selection })
      trackEventsPendo('updated_product_preference_productForm', { selectedProductForm: userChoice.productForm.selection })
      dispatch(incrementCurrentQuestion());
    }
    if (currentQuestion === 8) {
      trackEvents('updated_product_preference_duration', { selectedDuration: userChoice.duration.selection })
      trackEventsPendo('updated_product_preference_duration', { selectedDuration: userChoice.duration.selection })
      dispatch(incrementCurrentQuestion());
    }
    if (currentQuestion === 9) {
      trackEvents('updated_product_preference_flavour', { selectedFlavour: userChoice.flavour.selection })
      trackEventsPendo('updated_product_preference_flavour', { selectedFlavour: userChoice.flavour.selection })
      let payload = {
        userId: userProfileData["id"],
        maintainance: userChoice.maintenance.selection,
        productForm: userChoice.productForm.selection,
        duration: userChoice.duration.selection,
        flavour: userChoice.flavour.selection,
      };
      post(updateMyPreferences, payload)
        .then((res) => {
          if (res.status === 200) {
            setIsOpen(true);
          }
        })
        .catch((error) => alert("Request failed  : " + error.message));
    }
  }
  return (
    <div className="flex flex-col bg-primary h-full relative">
      <DashboardHeader
        back={true}
        title="My Account"
        handleBack={() => {
          if (currentQuestion === 7 || currentQuestion === 8 || currentQuestion === 9) {
            dispatch(decrementCurrentQuestion());
          } else {
            if (fromPath === 'UserStat') {
              navigate(ROUTES.DASHBOARD);
            } else {
              navigate(ROUTES.HOME);
            }
          }
        }}
      />
      <div className="h-full bg-white overflow-clip">
        <h3 className="custom-h3 text-primary px-6 short:px-4 mt-12 short:mt-10">
          Product Preferences
        </h3>
        <h3 className="subtitle text-primary px-6 short:px-4 mt-2 short:mt-1">
          {question.Question}
        </h3>
        <div className="px-4 flex flex-col my-3 basis-[70%] h-[70%] short:h-[60%] short:basis-2/3 overflow-auto relative">
          {currentQuestion === 6 && <Replacement />}
          {currentQuestion === 7 && <ProductOptions />}
          {currentQuestion === 8 && <ProductOptions />}
          {currentQuestion === 9 && <ProductOptions />}
        </div>
      </div>
      <div className="bg-white absolute bottom-0 w-full">
        <CustomButton
          handleClick={handleSave}
          label="Save"
          isDisabled={currentUserChoice.isDisabled}
          buttonStyleClass="disabled:bg-[#E4E4E4] disabled:text-[#434343] subtitle-2 bg-secondary text-white py-[10px] px-[24px] rounded-full w-[200px]"
        />
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Preference Updated Successfully
                  </Dialog.Title>
                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Ok!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
