import Branding from "../../common/header/Branding";
import footer from "../../assets/images/journey_logo.png";
// import google from  "../../assets/images/google.png";
// import apple from  "../../assets/images/apple.png";
import { ROUTES } from "../../routes";
import { useNavigate } from "react-router-dom";
import { increment, incrementCurrentQuestion } from "../../slices/stepSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userEmailIdRegex, userNameRegex } from "../../common/utils/Constants";
import { InputField } from "../../common/InputField";
import { onSignUp } from "../../api/onboarding";
import { CustomButton } from "../../common/CustomButton";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import Footer from "../../common/footer";
import { trackEventsPendo } from "../../common/utils/pendo";
import navlogo from "../../assets/images/nicoretteupNew.png";
import logo from "../../assets/images/journey_logo.png";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [name, setName] = useState(localStorage.getItem('userName') ? localStorage.getItem('userName') : "");
  const [email, setEmail] = useState(
    localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : ""
  );
  const [password, setPassword] = useState("");
  const {
    gender,
    age,
    currentUsage,
    motivations,
    habbit_behaviour,
    myGoals,
    maintenance,
    productForm,
    duration,
    flavour,
  } = useSelector((state: any) => state.steps.userChoice);
  const isNameExist = localStorage.getItem("userName");
  const isEmailExist = localStorage.getItem("userEmail");
  // const getPrivacy = localStorage.getItem("privacy");
  const [privacy, setPrivacy] = useState(
    false
  );

  useEffect(() => {
    trackEvents('on_sign_up_page')
    trackEventsPendo('on_sign_up_page')
  }, [])
  function handleNameChange(e: any) {
    const result: boolean = userNameRegex.test(e.target.value);
    if (e.target.value === "") {
      setName("");
      setError("");
    } else if (result) {
      setName(e.target.value);
      setError("");
    }
  }
  const handleChange = (e: any) => {
    setError("");
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: any) => {
    let value = e.target.value.trim();
    if (e.target.value.length >= 8) {
      setError("");
      setPassword(value);
    } else {
      setPassword(value);
      setError("Password must be at least 8 characters long.");
    }
  };

  function handleLogin() {
    trackEvents("clicked_on_login_from_signup");
    trackEventsPendo('clicked_on_login_from_signup')
    navigate(ROUTES.LOGIN);
  }
  return (
    <>
      <div className="bg-primary-blue flex min-h-screen flex-col  justify-between ">
        {/* <Branding /> */}

        {/* nav for mobile */}
        <div className="flex sm:flex md:hidden  bg-custom-blue text-white h-[70px] px-5 justify-around items-center">
          <img src={navlogo} alt="cvs logo" className="h-[22px]" />
        </div>

        {/* nav for Desktop */}
        <div className="hidden px-10 md:flex  md:items-center md:justify-between bg-custom-blue text-white h-[80px]">
          <img src={navlogo} alt="Logo" />
        </div>

        <div className="md:hidden">
          <div className="flex justify-center px-6 short:p-0">
            <h2 className="custom-h2 text-slate-700">
              Let's get you Signed Up
            </h2>
          </div>
          <div className="flex justify-center">
            <p className="text-slate-700 custom-text text-sm">
              We're ready to support you on your journey!
            </p>
          </div>
          <div className="py-[20px] short:px-0 ">
            <div className="flex justify-center px-5 py-1 short:px-6 mt-1">
              <InputField
                type="text"
                value={isNameExist}
                charLength={15}
                inputStyleClass="form-input text-slate-700 h-[50px]"
                inputContainerClass={
                  "flex justify-end items-center max-w-[345px] w-full m-auto "
                }
                placeholderText="Enter your First Name"
                handleChange={(e: any) => handleNameChange(e)}
              />
            </div>
            {
              <div className="flex justify-center px-5 py-1 short:px-6 mt-1">
                <InputField
                  type="email"
                  value={isEmailExist}
                  inputStyleClass="form-input text-slate-700 h-[50px]"
                  inputContainerClass={
                    "flex justify-end items-center max-w-[345px] w-full m-auto h-"
                  }
                  placeholderText="Enter your Email"
                  handleChange={(e: any) => handleChange(e)}
                />
              </div>
            }
            <div className="flex justify-center px-5 py-1 short:px-6 mt-1">
              <InputField
                type="password"
                value={password}
                inputStyleClass="form-input text-slate-700 h-[50px]"
                inputContainerClass={
                  "flex justify-end items-center max-w-[345px] w-full m-auto"
                }
                placeholderText="Password"
                handleChange={(e: any) => handlePasswordChange(e)}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <h5 className="text-secondary custom-text">{error}</h5>
          </div>

          <div className="flex justify-center px-10 short:px-6">
            {/* <hr className="w-11/12 border-[#8C8C8C] md:hidden" /> */}
            <h1 className=" custom-text text-slate-700 md:hidden text-xs">
              <input
                type="checkbox"
                onChange={(e) => {
                  setPrivacy(e.target.checked);
                  localStorage.setItem("privacy", e.target.checked.toString());
                }}
              />{" "}
              &nbsp; Checking this box acknowledges that when you submit this
              information, Haleon and its affiliated companies and trusted
              partners may use your personal information for purposes explained
              in our Privacy Notice including, but not limited to, sending you
              emails from the Journey to Freedom program. In the future, if you
              do not wish to receive this information, you will be given the
              opportunity to opt-out
            </h1>
          </div>

          <div className=" px-3 justify-center hidden md:flex">
            {/* <hr className="w-11/12 border-[#8C8C8C] md:hidden" /> */}
            <p className=" custom-text text-slate-700 text-sm w-[20%]">
              <></>
              <input
                type="checkbox"
                onChange={(e) => {
                  setPrivacy(e.target.checked);
                  localStorage.setItem(
                    "privacy",
                    e.target.checked.toString()
                  );
                }}
              />{" "}
              &nbsp; Checking this box acknowledges that when you submit this
              information, Haleon and its affiliated companies and trusted
              partners may use your personal information for purposes explained
              in our Privacy Notice including, but not limited to, sending you
              emails from the Journey to Freedom program. In the future, if you
              do not wish to receive this information, you will be given the
              opportunity to opt-out
            </p>
          </div>


          <div className="flex justify-center px-5 short:px-6 mt-4">
            <CustomButton
              centeralign={true}
              label={"Sign Up"}
              buttonStyleClass="subtitle-2 disabled:bg-[#E4E4E4] disabled:text-[#434343] bg-secondary text-white py-[10px] px-[24px] rounded-full w-[340px] "
              isDisabled={(error?.length > 0 && error !== 'Something went wrong. Please try again.') || (!isNameExist && !name) || (!isEmailExist && !email) || !password || !privacy}
              handleClick={async () => {
                if (email && password && privacy == true) {
                  const result: boolean = userEmailIdRegex.test(email);
                  if (result) {
                    const payload = {
                      email: isEmailExist
                        ? localStorage.getItem("userEmail")
                        : email,
                      password: password,
                      user_name: isNameExist
                        ? localStorage.getItem("userName")
                        : name,
                      gender: gender.serverValue,
                      age: age.serverValue,
                      phoneno: "",
                      currentUsage: currentUsage.selection,
                      motivations: motivations.serverValue,
                      habbits_behavior: habbit_behaviour.serverValue,
                      my_goals: myGoals.serverValue,
                      maintainance: maintenance.selection,
                      productForm: productForm.serverValue,
                      duration: duration.selection,
                      isFirstLogin: true,
                      flavour: flavour.serverValue,
                    };
                    try {
                      trackEvents("clicked_on_sign_up")
                      trackEventsPendo("clicked_on_sign_up")
                      const result: any = await onSignUp(payload)
                      if (result.status === 200) {
                        mixpanel.people.set({
                          $name: payload.user_name,
                          $email: payload.email,
                          $gender: payload.gender,
                          $age: payload.age,
                        });
                        console.log(result.data.data[0].currentUser.id)
                        mixpanel.identify(result.data.data[0].currentUser.id);

                        window.pendo.identify(result.data.data[0].currentUser.id);

                        trackEvents('successful_user_sign_up')
                        trackEventsPendo('successful_user_sign_up')
                        localStorage.setItem("userName", name);
                        localStorage.setItem("day1", "true");
                        dispatch(incrementCurrentQuestion());
                        navigate(ROUTES.QUESTIONCARD);
                      }
                    } catch (e: any) {
                      setError(e ?? "Something went wrong. Please try again.");
                    }
                  } else {
                    setError("Invalid Email Id.");
                  }
                } else {
                  setError(
                    "Email, Password and Privacy Policy acceptance is required."
                  );
                }
              }}
            />
          </div>
          {/* <div className="flex justify-center">
            <hr className="border-[#8C8C8C] w-11/12" />
          </div> */}
          <div className="flex justify-center px-4 py-2">
            <p className="text-slate-700 custom-text text-sm">
              Already a user?
              <span
                className="text-secondary custom-text"
                onClick={handleLogin}
              >
                {" "}
                &nbsp; Login
              </span>
            </p>
          </div>
        </div>

        <div className="hidden md:flex flex-col justify-start items-center mt-2">
          <div>
            <img
              src={logo}
              alt="journey to freedom"
              className="h-[180px] object-contain"
            />
          </div>

          <div className="flex px-7 ">
            <p
              className="text-custom-dark-blue  custom-h1 text-center"
              style={{ fontSize: "54px", fontWeight: 500, lineHeight: "1" }}
            >
              {" "}
              We're ready to support you on your journey!
            </p>
          </div>

          <div className="pt-2">
            <h2
              className="custom-h1 text-custom-dark-blue pt-1"
              style={{ fontSize: "36px" }}
            >
              Let’s get you Signed Up
            </h2>
          </div>

          <div className="flex flex-col gap-2 mt-2 w-[425px]">
            <div className="flex justify-center  short:py-1 short:px-6 mt-7">
              <InputField
                type="text"
                value={name}
                charLength={15}
                inputStyleClass="form-input text-slate-700"
                inputContainerClass={
                  "flex justify-end items-center  w-full m-auto"
                }
                placeholderText="Enter your First Name"
                handleChange={(e: any) => handleNameChange(e)}
              />
            </div>
            {<div className="flex justify-center short:py-1 short:px-6 mt-1">
              <InputField
                type="email"
                value={email}
                inputStyleClass="form-input text-slate-700"
                inputContainerClass={
                  "flex justify-end items-center  w-full m-auto"
                }
                placeholderText="Enter your Email"
                handleChange={(e: any) => handleChange(e)}
              />
            </div>}
            <div className="flex justify-center  short:py-1 short:px-6 mt-1">
              <InputField
                type="password"
                inputContainerClass="flex justify-end items-center  w-full m-auto "
                value={password}
                inputStyleClass="form-input text-slate-700"
                placeholderText="Password"
                handleChange={(e: any) => handlePasswordChange(e)}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <h1 className="text-red-500 text-[16px]">{error}</h1>
          </div>

          <div className="flex justify-center px-10 short:px-6 w-[50%] mt-5">
            {/* <hr className="w-11/12 border-[#8C8C8C] md:hidden" /> */}
            <p className=" custom-text text-neutral-600 text-sm pt-2">
              <input
                className="mt-1"
                type="checkbox"
                onChange={(e) => {
                  setPrivacy(e.target.checked);
                  localStorage.setItem("privacy", e.target.checked.toString());
                }}
              />{" "}
              &nbsp; Checking this box acknowledges that when you submit
              this information, Haleon and its affiliated companies and
              trusted partners may use your personal information for
              purposes explained in our Privacy Notice including, but not
              limited to, sending you emails from the Journey to Freedom
              program. In the future, if you do not wish to receive this
              information, you will be given the opportunity to opt-out
            </p>
          </div>



          <div className="flex justify-center  short:px-6 mt-4">
            <CustomButton
              label={"Sign Up"}
              buttonStyleClass="subtitle-2 disabled:bg-[#E4E4E4] disabled:text-[#434343] bg-secondary text-white py-[10px]  rounded-full w-[450px] h-[60px]"
              isDisabled={
                (error?.length > 0 &&
                  error !== "Something went wrong. Please try again.") ||
                (!isNameExist && !name) ||
                (!isEmailExist && !email) ||
                !password ||
                !privacy
              }
              handleClick={async () => {
                if (email && password && privacy == true) {
                  const result: boolean = userEmailIdRegex.test(email);
                  if (result) {
                    const payload = {
                      email: email,
                      password: password,
                      user_name: name,
                      gender: gender.serverValue,
                      age: age.serverValue,
                      phoneno: "",
                      currentUsage: currentUsage.selection,
                      motivations: motivations.serverValue,
                      habbits_behavior: habbit_behaviour.serverValue,
                      my_goals: myGoals.serverValue,
                      maintainance: maintenance.selection,
                      productForm: productForm.serverValue,
                      duration: duration.selection,
                      isFirstLogin: true,
                      flavour: flavour.serverValue,
                    };
                    try {
                      trackEvents("clicked_on_sign_up")
                      trackEventsPendo("clicked_on_sign_up")
                      const result: any = await onSignUp(payload);
                      if (result.status === 200) {
                        mixpanel.people.set({
                          $name: payload.user_name,
                          $email: payload.email,
                          $gender: payload.gender,
                          $age: payload.age,
                        });
                        console.log(result.data.data[0].currentUser.id)
                        mixpanel.identify(result.data.data[0].currentUser.id);

                        window.pendo.identify(result.data.data[0].currentUser.id);

                        trackEvents('successful_user_sign_up')
                        trackEventsPendo('successful_user_sign_up')
                        localStorage.setItem("userName", name);
                        localStorage.setItem("day1", "true");
                        dispatch(incrementCurrentQuestion());
                        navigate(ROUTES.QUESTIONCARD);
                      }
                    } catch (e: any) {
                      setError(e ?? "Something went wrong. Please try again.");
                    }
                  } else {
                    setError("Invalid Email Id.");
                  }
                } else {
                  setError(
                    "Email, Password and Privacy Policy acceptance is required."
                  );
                }
              }}
            />
          </div>

          <p className=" xl:text-start sm:text-center text-slate-700 hidden md:block py-5">
            Already a user?
            <span
              className="custom-text text-secondary"
              onClick={handleLogin}
            >
              {" "}
              &nbsp; Login
            </span>
          </p>
        </div>

        {/* <div className="flex justify-center items-center pb-7 short:pb-[10px] bg-sky-200">
          <div>
            <div className="flex justify-center">
              <div className="">
                <img src={footer} alt="journey to freedom" className="h-[100px] object-contain" />
              </div>
            </div>
          </div>
        </div> */}

        {/* <Footer /> */}
        {/* Footer */}
        <div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
          <Footer showLinks={true} showBehaviouralText={false} />
        </div>
      </div>
    </>
  );
}