import { InputField } from "../../common/InputField";
import { CustomButton } from "../../common/CustomButton";
import footer from "../../assets/images/journey_logo.png";
import React, { useState } from "react";
import { sendForgotPasswordMail } from "../../api/onboarding";
import { ROUTES } from "../../routes";
import { useNavigate } from "react-router-dom";
import { userEmailIdRegex } from "../../common/utils/Constants";
import Branding from "../../common/header/Branding";
import navlogo from "../../assets/images/nicoretteupNew.png";
import Footer from "../../common/footer";
import logo from "../../assets/images/journey_logo.png";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [isVerificationMailSent, setIsVerificationMailSent] = useState(false);

  const handleChange = (e: any) => {
    setError("");
    setEmail(e.target.value);
  };

  const handleSendVerification = async () => {
    const result: boolean = userEmailIdRegex.test(email);
    if (!result) {
      setError("Invalid Email Id");
    } else {
      if (!isVerificationMailSent) {
        try {
          const result: any = await sendForgotPasswordMail(email);
          if (result.status === 200) {
            setIsVerificationMailSent(true);
          }
        } catch (e) {
          setError("User/Email Does not exist");
        }
      }
    }
  };

  return (
    <>
      {/* <div className="h-full flex flex-col overscroll-auto md:border-2 py-5 bg-sky-200 justify-around">
                <Branding/>
                <div className="justify-center items-center text-center">
                    <div className="flex justify-center px-5 py-2">
                        <h2 className="custom-h2 text-slate-700">Forgot Password</h2>
                    </div>
                    <div className="flex justify-center px-[24px] mb-2">
                        <p className="text-slate-700 custom-text">A link will be sent to your email for password recovery</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <InputField
                            inputContainerClass={"flex justify-end items-center w-[345px] w-full m-auto"}
                            inputStyleClass={"py-2 w-full px-2  border-[0.75px] text-slate-700 rounded-full bg-sky-200 border-[#BFBFBF]"}
                            type={'email'}
                            placeholderText={'Email Address'}
                            handleChange={(e: any)=>{handleChange(e)}}/>
                    </div>
                    {error && <div className="flex justify-center">
                        <h5 className="text-secondary custom-text">{error}</h5>
                    </div>}
                    <CustomButton
                        buttonStyleClass={ isVerificationMailSent ? "mt-10 subtitle-2 bg-[#FF764C] text-white py-[10px] px-[24px] rounded-full w-[340px]" :
                            "mt-10 subtitle-2 bg-secondary text-white py-[10px] px-[24px] rounded-full w-[340px]" }
                        label={isVerificationMailSent ? 'Verification link sent' : 'Send verification link'}
                        handleClick={() => {handleSendVerification()}}/>
                    <CustomButton label={'Back to Login'} buttonStyleClass={"text-slate-700 custom-text mt-5"} handleClick={() => {navigate(ROUTES.LOGIN)}}/>
                </div>
                <div className="flex justify-center items-center mt-14">
                    <div>
                        <div className="flex justify-center">
                            <div className="">
                                <img src={footer} alt="journey to freedom" className="h-[120px] object-contain"/>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <p className="text-[14px] text-slate-700">
                                Supporting your journey from start to success
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="bg-primary-blue flex min-h-screen flex-col  justify-between ">
        {/* nav for mobile */}
        <div className="flex sm:flex md:hidden  bg-custom-blue text-white h-[70px] px-5 justify-around items-center">
          <img src={navlogo} alt="cvs logo" className="h-[22px]" />
        </div>

        {/* nav for Desktop */}
        <div className="hidden px-10 md:flex  md:items-center md:justify-between bg-custom-blue text-white h-[80px]">
          <img src={navlogo} alt="Logo" />
        </div>

        <div className="flex flex-col  justify-center items-center grow ">
          <img
            src={logo}
            alt="journey to freedom"
            className="h-[100px] md:h-[180px]  object-contain"
          />

          <p
            className="text-custom-dark-blue  custom-h1 text-center md:mt-5" 
            style={{ fontSize: isMobile ? "38px" : "54px", fontWeight: 500 }}
          >
            {" "}
            Forgot Password
          </p>

          <h2
            className="md:custom-h1 custom-h2 text-custom-dark-blue md:mt-5 mt-2"
            style={{ fontSize: isMobile ? "18px" : "36px" }}
          >
            A link will be sent to your email for password recovery
          </h2>

          <div className="flex flex-col justify-center items-center md:max-w-[450px] w-full  pt-5">
            <InputField
              type={"email"}
              placeholderText={"Email Address"}
              handleChange={(e: any)=>{handleChange(e)}}
              className="px-4"
              inputStyleClass={"form-input text-slate-700 md-h[50px]"}
              inputContainerClass={
                "flex justify-end items-center  md:w-[450px] w-full m-auto px-5 md:px-0"
              }
            />
            {error && (
              <div className="flex justify-center">
                <h5 className="text-secondary custom-text">{error}</h5>
              </div>
            )}
            <div className="flex justify-center items-center max-w-[450px] w-full m-auto mt-3 md:px-0 px-5">
              {/* <button
                className="subtitle-2 bg-secondary py-3 short:px-7 short:py-2 w-full text-white rounded-full md:h-[60px] md:w-[450px] "
              >
                Login
              </button> */}
              <CustomButton
                        buttonStyleClass={ isVerificationMailSent ? "subtitle-2 bg-secondary py-3 short:px-7 short:py-2 w-full text-white rounded-full md:h-[60px] md:w-[450px] bg-[#FF764C] md:text-[16px]":
                        "subtitle-2 bg-secondary py-4 short:px-7 short:py-2 w-full text-white rounded-full md:h-[60px] md:w-[450px] md:text-[16px]"}
                        label={isVerificationMailSent ? 'Verification link sent' : 'Send verification link'}
                        handleClick={() => {handleSendVerification()}}/>
            </div>
            <div className="flex justify-center items-center max-w-[450px] w-full m-auto mt-3 md:px-5 px-5">
            <CustomButton label={'Back to Login'} buttonStyleClass={"w-full text-secondary md:pt-5 pt-2 md:text-[18px]"} handleClick={() => {navigate(ROUTES.LOGIN)}}/>
                </div>
          </div>
        </div>

        <div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
          <Footer showLinks={true} showBehaviouralText={false} />
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
