import React, {useState} from "react";

export const InputField=(props:any)=> {
    const {type = 'text', handleChange, inputStyleClass, placeholderText, value,charLength, isDisabled=false, inputContainerClass} = props;
    const [showPassword, setShowPassword] = useState(false)

    return <div className={inputContainerClass ?? "flex justify-end items-center max-w-[345px] w-full short:w-4/5 m-auto"}>
        <input
            disabled={isDisabled}
            value={value}
            maxLength={charLength}
            type={type === 'password' ? showPassword ? "text" : "password" : type}
            className={inputStyleClass ?? "px-8 py-2 short:px-7 short:py-2  border-[0.75px] text-slate-700 rounded-full bg-sky-200 border-\[\#BFBFBF\]"}
            placeholder={placeholderText}
            onChange={(e: any) => handleChange(e)}
        />
        {type === 'password' && <div onClick={() => {
            setShowPassword(!showPassword)
        }} className="absolute px-3">
            {showPassword ?
                <span className="fa fa-fw fa-eye text-gray-400 toggle-password h-[30px] w-[30px] flex items-center"/> :
                <span
                    className="fa fa-eye fa-eye-slash text-gray-400 toggle-password h-[30px] w-[30px] flex items-center"/>}
        </div>}
    </div>
}