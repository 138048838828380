import { Button, Icon, StepButton, StepContent, StepIcon, Stepper, colors } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";

export default function CustomStepper(props) {
  const steps = [1, 2, 3, 4, 5, 6];
  const currentQuestion = useSelector(
    (state: any) => state.steps.currentQuestion,
  );

  const isMobile = useCheckMobileScreen();


  return (
    <Box
      sx={{
        height: isMobile ? "40px" : "100%",
        width: isMobile ? "100" : "5%",
        paddingTop: isMobile ? 3 : 2,
        zIndex: props.zindex?props.zindex:""
      }}
    >
      <Stepper
      activeStep={currentQuestion}
        sx={{
          paddingRight: isMobile && '10px',
          padding:!isMobile? '50px':'10px', pt:!isMobile && '40px',
        ".MuiSvgIcon-root.Mui-active": {
          padding: "3px",
          borderRadius: "50%",
          border: "1px solid #093159",
        },
        "& .MuiStepConnector-line": {
            
        },
        "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
        
          borderColor: "#093159",
        },
        "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
        
          borderColor: "#093159",
        },

      
      }}
        orientation={!isMobile ? "vertical" : "horizontal"}
      >
        {steps.map((label) => (
          <Step key={label} sx={{

            '& .MuiStepLabel-root': {
              position:'relative',
              right:'3px',
              '.Mui-disabled': {
                position:'relative',
                top:isMobile?4:'',
                left:"5px",
                height: '2rem',
                width: '2rem',
                color: '#002744',
              },
              '.Mui-completed': {
                position:'relative',
                bottom:isMobile?3:'',
                height: '1.6rem',
                width: '1.6rem',
                color: '#002744', // Circle color (COMPLETED)
              },
              '.Mui-active': {
                position:'relative',
                color: '#002744',
                height: !isMobile ? '2rem' : '2rem',
                width: !isMobile ? '2rem' : '2rem',
                // Circle color (ACTIVE)
              },
            },
          }}>
            <StepLabel StepIconComponent={(props) => (
    <StepIcon
      {...props}
      icon={props.icon}
      active={props.active || props.completed}
      completed={false}
    />
  )}  ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}