import './popup.css'
type bubble = {
  text: string;
  dialogBoxStyle?:any;
  style?:any
};

export default function BubblePopup({ text,dialogBoxStyle,style }: bubble) {
  return (
    <div className="flex justify-end " >
     <div className='dialog' style={dialogBoxStyle}>
      <p className='ztext-[#575257] text-center' style={style}> {text}</p>
      </div>
    </div>

  );
}
