import { Disclosure } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";
import DashboardHeader from "./dashboard/DashboardHeader";

export default function FAQ() {
    let navigate = useNavigate();
  
    function handleBack(){
        navigate(ROUTES.SETTING)
  }
  return (
    <div className="h-full bg-white">
     <div>
     <DashboardHeader back={true} handleBack={handleBack}/>
     </div>
     <div className="px-6 mt-8 short:mt-4">
        <h2 className="custom-h2 text-primary">FAQ</h2>
     </div>
     <div className="px-6 mt-4 short:mt-2">
     <Disclosure>
        {({open}) => (
            <>
            <Disclosure.Button className="py-2  w-full flex justify-between">
            Lorem, ipsum dolor.
            <i className={open ?"fa-solid fa-chevron-up":"fa-solid fa-chevron-down"}/>
          </Disclosure.Button>
          <Disclosure.Panel className="text-[#8C8C8C] my-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam, illum?
          </Disclosure.Panel>
            </>
        )}      
    </Disclosure>
    <hr />
     </div>
      

    </div>
  )
}
