import Cart from "../../common/cart/Cart";
import menuIcon from "../../assets/icons/menu.png";
import cvs_logo from "../../assets/images/nicoretteLogo.png";
import backIcon from "../../assets/icons/backArrow.svg";

export default function DashboardHeader(props: any) {
    const {title, menu, handleMenu, back, handleBack, cart, handleCart} = props;
    return title ? <div>
            <div className="flex justify-center w-full bg-sky-200 px-5 py-3 my-2">
                <img src={cvs_logo} alt="cvs logo" className="sm:w-126 short:w-100"/>
            </div>
            <div className="flex justify-between mb-10 short:mb-4 mt-4 px-5 text-slate-700">
                <div>
                    {menu && (
                        <button onClick={handleMenu}>
                            <img src={menuIcon} alt="menu"/>
                        </button>
                    )}
                    {back && (
                        <button onClick={handleBack}>
                            <img src={backIcon} alt="menu"/>
                        </button>
                    )}
                </div>
                <div className="ml-[-22px]">
                    <h2 className="custom-h2 text-slate-700">{title}</h2>
                </div>
                <div onClick={handleCart}>
                    {cart &&
                    <Cart/>
                    }
                </div>
            </div>
        </div>
        : <div className="flex flex-col bg-sky-200 h-full relative">
            <div className="flex justify-between w-full bg-sky-200 px-5 py-3 my-2">
                <button onClick={handleBack}>
                    <img src={backIcon} alt="back" />
                </button>
                <img src={cvs_logo} alt="cvs logo" className="" />
                <div/>
            </div>
        </div>
}