import { setShowCart } from "../../slices/dashboardSlice";
import { Dialog } from "@headlessui/react";
import close from "../../assets/icons/close.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";
import { ROUTES } from "../../routes";
import { CustomButton } from "../../common/CustomButton";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const productInfo = store.getState().steps.cart;

    const [isOpen, setIsOpen] = useState(false);
    const { showCart } = useSelector(
        (state: any) => state.dashboard
    );
    const { isNewUser } = useSelector(
        (state: any) => state.steps
    );

    useEffect(() => {
        if (productInfo.length > 0 && isNewUser) {
            setTimeout(() => {
                setIsOpen(true);
            }, 3000);
        }
    }, []);

    useEffect(() => {
        if (showCart) {
            setIsOpen(true);
        }
    }, [showCart]);

    function handleGoToProduct() {
        trackEvents('cart_go_to_product')
        trackEventsPendo('cart_go_to_product')
        dispatch(setShowCart(false))
        let product = productInfo[0];
        navigate(ROUTES.PRODUCTDETAILS, { state: { ...product } });
    }

    return <Dialog
        open={isOpen}
        onClose={() => {
            setIsOpen(false)
            dispatch(setShowCart(false))
        }}
        className="absolute top-0 z-50 w-full md:w-96 md:mx-[40%]"
    >
        <div
            className="fixed w-full md:w-96  h-full md:h-[80vh] md:mt-8 bg-black/30 "
            aria-hidden="true"
        />
        <Dialog.Panel className="relative">
            <div className="h-72 short:h-60 border rounded-lg shadow-md mx-5 my-[50%]">
                <div className="h-1/4 bg-[#F5F5F5] flex justify-center items-center relative">
                    <h3 className="text-center custom-h2">Products in your cart</h3>
                    <button
                        className="absolute right-4 top-4"
                        onClick={() => {
                            setIsOpen(false)
                            dispatch(setShowCart(false))
                        }}
                    >
                        <img src={close} alt="close" />
                    </button>
                </div>
                {productInfo.length > 0 ? <div className="flex flex-col justify-between py-3 h-3/4 px-4 bg-white">
                    <div className="flex gap-2">
                        <div className="w-20">
                            <img
                                src={productInfo[0]?.productImageURL}
                                alt={productInfo[0]?.productBrand}
                            />
                        </div>
                        <div className="font-[Helvetica-Neue]">
                            {productInfo[0]?.productBrand} {productInfo[0]?.productForm}{" "}
                            successfully added in your cart.
                            <p className="mt-3"> Would you like to shop more?</p>
                        </div>
                    </div>
                    <div className="flex justify-end gap-1">
                        <div>
                            <CustomButton
                                buttonStyleClass="custom-subtitle-2 text-secondary py-2 px-5"
                                handleClick={() => {
                                    trackEvents('cart_go_to_product_later')
                                    trackEventsPendo('cart_go_to_product_later')
                                    setIsOpen(false)
                                    dispatch(setShowCart(false))
                                }}
                                label={'Later'}
                            />
                        </div>
                        <div className="basis-1/2">
                            <CustomButton
                                label={'Go To Product'}
                                handleClick={handleGoToProduct}
                                buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border w-40 border-secondary rounded-full"
                            />
                        </div>
                    </div>
                </div> :
                    <div className="flex flex-col justify-center items-center text-center py-3 h-3/4 px-4 bg-white">
                        <p>Your cart is currently empty, we recommend checking out our featured products!</p>
                    </div>}
            </div>
        </Dialog.Panel>
    </Dialog>
}
export default Cart