import achievement from "../../assets/images/Achievements.png";

export const AchievementInfo = () => {
  return (
    <>
      <h1 className="subtitle-medium text-center pt-5 pb-2">Achievements</h1>
      <p className="text-center text-[#595959] text-base font-normal font-[Helvetica-Neue]">
        Track Your Milestones Unlock Your Savings.
      </p>
      <img src={achievement} alt="journey map picture" className="m-auto" />
      <h1 className="subtitle-medium text-center pt-[10px] pb-2">
        How it works?
      </h1>
      <div className="px-7 flex flex-col mt-[10px] gap-2">
        <div>
          <h3>🏆 Collect Badges </h3>
          <p className="subtitle-modal text-[#595959]">
            as achievements for your smoke free journey
          </p>
        </div>
        <div>
          <h3>💵 Unlock your Savings </h3>
          <p className="subtitle-modal text-[#595959]">
            by earning coupons that can be redeemed at any CVS store!
          </p>
        </div>
      </div>
    </>
  );
};

export const HowItWorks = () => {
  return (
    <>
      <h1 className="subtitle-medium text-center pt-5 pb-2">How it works?</h1>
      <div className="mt-4 px-7 flex flex-col gap-7 short:gap-5 short:mt-4">
        <div>
          <h3>🏆 Collect Badges </h3>
          <p className="subtitle-modal text-[#595959]">
            as achievements for your smoke free journey
          </p>
        </div>
        <div>
          <h3>💵 Unlock your Savings </h3>
          <p className="subtitle-modal text-[#595959]">
            by earning coupons that can be redeemed at any CVS store!
          </p>
        </div>
      </div>
    </>
  );
};
