import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {incrementCurrentQuestion} from "../../slices/stepSlice";
import QuestionCardHeader from "./questionCard/questionCardHeader/QuestionCardHeader";
import badgeBackground from '../../assets/images/badgeBackground.png';
import {ROUTES} from "../../routes";
import {setUserBadge} from "../../api/onboarding";
import {CustomButton} from "../../common/CustomButton";

export default function EarnBadge() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const name = localStorage.getItem("userName")
    const badgeDetails = useSelector(
        (state: any) => state.steps.userChoice.fixedBadge.selection
    );
    const userProfileData = useSelector(
        (state: any) => state.steps.userProfileData
    );
    async function handleClick(){
        const payload = {
            userId:userProfileData.id,
            badgeId: badgeDetails.id
        }
        await setUserBadge(payload)
        navigate(ROUTES.QUESTIONCARD)

    }
    return (
        <>
            <div className="flex flex-col h-full bg-white">
                <div>
                    <QuestionCardHeader />
                </div>
                <div className="overflow-auto p-5 short:p-4 basis-[72%] short:basis-[64%]">
                    <div>
                        <h2 className="custom-h2 text-[#FF5C37] text-left text-[23px]">
                            Congratulations {userProfileData?.user_name ?? name}! <br/>
                            You’ve earned your First Badge!</h2>
                        <div className="text-[#595959] custom-text py-[20px] text-left text-base">
                            Continue to collect achievement badges and rewards by meeting milestones along your journey
                        </div>
                        <div className="flex justify-center left-2/4 basis-auto m-2">
                            <div className="flex flex-col h-full" style={{
                                height:'230px',
                                width:'250px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                backgroundImage: `url(${badgeBackground})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat"}}>
                                <img style={{height:'150px',width:'150px'}} src={badgeDetails.batchUrl}/>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div className="text-[#FF764C] custom-text">GET STARTED BADGE</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-screen md:w-80 md:bottom-28 md:ml-8 bottom-0 fixed flex justify-center bg-white" style={{paddingTop: '4.1rem', paddingBottom: '4.1rem' }}>
                <div className="flex justify-center left-2/4 basis-auto m-2">
                    <CustomButton
                        label={'View Journey'}
                        buttonStyleClass="bg-secondary disabled:bg-[#E4E4E4] disabled:text-[#434343] w-[220px] py-[10px] px-[24px] subtitle-2 rounded-full text-white"
                        handleClick={()=>{handleClick()}}
                    />
                </div>
            </div>
        </>
    )
}
