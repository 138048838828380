import { useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import parse from 'html-react-parser';
import { ROUTES } from "../../routes";

export default function ArticleDetails() {
    const {state} = useLocation()
    let navigate = useNavigate()
    function handleArticleBack(){
        navigate(ROUTES.SUPPORT)
    }
    let html = state.article.doc_HTML
    html = html.replaceAll("�","\'")
  return (
    <div>
        <DashboardHeader handleBack={handleArticleBack}/>
        <div className="flex flex-col gap-3 fixed">
            <div className="basis-1/3 object-contain relative">
                <img src={state.article.image_link} alt="article image" />
                <div className="absolute top-2 right-2 bg-[#00000066] text-xs px-3 py-1 rounded-full h-6 text-[#F0F0F0]">
                          Sponsored
                </div>
            </div>
            <div className="px-4 mt-3 text-[18px] font-medium text-primary">
                <h3>{state.article.title}</h3>
            </div>
            <div className="h-48 overflow-hidden px-4 text-[#595959] subtitle-2">
                {parse(html)}
            </div> 
            <div className="absolute -bottom-10 right-10 ">
             <a href={state.article.address} target="_blank" className="text-secondary font-medium text-[16px]">Read the full article</a>   
            </div>      
        </div>
    </div>
  )
}
