import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../common/CustomButton";
import DashboardHeader from "./DashboardHeader";
import BottomNav from "./BottomNav";
import { ROUTES } from "../../routes";
import done from "../../assets/images/done.svg";
import notepad from "../../assets/images/notepad.svg";
import "react-modern-drawer/dist/index.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LeftMenu from "../../common/LeftMenu";
import {
  setFeelingsData,
  setIsLeftDrawerOpen,
  setJourneyData,
  setPopupShown,
  setSelectedFeeling,
  setShowCart
} from "../../slices/dashboardSlice";
import {
  getRatingDetails,
  getUserDetails,
  getUserStatDetails,
  setFeelingImage,
} from "../../api/dashboard";
import JourneyDashboard from "./JourneyDashboard";
import UserStats from "./UserStats";
import { get } from "../../api/common";
import { getJourneyDetails } from "../../api/constants";
import mixpanel from "mixpanel-browser";
import { Dialog } from "@headlessui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { HowItWorks, JourneyInfo } from "./JourneyGuide";
import buttonArrow from '../../assets/icons/buttonArrow.png'
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import BuddyPopup from "../../common/popup/buddyPopUp";
import buddy from "../../assets/icons/buddy.svg";
import checkInIcon from "../../assets/icons/checkin.png";
import { trackEvents } from "../../common/utils/mixpanel";
import { Events } from "../../common/Mixpanel_Events_constant";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function Dashboard() {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showUserStatPopup, setShowUserStatPopup] = useState(false)
  let { isLeftDrawerOpen, selectedFeeling, feelingsData, showCart,popupShown } = useSelector(
    (state: any) => state.dashboard
  );
  let { userProfileData, isLoggedIn, isNewUser } = useSelector(
    (state: any) => state.steps
  );
  let [isOpen, setIsOpen] = useState(false);
  const swiperRef = useRef(null);
  let [buddyPopisOpen, setBuddyPopUpIsOpen] = useState(false);
  const swiperRefBuddy = useRef(null);
  const [showNotificationBtn, setShowNotificationBtn] = useState(false);
  let [buddyPopDay1isOpen, setBuddyPopDay1IsOpen] = useState(false);
  let [buddyPopDay2isOpen, setBuddyPopDay2IsOpen] = useState(false);
  let [buddyPopDay2SecondisOpen, setBuddyPopDay2SecondIsOpen] = useState(false);
  let [buddyPopDay3SecondisOpen, setBuddyPopDay3SecondIsOpen] = useState(false);
  let [buddyPopDay3isOpen, setBuddyPopDay3IsOpen] = useState(false);
  const swiperRefBuddyDay1 = useRef(null);
  const swiperRefBuddyDay2 = useRef(null);
  const swiperRefBuddySecondDay2 = useRef(null);
  const swiperRefBuddySecondDay3 = useRef(null);
  const swiperRefcheckin = useRef(null);
  const swiperRefBuddyDay3 = useRef(null);
  const [showExploreBtn, setShowExploreBtn] = useState(false);
  const [showCheckinBtn, setShowCheckinBtn] = useState(false);
  let [dailyCheckUpModal, setDailyCheckUpModal] = useState(false)
  let [dailyCheckUpModalIndex, setDailyCheckUpModalIndex] = useState(false)
  let [openDay, setOpenDay] = useState(0);
  let [fromDay3, setFromDay3] = useState(false);
  let [isMilestoneReached, setIsMilestoneReached] = useState(false);
  let [badgeUrl, setBadgeUrl] = useState('')
  let [badgeInfo, setBadgeInfo] = useState({})
  // const { fromPath } = useParams()
  const fromsms = localStorage.getItem("fromsms");
  // const enableNotification = localStorage.getItem("enableNotification")

  useEffect(() => {
    // Mixpanel scroll tracking
    trackScroll('journey-map', false, 'journey-map-scroll')

    getUserDetails({ email_id: userProfileData?.email });
    getUserStatDetails(userProfileData?.id)
    if (isLoggedIn && !isNewUser) {
      getRatingDetails(userProfileData?.id);
    }

    if (isNewUser) {
      let isDay1 = localStorage.getItem("day1");

      if (isDay1 == "true") {
        localStorage.removeItem("day1");

        if (fromsms == 'true') {
          localStorage.setItem("fromsms", 'false');
          setBuddyPopDay1IsOpen(true);
        } else {
          setIsOpen(true)
        }
      }
    }
  }, []);

  useEffect(() => {
    const currentFormattedDate = moment().format('YYYY-MM-DD')
    const loginDate = moment(userProfileData?.user_signedIn_date).format('YYYY-MM-DD')
    const currentDate = moment(currentFormattedDate)
    if (currentDate.diff(loginDate,'days') == 1 && !popupShown) {
      if (Array.isArray(feelingsData)) {
        setBuddyPopDay2IsOpen(true) 
      }
    } else {
      if ((Array.isArray(feelingsData)) && (feelingsData?.length > 0) && !popupShown) {
        let getIsBack = localStorage.getItem("fromCheckinDialog")
        if (getIsBack == "true") {
          setDailyCheckUpModal(true)
          setDailyCheckUpModalIndex(true)
        } else {
          setBuddyPopDay3IsOpen(true) 
        }
      }
    }
  }, [feelingsData && feelingsData?.length]);

  useEffect(() => {
    if (selectedFeeling && (selectedFeeling.id > 0) && (selectedFeeling.id <= 3)) {
      setTimeout(() => {
        dispatch(setFeelingsData(""));
        getUserStatDetails(userProfileData?.id)
        get(getJourneyDetails, { params: { userId: userProfileData?.id } })
          .then((response) => {
            if (response.status === 200) {
              dispatch(setJourneyData(response.data.result))
            }
          })
          .catch((error) => {
            alert("Request failed" + error.message)
          });
      }, 4000);
    }
  }, [selectedFeeling])

  const toggleMenuDrawer = () => {
    dispatch(setIsLeftDrawerOpen(!isLeftDrawerOpen));
  };
  
  const fetchBadgeUrl =()=>{
    get(getJourneyDetails, { params: { userId: userProfileData?.id } })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setJourneyData(response.data.result))
            const badgeElement = response.data.result && response.data.result[response.data.result.length - 1]
            if (badgeElement && badgeElement['badge_ids']) {
              if (response.data.result[response.data.result.length - 1]['badge_ids']['batchUrl'] !== null) {
                localStorage.setItem('badge_url', response.data.result[response.data.result.length - 1]['badge_ids']['batchUrl'])
                localStorage.setItem('badgeInfo', response.data.result[response.data.result.length - 1]['badge_ids'])
                setBadgeUrl(response.data.result[response.data.result.length - 1]['badge_ids']['batchUrl'])
                setBadgeInfo(response.data.result[response.data.result.length - 1]['badge_ids'])
              }
            }
          }
        })
        .catch((error) => {
          alert("Request failed" + error.message)
        });
  }

  const setHowAreYouFeeling = (payload) =>{
    setFeelingImage(payload).then((res:any)=>{
      if(res?.status === 200){
        fetchBadgeUrl();
        getUserDetails({ email_id: userProfileData?.email });
      }
    }).catch(err=>console.log("@@@ err",err))
  }

  const onDrawerClose=()=>{
    const currentFormattedDate = moment().format('YYYY-MM-DD')
    const loginDate = moment(userProfileData?.user_signedIn_date).format('YYYY-MM-DD')
    const currentDate = moment(currentFormattedDate)
    if(badgeUrl && !popupShown && (currentDate.diff(loginDate,'days') >= 1) ){
      setIsMilestoneReached(true)
    }else if(fromDay3 == true) {
      setFromDay3(false)
      setBuddyPopDay3SecondIsOpen(true)
    }
    setShowUserStatPopup(false);
    dispatch(setPopupShown(true))
}

useEffect(()=>{
  if(!isMobile){ 
    navigate(ROUTES.DESKTOP_LANDING);
  }
},[isMobile])
 
console.log("@@@@feelingsData?.length",feelingsData?.length)
  return (
    <>
    {/* relative */}
      <div className="flex flex-col bg-white h-full w-full fixed">
        <div className="bg-sky-200">
          <DashboardHeader
            menu={true}
            title="My Journey"
            cart={true}
            handleMenu={() => {
              toggleMenuDrawer();
            }}
            handleCart={() =>
              dispatch(setShowCart(!showCart))
            }
          />
        </div>
        <div className="h-full overflow-auto bg-white mt-6 " id="journey-map">
          <JourneyDashboard />
        </div>
        <div onClick={() => { setShowUserStatPopup(true) }}>
          <div className="flex justify-center h-[25px]">
            <div className="flex absolute bottom-[10%] short:bottom-[13%] justify-center items-center bg-white h-[35px] w-[35px] rounded-full" >
              <i className="fa-solid fa-angle-up" style={{ color: 'red' }} />
            </div>
          </div>
        </div>
        <BottomNav dashboardActive />
      </div>

      {/* Journey Introduction modal */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/6 w-full max-w-sm rounded bg-white">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRef}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="mySwiper pop-up"
              >
                
                <SwiperSlide>
                  <JourneyInfo />
                  <HowItWorks />
                </SwiperSlide>
              </Swiper>
              <button
                onClick={() => {
                  // Check if it is the last slide
                  if (swiperRef.current.swiper.isEnd) {
                    setIsOpen(!isOpen);
                    setBuddyPopUpIsOpen(true);
                  }
                  else {
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideNext();
                      return;
                    }
                  }

                }}
                className="mb-2 h-14 w-14 flex justify-center items-center text-2xl rounded-full bg-secondary font-medium text-white"
              >
                <img src={buttonArrow} alt="forword button" />
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Buddy popup */}
      <Dialog
        open={buddyPopisOpen}
        onClose={() => setBuddyPopUpIsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/5 w-full max-w-sm rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefBuddy}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pop-up-buddy"

                onSlideChange={(swiper) => { console.log(swiper); if (swiperRefBuddy.current.swiper.isEnd) { setShowNotificationBtn(true) } }}
              >
                <SwiperSlide>
                  <BuddyPopup bubbleContent={"Hello, I'm your check-in buddy. I'll note your daily check-ins and add them to your Journey."} />
                </SwiperSlide>
                <SwiperSlide>
                  <BuddyPopup bubbleContent={"Enable notifications for daily check-in prompts and increase your chances of success!"} />
                </SwiperSlide>
              </Swiper>
              <div className="w-full h-[10rem] flex flex-col items-center">
              {!showNotificationBtn && <button
                onClick={() => {
                  localStorage.setItem("day1", "true");

                  // Check if it is the last slide
                  if (swiperRefBuddy.current.swiper.isEnd) {
                    setBuddyPopUpIsOpen(!setBuddyPopUpIsOpen);
                  }
                  else {
                    if (swiperRefBuddy.current && swiperRefBuddy.current.swiper) {
                      console.log(swiperRefBuddy.current.swiper.activeIndex)
                      if (swiperRefBuddy.current.swiper.activeIndex === 1) {

                      }
                      swiperRefBuddy.current.swiper.slideNext();
                      console.log("after", swiperRefBuddy.current.swiper.activeIndex)
                      return;
                    }
                  }

                }}
                className="btn-primary font-medium text-white mb-8"
              >
                Next
              </button>}
              {showNotificationBtn && <><button
                onClick={() => { localStorage.setItem("enableNotification", "true"); navigate(ROUTES.SETTING); }}
                className="btn-primary font-medium text-white"
              >
                Enable Notifications
              </button>
                <CustomButton
                  label={'Maybe Later'}
                  buttonStyleClass="text-white custom-text"
                  buttonContainerClass="mt-[20px] mb-0"
                  handleClick={() => {
                    setBuddyPopUpIsOpen(false)
                    setBuddyPopDay1IsOpen(true)
                  }}
                /></>}
                </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Buddy popup day 1*/}
      <Dialog
        open={buddyPopDay1isOpen}
        onClose={() => setBuddyPopDay1IsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/5 w-full max-w-sm rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefBuddyDay1}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pop-up-buddy"

                onSlideChange={(swiper) => { console.log(swiper); if (swiperRefBuddyDay1.current.swiper.isEnd) { setShowNotificationBtn(true) } }}
              >
                <SwiperSlide>
                  <BuddyPopup bubbleContent={"Great job, buddy! You've successfully completed the task for day one."} />
                </SwiperSlide>
                <SwiperSlide>
                  <BuddyPopup bubbleContent={"Feel free to explore the Program. I will see you tomorrow."} />
                </SwiperSlide>
              </Swiper>
              <div className="w-full h-[10rem] flex flex-col items-center">
              {!showExploreBtn && <button
                onClick={() => {
                  // Check if it is the last slide
                  if (swiperRefBuddyDay1.current.swiper.isEnd) {
                    setBuddyPopDay1IsOpen(!setBuddyPopDay1IsOpen);
                    setShowExploreBtn(true)
                  }
                  else {
                    if (swiperRefBuddyDay1.current && swiperRefBuddyDay1.current.swiper) {
                      console.log(swiperRefBuddyDay1.current.swiper.activeIndex)
                      if (swiperRefBuddyDay1.current.swiper.activeIndex == 0) {
                        setShowExploreBtn(true)
                      }
                      swiperRefBuddyDay1.current.swiper.slideNext();
                      console.log("after", swiperRefBuddyDay1.current.swiper.activeIndex)
                      return;
                    }
                  }

                }}
                className="btn-primary font-medium text-white mb-8"
              >
                Next
              </button>}
              {showExploreBtn && <button
                onClick={() => { localStorage.removeItem("day1"); setBuddyPopDay1IsOpen(false) }}
                className="btn-primary font-medium text-white"
              >
                Explore Program
              </button>}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Buddy popup day 2*/}
      <Dialog
        open={buddyPopDay2isOpen}
        onClose={() => setBuddyPopDay2IsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/5 w-full max-w-sm rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefBuddyDay2}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pop-up-buddy"

                onSlideChange={(swiper) => { console.log(swiper); if (swiperRefBuddyDay2.current.swiper.isEnd) { setShowNotificationBtn(true) } }}
              >
                <SwiperSlide>
                  <BuddyPopup bubbleContent={<>Welcome back, buddy!<br /> Lets get you checked-in.</>} />
                </SwiperSlide>
                <SwiperSlide>
                  <BuddyPopup dialogBoxStyle={{justifyContent:'flex-start'}} style={{textAlign:'left'}} bubbleContent={<>One last note,You will progress in 3 ways,  based on your check-ins:<br />
                    <div className="flex items-center"><div className="min-w-[35%]">Missed</div> <div className="progress-bar-missed"></div></div>
                    <div className="flex items-center"><div className="min-w-[35%]">Positive</div> <div className="progress-bar-positive"></div></div>
                    <div className="flex items-center"><div className="min-w-[35%]">Negative</div> <div className="progress-bar-negative"></div></div>
                  </>
                  } />
                </SwiperSlide>
              </Swiper>
              <div className="w-full h-[10rem] flex flex-col items-center">
              {!showCheckinBtn && <button
                onClick={() => {
                  // Check if it is the last slide
                  console.log("swiperRefBuddyDay2 index", swiperRefBuddyDay2.current.swiper.activeIndex, swiperRefBuddyDay2.current.swiper.isEnd)
                 
                  if (swiperRefBuddyDay2.current.swiper.isEnd) {
                    setBuddyPopDay2IsOpen(false);
                  }
                  else {
                    if (swiperRefBuddyDay2.current && swiperRefBuddyDay2.current.swiper) {
                      if (swiperRefBuddyDay2.current.swiper.activeIndex == 0) {
                        swiperRefBuddyDay2.current.swiper.slideNext();
                        setShowCheckinBtn(true)
                      } else {
                        swiperRefBuddyDay2.current.swiper.slideNext();
                        console.log("after", swiperRefBuddyDay2.current.swiper.activeIndex)
                      }
                      return;
                    }
                  }

                }}
                className="btn-primary font-medium text-white mb-8"
              >
                Next
              </button>}
              {showCheckinBtn && <button
                onClick={() => {setOpenDay(2); setBuddyPopDay2IsOpen(false);
                  if((Array.isArray(feelingsData)) && (feelingsData?.length > 0)){
                    setDailyCheckUpModal(true); 
                  }
                   }}
                className="btn-primary font-medium text-white mb-8"
              >
                Checkin Now
              </button>}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* dailyCheckUpModal */}
      <Dialog
        open={dailyCheckUpModal}
        onClose={() => setDailyCheckUpModal(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex flex-col  items-center justify-center bg-black bg-opacity-80 p-4">
          <img src={userProfileData?.Badges?.batchUrl ?? buddy} alt="" className="w-44" />
          <Dialog.Panel className="w-full rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefcheckin}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                initialSlide={dailyCheckUpModalIndex == true ? 1 : 0}
                className="myswiper pop-up"
                onSlideChange={(swiper) => { console.log("swiper checkin", swiper); }}
              >
                <SwiperSlide style={{paddingBottom:"40px"}}>  {/*padding is the height of slider's pagination  */}
                  {/* <Feelings /> */}
                  <div className="m-auto rounded-xl bg-white px-5 py-10" style={{width:"95%"}}>
                    <div className="flex gap-4">
                      <img src={checkInIcon} alt="check-in" />
                      <p className="subtitle-2 text-[#002744]"> Daily Check-In</p>
                    </div>
                    <div className="font-normal mt-3 font-[Helvetica-Neue] text-[16px] text-[#262626]">
                      How are you feeling today?
                    </div>

                    <div className="mt-10">
                      {selectedFeeling?.id === 0 ? (
                        <div className="grid grid-cols-3 items-center justify-center gap-8">
                          {feelingsData &&
                            Array.isArray(feelingsData) &&
                            feelingsData?.map((option: any) => {
                              return (
                                option.title !== "Not Now" && (
                                  <div
                                    onClick={() => {
                                      const payload = {
                                        userId: userProfileData?.id,
                                        ratingId: option.id,
                                      };
                                      setHowAreYouFeeling(payload)
                                      dispatch(setSelectedFeeling(option));
                                    }}
                                    className="flex h-[91px] w-[73px] flex-col items-center justify-center bg-[#FFF6F1] py-[10px] text-center"
                                    style={{ borderRadius: "5px" }}
                                  >
                                    <img
                                      style={{
                                        height: "41px",
                                        width: "41px",
                                        objectFit: "cover",
                                      }}
                                      className="h-20 w-10 object-contain short:h-7 short:w-7"
                                      src={option.imageUrl}
                                    />
                                    <p
                                      className="text-[#595959]"
                                      style={{ paddingTop: "10px" }}
                                    >
                                      {option.title}
                                    </p>
                                  </div>
                                )
                              );
                            })}
                        </div>
                      ) : selectedFeeling?.id <= 3 ? (
                        <div className="flex justify-center flex-col items-center mt-5">
                          <div className="feelingsImgContainer bg-[#FFF6F1] w-[140px] h-[141px] short:w-[70px] short:h-[71px] rounded-full flex justify-center items-center relative">
                            <div className="absolute left-0 bottom-8">
                              <img className="pop w-20 h-10" alt="done-popup" src={done} />
                            </div>
                            <img
                              className="imageStyle"
                              alt="selected-feeling"
                              src={selectedFeeling.imageUrl}
                            />
                          </div>
                          <p className="text-[Helvetica-Neue] text-[#002744] font-medium text-base py-[30px]">
                            {" "}
                            {"Wow, that’s great!"}
                          </p>

                          <button className="text-secondary text-[Helvetica-Neue]" onClick={() => swiperRefcheckin.current.swiper.slideNext()}>Continue Check-In</button>

                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <img className="imageStyle w-[120px]" alt="notepad-img" src={notepad} />
                          <p className="text-[Helvetica-Neue] text-[#002744] font-medium text-base py-2 mt-4 short:mt-0">
                            {" "}
                            {"Dont worry, you’ve got this"}
                          </p>
                          <p className="custom-text text-[#8C8C8C]">
                            {" "}
                            {"Remember your Motivation..."}
                          </p>
                          <div className="my-6 short:my-2">
                            <CustomButton
                              label={"My Motivation"}
                              handleClick={() => {
                                console.log("test")
                                localStorage.setItem("fromCheckinDialog", "true")
                                navigate(ROUTES.MYMOTIVATIONS);
                                // dispatch(setFeelingsData(""));
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <button className="text-secondary text-[Helvetica-Neue]" onClick={() => swiperRefcheckin.current.swiper.slideNext()}>Continue Check-In</button>
                          </div>
                        </div>
                      )}
                    </div>
                    <button className="text-secondary text-[Helvetica-Neue]" onClick={() => swiperRefcheckin.current.swiper.slideNext()}></button>

                  </div>
                </SwiperSlide>
                <SwiperSlide style={{paddingBottom:"40px"}}> {/*padding is the height of slider's pagination  */}
                  {/* <Craving /> */}
                  <div className="m-auto w-[345px] rounded-xl bg-white px-5 py-10 short:h-[380px] short:w-[340px]">
                    <div className="flex gap-4">
                      <img src={checkInIcon} alt="check-in" />
                      <p className="subtitle-2 text-[#002744]"> Daily Check-In</p>
                    </div>
                    <div className="ont-normal mt-3 font-[Helvetica-Neue] text-[16px] text-[#262626]">
                      Have your cravings improved?
                    </div>
                    <div className="flex mt-[50px]">
                      <div className="subtitle-2 text-secondary mr-14" onClick={() => { if (dailyCheckUpModalIndex == true) { setDailyCheckUpModalIndex(false); localStorage.removeItem("fromCheckinDialog"); } return swiperRefcheckin.current.swiper.slideNext() }}>
                        Yes
                      </div>
                      <div className="subtitle-2 text-secondary" onClick={() => { if (dailyCheckUpModalIndex == true) { setDailyCheckUpModalIndex(false); localStorage.removeItem("fromCheckinDialog"); } return swiperRefcheckin.current.swiper.slideNext() }}>
                        No
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{paddingBottom:"40px"}}> {/*padding is the height of slider's pagination  */}
                  {/* <DidYouKnow /> */}
                  <div className="m-auto w-[345px] rounded-xl bg-white px-6 py-10 short:h-[380px] short:w-[340px]">
                    <div className="subtitle-2 text-center">
                      Did you know?
                    </div>
                    <div className="mt-4 justify-center">
                      Combining a behavioral support tool with a Nicotine Replacement Therapy (NRT) product, like Nicorette, can help increase your chances of quit success.
                      Find    which product is right for you. Source
                    </div>
                    <div className="flex flex-col items-center gap-4 mt-14">
                      <a href="https://www.cvs.com/shop/merch/stop-smoking-resources?widgetID=2jnw80jz&mc=cvscontentpage" target="_blank" onClick={()=>trackEvents(Events.CLICKED_LEARN_MORE)}>
                        <button className="w-[133px] px-6 py-[10px] bg-secondary text-white font-medium rounded-full">Learn More</button>
                      </a>
                      <button className="text-secondary font-medium" onClick={() => { 
                        if (openDay == 2) {  
                          setBuddyPopDay2SecondIsOpen(true); 
                        setOpenDay(0) } 
                        if (openDay == 3) { 
                          setShowUserStatPopup(true) 
                        } 
                          setDailyCheckUpModal(false); 
                         }}>Dismiss</button>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Buddy popup second day 2*/}
      <Dialog
        open={buddyPopDay2SecondisOpen}
        onClose={() => setBuddyPopDay2SecondIsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/5 w-full max-w-sm rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefBuddySecondDay2}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pop-up-buddy"

                onSlideChange={(swiper) => { console.log(swiper); if (swiperRefBuddySecondDay2.current.swiper.isEnd) { setShowNotificationBtn(true) } }}
              >
                <SwiperSlide>
                  <BuddyPopup bubbleContent={<>Congratulations on your first check-in! Check your Dashboard to view your progress.</>} />
                </SwiperSlide>
              </Swiper>
              <div className="w-full h-[10rem] flex flex-col items-center">
              {<button
                onClick={() => {  setShowUserStatPopup(true) }}
                className="btn-primary font-medium text-white mb-8"
              >
                Dashboard
              </button>}
                </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Buddy popup day 3*/}
      <Dialog
        open={buddyPopDay3isOpen}
        onClose={() => setBuddyPopDay3IsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/5 w-full max-w-sm rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefBuddyDay3}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pop-up-buddy"

                onSlideChange={(swiper) => { console.log(swiper); if (swiperRefBuddyDay3.current.swiper.isEnd) { setShowNotificationBtn(true) } }}
              >
                <SwiperSlide>
                  <BuddyPopup bubbleContent={<>Welcome back, buddy!<br /> Lets get you checked-in.</>} />
                </SwiperSlide>

              </Swiper>
              <div className="w-full h-[10rem] flex flex-col items-center">
              <button
                onClick={() => { setBuddyPopDay3IsOpen(false); setOpenDay(3); setFromDay3(true);
                  if((Array.isArray(feelingsData)) && (feelingsData?.length > 0)){
                  setDailyCheckUpModal(true); 
                } }} //fault
                className="btn-primary font-medium text-white mb-8"
              >
                Checkin Now
              </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Buddy popup day 3 second popup*/}
      <Dialog
        open={buddyPopDay3SecondisOpen}
        onClose={() => setBuddyPopDay3SecondIsOpen(false)}
        className="relative bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-3/5 short:h-4/5  w-full max-w-sm rounded bg-transparent">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRefBuddySecondDay3}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pop-up-buddy"

                onSlideChange={(swiper) => { console.log(swiper); }}
              >
                <SwiperSlide>
                  <BuddyPopup bubbleContent={<>Great going, buddy!<br />Explore our Support section for helpful articles and programs on quitting.</>} />
                </SwiperSlide>

              </Swiper>
                <div className="w-full h-[10rem] flex flex-col items-center">
              <button className="text-secondary font-medium" onClick={() => setBuddyPopDay3SecondIsOpen(false)}>Dismiss</button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {console.log("@@@@ badge url",badgeUrl)}
      {/* Badge popup */}
      {(badgeUrl && isMilestoneReached) && <Dialog
        open={isMilestoneReached}
        onClose={() => setIsMilestoneReached(false)}
        className='relative bg-blue-100'
      >
        <div className='fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4 background'>
          <Dialog.Panel className='h-3/5 short:h-4/5 w-full max-w-sm rounded bg-transparent'>
            <div className='flex h-full flex-col items-center'>
              <span className='text-white text-2xl font-normal pb-[40px] font-[Museo]'>
                {`${badgeInfo?.['achievement_badge_id'] === 2 ? "First" : ""}  Milestone reached!`} 
              </span>

              <img
                src={badgeUrl}
                alt=''
                className='w-44'
              />
              <div className="font-[Helvetica-Neue]">
                <p className='text-white text-center py-[15px] text-base font-medium'>
                  {badgeInfo?.['badgeInfo']}
                </p>

                <p className="font-normal text-center pt-2">
                  <span className='text-white text-sm py-[5px]'>
                    Keep checking in daily.
                  </span>
                  <br />
                  <span className='text-white text-sm mb-[50px] my-[5px]'>
                    Each check-in brings you closer to the smoke-free life.
                  </span>
                </p>
              </div>
              <div className="pt-10">
                <button
                  onClick={() => { navigate(ROUTES.ACHIEVEMENTS) }}
                  className='btn-primary w-40 font-medium text-white my-[10px]'
                >
                  View badge
                </button>
              </div>

              <div>
                <button
                  onClick={() => { localStorage.setItem("addContact", "false"); navigate(ROUTES.MYCONTACTS); setIsMilestoneReached(false); }}
                  className='custom-subtitle-2 w-40 bg-white py-2 px-6 text-secondary border border-secondary rounded-full'
                >
                  Share
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>}

      <LeftMenu />
      {showUserStatPopup && <UserStats onDrawerClose={onDrawerClose}  showUserStatPopup={showUserStatPopup} />}


    </>
  );
}
export function trackScroll(
  elementId: string,
  scrollOnce: boolean,
  EventName: string
) {
  let myDiv = document.getElementById(elementId);

  function handleScroll() {
    if (myDiv) {
      let scrollPosition = myDiv.scrollTop || 0;
      let windowHeight = myDiv.clientHeight;
      let pageHeight = myDiv.scrollHeight;
      let percentage = Math.round(
        (scrollPosition / (pageHeight - windowHeight)) * 100
      );
      if (
        (percentage > 0 && percentage < 5) ||
        percentage > 50 ||
        percentage === 100
      ) {
        trackEvents(EventName, { percentage: percentage });
        trackEventsPendo(EventName, { percentage: percentage });
      }
      // Stop tracking once user has scrolled to the bottom of the element
      if (scrollPosition + windowHeight >= pageHeight) {
        myDiv.removeEventListener("scroll", handleScroll);
        scrollOnce = true;
      }
    }
  }
  if (myDiv && !scrollOnce) {
    myDiv.addEventListener("scroll", handleScroll);
  }
}