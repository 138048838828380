import React, {useEffect} from "react";
import DashboardHeader from "../dashboard/DashboardHeader";
import account from "../../assets/icons/account.svg";
import email from "../../assets/icons/email.svg";
import lock from "../../assets/icons/lock.svg";
import logout from "../../assets/icons/logout.svg";
import arrow from "../../assets/icons/forwordArrowGray.svg";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes";
import {useDispatch, useSelector} from "react-redux";
import {setIsLogoutPopupOpen} from "../../slices/dashboardSlice";
import LogoutPopup from "../LogoutPopup";
import { trackEvents } from "../../common/utils/mixpanel";
import Footer from "../../common/footer";
import { trackEventsPendo } from "../../common/utils/pendo";

const Home=()=> {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {userProfileData} = useSelector(
        (state: any) => state.steps
    );
    const {isLogoutPopupOpen} = useSelector(
        (state: any) => state.dashboard
    );
    const name = localStorage.getItem("userName") ?? ''
    const userName = userProfileData?.user_name ? userProfileData?.user_name[0]?.toUpperCase() : name[0]?.toUpperCase();

    useEffect(()=>{
        trackEvents('on_myAccount_home')
        trackEventsPendo('on_myAccount_home')
    },[])

    const MenuComponent = (props: any) => {
        const {leftIcon, title, subtitle, rightArrow, handleMenuClick, isLastOption = false, isLogout = false} = props
        return <div className={isLastOption ? "menuLastItem" : "menuItem"} onClick={handleMenuClick}>
            <div className="homeImgContainer">
                <img src={leftIcon} alt="LeftIcon"/>
            </div>
            <div className="menuDetails">
                <p className={isLogout ? "menuTitleLogout" : "menuTitle"}>{title}</p>
                {subtitle && <p className="menuSubtitle">{subtitle}</p>}
            </div>
            {rightArrow && <div className="homeImgContainer">
                <img src={rightArrow} alt="arrow"/>
            </div>}
        </div>

    }
    return <div className="bg-white flex flex-col grow h-[100dvh]">
        <div className="dashboardContainer">
            <DashboardHeader back={true} title="My Account" handleBack={()=>{navigate(ROUTES.DASHBOARD)}}/>
        </div>
        <div className="flex grow flex-col overflow-scroll">
            <div className="homeUserNameContainer">
                <div className="homeUserNameDiv">
                    <p className="homeUserNameInitial">{userName}</p>
                </div>
                <p className="homeUserName subtitle">
                    {userProfileData.user_name ?? name}
                </p>
            </div>
            <div>
                <div className="px-[24px]">
                    <MenuComponent leftIcon={account} title={"About me"} subtitle={"Name, Age and Nicotine intake"}
                                   rightArrow={arrow} handleMenuClick={() => {
                        navigate(ROUTES.ABOUTME)
                    }}/>
                    <MenuComponent leftIcon={email} title={"Email"} subtitle={userProfileData?.email}/>
                    <MenuComponent leftIcon={lock} title={"Password"} subtitle={"Change your Password"}
                                   rightArrow={arrow} handleMenuClick={() => { navigate(ROUTES.CHANGEPASSWORD)
                    }}/>
                    <MenuComponent leftIcon={lock} title={"My contacts"} subtitle={"Manage your contacts"} isLastOption
                                   rightArrow={arrow} handleMenuClick={() => {
                        navigate(ROUTES.MYCONTACTS)
                    }}/>
                </div>
                <div style={{borderBottom: '8px solid #F5F5F5'}}/>
                <div style={{borderBottom: '8px solid #F5F5F5'}}/>
                <div className="px-[24px]">
                    <MenuComponent leftIcon={logout} title={"Log out"} isLastOption isLogout handleMenuClick={() => {
                        dispatch(setIsLogoutPopupOpen(true))
                    }}/>
                </div>
            </div>
            {isLogoutPopupOpen && <LogoutPopup/>}
        <Footer />
        </div>
        
    </div>
}
export default Home;