import React, {useEffect} from "react";

//Toast Types : Info, Success, Warning, Error
const ToastMessage=(props:any)=>{
    const {toastType='Success', message="Success", handleClose=()=>{}}=props

    useEffect(()=>{
        setTimeout(()=>{
            handleClose()
        },5000)
    },[])

    const handleToastMessage=()=> {
        switch (toastType) {
            case 'Info':
                return <div
                    className="bg-blue-600 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                    id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                    <div
                        className="bg-blue-600 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-blue-500 rounded-t-lg">
                        <p className="font-bold text-white flex items-center">
                            <i className="fa-solid fa-circle-info" style={{color:'white', paddingRight:'10px'}}/>
                            {toastType}
                        </p>
                        <div className="flex items-center" onClick={()=>{handleClose()}}>
                            <i className="fa-solid fa-xmark" style={{color:'white', paddingRight:'10px'}}/>
                        </div>
                    </div>
                    <div className="p-3 bg-blue-600 rounded-b-lg break-words text-white">
                        {message}
                    </div>
                </div>
            case 'Success':
                return <div
                    className="bg-green-500 shadow-lg mx-auto w-full max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                    id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                    <div
                        className="bg-green-500 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-green-400 rounded-t-lg">
                        <p className="font-bold text-white flex items-center">
                            <i className="fa-solid fa-circle-check" style={{color:'white', paddingRight:'10px'}}/>
                            {toastType}
                        </p>
                        <div className="flex items-center" onClick={()=>{handleClose()}}>
                            <i className="fa-solid fa-xmark" style={{color:'white', paddingRight:'10px'}}/>
                        </div>
                    </div>
                    <div className="p-3 bg-green-500 rounded-b-lg break-words text-white">
                        {message}
                    </div>
                </div>
            case 'Warning':
                return <div
                    className="bg-yellow-500 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                    id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                    <div
                        className="bg-yellow-500 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-yellow-400 rounded-t-lg">
                        <p className="font-bold text-white flex items-center">
                            <i className="fa-solid fa-triangle-exclamation" style={{color:'white', paddingRight:'10px'}}/>
                            {toastType}
                        </p>
                        <div className="flex items-center" onClick={()=>{handleClose()}}>
                            <i className="fa-solid fa-xmark" style={{color:'white', paddingRight:'10px'}}/>
                        </div>
                    </div>
                    <div className="p-3 bg-yellow-500 rounded-b-lg break-words text-white">
                        {message}
                    </div>
                </div>
            case 'Error':
                return <div
                    className="bg-red-600 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                    id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                    <div
                        className="bg-red-600 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-red-500 rounded-t-lg">
                        <p className="font-bold text-white flex items-center">
                            <i className="fa-solid fa-circle-xmark" style={{color:'white', paddingRight:'10px'}}/>
                            {toastType}
                        </p>
                        <div className="flex items-center" onClick={()=>{handleClose()}}>
                            <i className="fa-solid fa-xmark" style={{color:'white', paddingRight:'10px'}}/>
                        </div>
                    </div>
                    <div className="p-3 bg-red-600 rounded-b-lg break-words text-white">
                        {message}
                    </div>
                </div>
            default:
        }
    }
    return <div className="flex flex-col justify-center absolute w-full bottom-0 py-[10px] px-[10px]">
        {handleToastMessage()}
    </div>
}
export default ToastMessage