import QuestionCardHeader from "./questionCardHeader/QuestionCardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import nico from "../../../assets/images/nico_brand.png";
import NextButton from "../NextButton";
import QuestionDescription from "../QuestionDescription";
import QuestionOptions from "../QuestionOptions";
import { trackScroll } from "../../dashboard/Dashboard";
import Copyright from "../../../common/footer/copyright";
import navlogo from "../../../assets/images/nicoretteupNew.png";
import Footer from "../../../common/footer";
import useCheckMobileScreen from "../../../common/useCheckMobileScreen";
import { CustomButton } from "../../../common/CustomButton";
import { ROUTES } from "../../../routes";
import { useNavigate } from "react-router";
import { decrement, decrementCurrentQuestion, gotoQuestion } from "../../../slices/stepSlice";
import CustomStepper from "../CustomStepper";

export default function QuestionCardNew() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useCheckMobileScreen();
  const currentQuestion = useSelector(
    (state: any) => state.steps.currentQuestion
  );
  const {userChoice} = useSelector(
    (state: any) => state.steps
  );
  const multiSelectQuestionNumbers = [3, 4, 5, 12];
  let cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef && cardRef.current) {
      cardRef.current.scrollTo(0, 0);
    }
    if (currentQuestion === 3) {
      trackScroll("options", false, "why-scroll");
    }
    if (currentQuestion === 4) {
      trackScroll("options", false, "when-scroll");
    }
    if (currentQuestion === 5) {
      trackScroll("options", false, "goals-scroll");
    }
  }, [currentQuestion]);
  const showNextButton =
    multiSelectQuestionNumbers.includes(currentQuestion) ||
    currentQuestion === 10;


  const handleBack=()=>{
    if(currentQuestion === 0){
      navigate(ROUTES.GETSTARTED)
      localStorage.clear()
      return
  }
  if(currentQuestion === 10 && userChoice.maintenance.selection.includes("low")){
    dispatch(decrement())
     dispatch(gotoQuestion(6))
     return
  }
  if(currentQuestion === 3 || currentQuestion === 5 ||currentQuestion === 6 || currentQuestion === 10 || currentQuestion === 11 ){
    dispatch(decrement())
    dispatch(decrementCurrentQuestion())
    return
   }
  if(currentQuestion === 12){
    navigate(ROUTES.QUESTIONCARD)
    dispatch(decrementCurrentQuestion())
    return
  }
   dispatch(decrementCurrentQuestion())

  }
  return (
    <>
     <div className="flex min-h-screen flex-col justify-start">
        <div>
          {/* <h1>Hrushikesh</h1> */}
          {/* nav for mobile */}
          <div className="bg-custom-blue flex h-[70px]  items-center justify-around px-5 text-white sm:flex md:hidden">
            <img src={navlogo} alt="cvs logo" className="h-[22px]" />
          </div>

          {/* nav for Desktop */}
          <div className="bg-custom-blue hidden h-[80px]  px-10 text-white md:flex md:items-center md:justify-between">
            <img src={navlogo} alt="Logo" />
          </div>
          {/* <QuestionCardHeader /> */}
        </div>
        {/* Description section */}

        <div className="flex w-full flex-col md:flex-row md:justify-start ">
          {/* {isMobile ? 
          <QuestionCardHeader /> :  */}
          <CustomStepper />
          {/* } */}

          <div className=" flex  w-full flex-col   overflow-clip  pb-5 md:gap-2 md:px-16 md:py-10 min-h-[75vh]">
            <div className="px-6">
              
              <QuestionDescription />
            </div>

            <div
              id="options"
              ref={cardRef}
              className={
                currentQuestion !== 10 ? "overflow-auto px-6" : "overflow-auto"
              }
              // style={{marginTop:'14%'}}
            >
              <QuestionOptions />
            </div>


            <div className="hideen md:flex justify-start items-start max-w-[59vw] px-6 flex-row gap-5 mt-auto pt-5">
              <CustomButton
              centeralign={false}
              handleClick={handleBack}
                label={"Back"}
                buttonStyleClass="hidden md:block btn-primary-desktop h-[60px] max-w-[28vw] item-start"
              />
              {
multiSelectQuestionNumbers.includes(currentQuestion) &&
<div className="hidden md:flex w-full">
  <NextButton /> 
  </div>
              }
            </div>

            <div className="flex md:hidden flex-row  justify-between items-center px-5  ">
            <CustomButton
              centeralign={false}
              handleClick={handleBack}
                label={"Back"}
                buttonStyleClass="block md:hidden btn-primary w-[43vw] py-3 h-[45px]"
              />
              {
multiSelectQuestionNumbers.includes(currentQuestion) &&
  <NextButton /> 
              }
            </div>
          </div>

          
        </div>
        {/* Button section */}
        <div>
          {currentQuestion === 10 && (
            <>
              <div className="flex justify-center">
                <NextButton />
              </div>
              <div className="flex justify-center">
                <img src={nico} alt="Nicorette Brand" className="p-2" />
              </div>
            </>
          )}
          {/* {multiSelectQuestionNumbers.includes(currentQuestion) && (
            <div className="fixed bottom-0 flex w-full flex-col justify-center bg-white py-4 ">
              <NextButton />

              <Copyright className="pt-[5px]" />
            </div>
         )} */}
        </div>
        {/* {!multiSelectQuestionNumbers.includes(currentQuestion) && <Copyright />} */}

        {/* {!multiSelectQuestionNumbers.includes(currentQuestion) && (
          <div className="bg-custom-dark-blue fixed bottom-0 flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
            <Footer showLinks={false} showBehaviouralText={false} />
          </div>
        )} */}
{
  <div className="bg-custom-dark-blue flex b-0 w-full mt-auto items-center justify-center text-center text-white md:h-[150px] h-[100px]">
            <Footer showLinks={false} showBehaviouralText={false} />
          </div>

}

      </div>
    </>
  );
}