import {store} from "../store";
import {get, post, remove} from "./common";
import {
    saveAnswer,
    setAppConfig,
    setIsLoggedIn,
    setIsNewUser,
    setLoading,
    setUserProfileData
} from "../slices/stepSlice";
import {
    addUserFriendUrl,
    getAppConfigUrl,
    getProductsUrl,
    loginUrl,
    removeUserFriendUrl, resetPasswordUrl,
    ShareProgress, setForgotPassMailUrl,
    setUserBadgeUrl,
    signupUrl,
    getProspectUrl
} from "./constants";
const dispatch = store.dispatch;


export const getAppConfig=async ()=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await get(getAppConfigUrl);
            if (response.status === 200) {
                dispatch(setAppConfig(response?.data?.data))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}

export const setProspectUser=async (payload)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(getProspectUrl,payload);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}

export const onSignUp=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(signupUrl, payload);
            if (response.status === 200) {
                sessionStorage.setItem('sessionToken',response?.data?.data[0]?.token)
                localStorage.setItem('signupData', JSON.stringify(response?.data?.data[0]?.currentUser))
                dispatch(setUserProfileData(response?.data?.data[0]?.currentUser))
                dispatch(setIsNewUser(true))
                dispatch(setIsLoggedIn(true))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setIsNewUser(true))
            dispatch(setLoading(false));
            reject(e?.response.data.signupResult);
        }
    })
}

export const onLoginUser=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(loginUrl, { email_id: payload.email_id, password: payload.password});
            if (response.status === 200) {
                sessionStorage.setItem('sessionToken',response?.data?.response?.token)
                localStorage.setItem('loggedIn', payload.checkedIn ? 'true' : 'false')                
                dispatch(setUserProfileData(response?.data?.response?.loginDetail[0]))
                dispatch(setIsLoggedIn(true))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setIsLoggedIn(false))
            dispatch(setLoading(false));
            reject(e?.response?.data.response);
        }
    })
}

export const addUserFriends=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(addUserFriendUrl, payload);
            if (response.status === 200) {
                let members:any = [...response?.data?.response?.afterInsertResponse]
                let objectPayload: object = {
                    serverValue: "members",
                    memberDetails: members ,
                    isDisabled: members <= 0
                };
                dispatch(saveAnswer(objectPayload))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}

export const removeUserFriends=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await remove(removeUserFriendUrl, payload);
            if (response.status === 200) {
                let members:any = [...response?.data?.response?.afterDeleteResponse]
                let objectPayload: object = {
                    serverValue: "members",
                    memberDetails: members ,
                    isDisabled: members <= 0
                };
                dispatch(saveAnswer(objectPayload))
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}
export const sendProgressMail=async (memberDetails:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            const user = store.getState().steps.userProfileData;
            const userStatData = store.getState().dashboard.userStatData
            let memberDetailList:any=[]
            memberDetails?.map((obj:any)=>memberDetailList.push({email:obj.email,name:obj.name}))
            let response:any = await get(ShareProgress, {params:{bccemail: JSON.stringify(memberDetailList),senderName:user?.user_name.toString(),badgeUrl:userStatData?.current_badge?.batchUrl.toString()}});
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}


export const setUserBadge=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(setUserBadgeUrl, payload);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}

export const sendForgotPasswordMail=(payload:any)=>{
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await get(setForgotPassMailUrl, {params:{toemail: payload.toString()}});
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}

export const resetPassword=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(resetPasswordUrl, payload);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}

export const getFilteredProducts=(payload:any)=>{
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await get(getProductsUrl, {params:payload});
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false));
            reject(e);
        }
    })
}