import back_icon from "../../assets/icons/backArrow.svg";
import logo from "../../assets/icons/branding_details.svg";
import coupon from "../../assets/images/coupon.png";
import niccorate from "../../assets/images/nico_brand.png"
import ellips from '../../assets/icons/ellipse.svg'
import Card from "../../common/Card";

export default function Coupon(props:any) {
    return (
        <div className="h-full flex flex-col">
            <div className="h-20 bg-primary flex flex-col gap-2 justify-around py-2">
                <div className="flex justify-center">
                    <div>
                        <img src={logo} alt="cvs logo"/>
                    </div>
                </div>
                <div className="flex justify-around bg-primary">
                    <div>
                        <button onClick={props.handleCuponBack}>
                            <img src={back_icon} alt="back button"/>
                        </button>
                    </div>
                    <h3 className="custom-h2 text-white">My Savings</h3>
                    <div/>
                </div>
            </div>
            <div className="flex justify-center basis-3/4 mt-3 px-6 short:px-4">
                <div>
                  <img src={coupon} alt="" />
                </div>
            </div>
            <div className="flex flex-col gap-2">
                {/*TODO: Add Terms & Conditions links*/}
                <div className="flex justify-center gap-3">
                    <p className="text-[12px] short:text-[11px] text-[#8C8C8C] font-[Helvetica-Neue]">Terms</p>
                    <img src={ellips} alt=""/>
                    <p className="text-[12px] short:text-[11px] text-[#8C8C8C] font-[Helvetica-Neue]">Return policy</p>
                </div>
                <div className="flex justify-center">
                    {/* <img src={niccorate} alt="nicorette"/> */}
                </div>
            </div>
        </div>
    );
}
