import mixpanel from "mixpanel-browser"

export const trackEvents =async (eventName,params) =>{
    console.log("@@@@ event",eventName,params)
    setTimeout(()=>{
        if(mixpanel?.get_distinct_id()){
            mixpanel.track(eventName,params)
        }else{
            mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN); 
            mixpanel.track(eventName,params)
        }
    },1000)
}