import footer from "../../../assets/images/journey_logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import Branding from "../../../common/header/Branding";
import { userNameRegex, userEmailIdRegex } from "../../../common/utils/Constants";
import { CustomButton } from "../../../common/CustomButton";
import { InputField } from "../../../common/InputField";
import './notifications.css'
import tick from "../../../assets/icons/tick.svg";
import { trackEvents } from "../../../common/utils/mixpanel";
import { trackEventsPendo } from "../../../common/utils/pendo";

export default function OtpSuccess() {
  let day1 = localStorage.getItem("day1");

  if (day1 == "true") {
    localStorage.setItem("fromsms", 'true');
  }

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    trackEvents('on_get_started')
    trackEventsPendo('on_get_started')
  }, [])
  function handleChange(e: any) {

    const result: boolean = userNameRegex.test(e.target.value);
    if (e.target.value === "") {
      setName("");
      setError("");
    } else if (result) {
      setName(e.target.value);
      setError("");
    }
  }

  function handleEmailChange(e: any) {
    setEmail(e.target.value);
  }

  function getStarted() {
    // localStorage.setItem("userName", name);
    // localStorage.setItem("userEmail", email);
    navigate(ROUTES.DASHBOARD);
  }
  return (
    <>
      <div className="basis-full h-full flex flex-col md:border-2 bg-sky-200">
        <Branding />
        <div className="flex justify-center px-6 py-7 ">
          <h2 className="custom-h2 text-slate-700">SMS notifications enabled successfully </h2>
        </div>
        {/* <div className="flex justify-left px-10">
          <p className="text-slate-700 custom-text text-left">We will send a One Time Password on this mobile number</p>
        </div> */}

        <div className="flex justify-center px-5 py-5 mt-5">
          <button
            disabled={true}
            className="disabled:bg-[#E4E4E4] disabled:text-[#434343] subtitle-2 bg-secondary text-white py-[10px] px-[24px] rounded-full w-[340px]"
            style={{ background: '#2E4A6A', borderRadius: '48px' }}
          >
            <div className="flex justify-center gap-3 px-5"><div><button><img src={tick} alt="back" /></button></div><div className="basis-4/5"><p style={{ color: '#FFFFFF', fontFamily: 'Helvetica Neue' }}>Phone Number Verified</p></div><div></div></div>
          </button>
        </div>

        {/* <div className="flex justify-center px-5 py-2">
          <CustomButton
            label={'Next'}
            buttonContainerclassName="flex justify-center short:px-2 w-full"
            buttonStyleclassName="subtitle-2 bg-secondary text-white py-3 px-6 short:px-4 rounded-full w-full"
            handleClick={getStarted}
          />
        </div> */}

        <div className="flex px-4 justify-center py-5 mt-5 short:px-2" style={{ alignItems: "left" }}>
          <CustomButton
            label={'Next'}
            buttonContainerClass="flex justify-center px-3 short:px-2"
            buttonStyleClass="subtitle-2 bg-secondary text-white py-3 px-6 short:px-4 rounded-full w-[340px]"
            handleClick={getStarted}
          />
        </div>

        <div className="flex justify-center otpin-footer">
          <img src={footer} alt="journey to freedom" className="h-[120px] object-contain" />
        </div>
      </div >

    </>
  );
}