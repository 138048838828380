import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import cartIcon from '../../assets/icons/add_cart.svg'
import Rating from '../rating/Rating';
import {useDispatch, useSelector} from 'react-redux';
import { addToCart } from '../../slices/stepSlice';
import { setParentScreen} from "../../slices/dashboardSlice";
import React, {useState} from "react";
import { Dialog } from "@headlessui/react";
import close from "../../assets/icons/close.svg";
import {CustomButton} from "../CustomButton";
import { trackEvents } from '../utils/mixpanel';
import { trackEventsPendo } from '../utils/pendo';

export default function ProductCard(props:any){
    const {product, parentPath}=props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

    const {isLoggedIn, cart, isNewUser} = useSelector(
        (state: any) => state.steps
    );
    function handleAddCart() {
        if (cart.length) {
            setShowConfirmationPopup(true)
        } else {
            handleAddToCart()
        }
    }

    const handleAddToCart = () => {
        trackEvents('added_product_in_cart',{ productSku: product?.productSku })
        trackEventsPendo('added_product_in_cart',{ productSku: product?.productSku })
        dispatch(addToCart(product))
        if (!isLoggedIn) {
            navigate(ROUTES.SIGNUP);
        } else {
            navigate(ROUTES.DASHBOARD)
        }
    }

    function handleLearnMore(){
        trackEvents('learn_more',{productSku: product?.productSku,productName:product?.productName})
        trackEventsPendo('learn_more',{productSku: product?.productSku,productName:product?.productName})
        dispatch(setParentScreen(parentPath))
        navigate(ROUTES.PRODUCTDETAILS,{state:product})
    }

    return (
      <div className="flex flex-col h-[372px] border border-[#D9D9D9] rounded">
        <div className="basis-1/2 flex justify-center items-center">
          <div className='w-28 h-32'>
          <img
            src={product.productImageURL}
            alt={product.productBrand + product.productForm}
            className="block object-contain"
          />
          </div>
        </div>
        <div className='w-full p-2'>
          <p className='text-xs text-center'>{product.productSkuSize}</p>
          </div>
        <hr />
        <div className="flex flex-col justify-evenly p-2 gap-2 basis-1/2">
          <div>
            <h3 className="subtitle-2 text-primary">
              {product.productBrand} {product.productForm}
            </h3>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <Rating startRating={parseFloat(product.productRating)} />
            </div>
            <div className="text-[12px] text-[#8C8C8C] short:text-[11px]">
              {product.productRating} ({product.productReview} Reviews)
            </div>
          </div>
          <div>
            <h3 className="custom-subtitle text-primary">$ {product.productPrice}</h3>
          </div>
          <div className="subtitle-2 h-[40px] text-secondary text-center">
              <CustomButton
                  label={'Learn More'}
                  buttonStyleClass="custom-subtitle-2 text-secondary px-5"
                  handleClick={handleLearnMore}/>
          </div>
        </div>
          {showConfirmationPopup && <Dialog
              open={showConfirmationPopup}
              onClose={() => {
                  setShowConfirmationPopup(false)
              }}
              className="absolute top-0 z-50 w-full md:w-96 md:mx-[40%]"
          >
              <div
                  className="fixed w-full md:w-96  h-full md:h-[80vh] md:mt-8 bg-black/30 "
                  aria-hidden="true"
              />
              <Dialog.Panel className="relative">
                  <div className="h-72 bg-[#F5F5F5] short:h-60 border rounded-lg shadow-md mx-5 my-[50%]">
                      <div className="h-1/4 bg-[#F5F5F5] flex justify-center items-center relative">
                          <h3 className="w-full text-center custom-h2">Replace product</h3>
                          <button
                              className="absolute right-4 top-4"
                              onClick={() => {
                                  setShowConfirmationPopup(false)
                              }}
                          >
                              <img src={close} alt="close"/>
                          </button>
                      </div>
                      <div className="flex flex-col justify-start items-center gap-1 h-3/4 bg-[#FFFFFF] ">
                          <div className="px-[20px] py-[5px] flex flex-col text-center justify-center">
                          <p className="custom-subtitle text-[#8C8C8C]">
                              This action will replace current cart item with selected product.
                          </p>
                          <p className="custom-subtitle text-[#8C8C8C] pt-[5px]">
                              Are you sure you want to replace product?
                          </p>
                          </div>
                          <div className="flex pt-[5px]">
                              <div>
                                  <CustomButton
                                      buttonStyleClass="custom-subtitle-2 text-secondary py-2 px-5"
                                      handleClick={() => {
                                          setShowConfirmationPopup(false)
                                          handleAddToCart()
                                      }}
                                      label={'Yes'}
                                  />
                              </div>
                              <div className="basis-1/2">
                                  <CustomButton
                                      handleClick={() => {
                                          setShowConfirmationPopup(false)
                                      }}
                                      buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border w-40 border-secondary rounded-full"
                                      label={'No'}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </Dialog.Panel>
          </Dialog>}
      </div>
    );
}