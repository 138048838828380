import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvents } from "../../common/utils/mixpanel";
import { saveAnswer } from "../../slices/stepSlice";
import { MultiSelect } from "../../types/product";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function Habbits(props:MultiSelect) {
  const dispatch = useDispatch();
  const isMobile = useCheckMobileScreen();
  const choice = useSelector((state: any) => state.steps.userChoice);
  const [enabled, setEnabled] = useState(false);
  function handleClick() {
    const payload = {
        title:props.value,
        serverValue:props.id
      }
      trackEvents( "question_fifth_find_yourself_selected",{"answer":props.value})
      trackEventsPendo( "question_fifth_find_yourself_selected",{"answer":props.value})
    dispatch(saveAnswer(payload));
  }
  useEffect(() => {
    if (choice.habbit_behaviour.selection.includes(props.value)) {
      setEnabled(true);
    }
  }, []);
  return (
    <>
    <div
      id={props.id}
      className={`${
        enabled
          ? "border-[1.5px] border-secondary h-72  rounded-lg"
          : "border border-[#BFBFBF] h-72  rounded-lg"
      } `}
      onClick={() => {
        handleClick();
        setEnabled(!enabled);
      }}
    >
      <div className="flex flex-col h-full">
        <div
          className="basis-2/4 p-2 border-[0px] rounded-lg md:basis-3/4"
          style={{
            backgroundImage: `url(${props.image})`,
            backgroundSize: isMobile?"contain":"cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <input
            type="checkbox"
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
            className="block  accent-secondary"
          />
        </div>
        <div className="basis-2/4 flex flex-col justify-start p-2 gap-1 h-full">
          <div>
            <h3 className="custom-subtitle text-left">{props.value}</h3>
          </div>
          <div>
            <p className="text-[12px] font-[Helvetica-Neue] text-[#8C8C8C] text-left -tracking-2">
              {props.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}