import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvents } from "../../common/utils/mixpanel";
import { saveAnswer } from "../../slices/stepSlice";
import { MultiSelect } from "../../types/product";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function Why(props:MultiSelect) {
    const dispatch = useDispatch();
    const choice = useSelector((state: any) => state.steps.userChoice);
    const [enabled, setEnabled] = useState(false);

    function handleClick() {
      const payload = {
        title:props.value,
        serverValue:props.id
      }
      trackEvents( "question_fourth_What_your_why_selected",{"answer":props.value})
      trackEventsPendo( "question_fourth_What_your_why_selected",{"answer":props.value})
      dispatch(saveAnswer(payload));
    }
    useEffect(() => {
      if (choice.motivations.selection.includes(props.value)) {
        setEnabled(true);
      }
    }, []);
    return (
      <>
          <div
            id={props.id}
            className={`${
              enabled
                ? "border-[1.5px] border-secondary  rounded-lg px-6 py-5  short:px-4 md:min-h-[180px] flex justify-center items-center"
                : "border border-[#BFBFBF]  rounded-lg  px-6 py-5 short:px-4 md:min-h-[180px] flex justify-center items-center" 
            } `}
            onClick={()=> {
              handleClick();
              setEnabled(!enabled)
            }}
          >
            <div className="flex justify-center h-full gap-2">
              <div className="flex flex-col justify-center items-center  pt-3  gap-4">
                <div>
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={() => setEnabled(!enabled)}
                    className=" accent-secondary w-[18px] h-[18px]"
                  />
                </div>
                <div className="h-10 w-10 short:h-8 short:w-8">
                  <img src={props.image} alt="icon" />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <div className="pl-2 text-left">
                  <h3 className="custom-subtitle text-lg text-[#002744]">
                    {props.value}
                  </h3>
                  <p className="custom-text text-left text-sm text-[#8C8C8C] ">
                    {props.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
      </>
    );
}
