import axios from "axios";

export const resetState = () => ({
  type: "RESET_STATE",
});

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "http://localhost:3000/api",
});

instance.interceptors.request.use(
  async (config: any) => {
    const tokenData = sessionStorage.getItem("sessionToken");
    if (tokenData) {
      config.headers["Authorization"] = `Bearer ${tokenData}`;
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const post = (url: string, payload: any) => {
  return instance.post(url, payload);
};

export const get = (url: string, params?: any) => {
  return instance.get(url, params);
};

export const remove = (url: string, params?: any) => {
  return instance.delete(url, { data: params });
};
