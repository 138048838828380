import { setSmsNotificationsData, setLoading } from "../slices/stepSlice";
import { post } from "./common";
import { store } from "../store";
import { getSmsOtp, verifyOtp, unsubscribe } from "./constants";
import { setSessionExpired } from "../slices/dashboardSlice";
const dispatch = store.dispatch;

export const getOtpForSmsNotifications = async (payload: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const notificationTime = localStorage.getItem("notificationTime")
            let params = { ...payload, timeZone: timeZone, sms_notification_time: notificationTime }

            let response: any = await post(getSmsOtp, params);
            console.log("otp", response)
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    })
}

export const verifyNotificationsOtp = async (payload: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const notificationTime = localStorage.getItem("notificationTime")

            let params = { ...payload, timeZone: timeZone, sms_notification_time: notificationTime }
            let response: any = await post(verifyOtp, params);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    })
}

export const unsubscribeSms = async (payload: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let params = { ...payload }
            let response: any = await post(unsubscribe, params);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    })
}