import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswer } from "../../slices/stepSlice";

export default function Replacement() {
    const dispatch = useDispatch();
    const {questionnaire, currentQuestion, userChoice} = useSelector((state: any) => state.steps);
    let type = Object.keys(questionnaire)[currentQuestion];

    function handleClick(item: any) {
      let response = {
        serverValue: item.serverValue,
        selection: item.name,
      };
      dispatch(saveAnswer(response));
    }

    return (
        <RadioGroup onChange={() => {}} value={userChoice.maintenance["selection"]}>
          <div className="flex flex-col basis-3/4 gap-3">
            {questionnaire[type].Options.map((item: any) => {
              return (
                <RadioGroup.Option
                  key={item.id}
                  value={item.name}
                  onClick={() => handleClick(item)}
                >
                  {({ checked }) => (
                    <div
                      className={`${
                        checked
                          ? "border-[1.5px] border-secondary h-[125px] w-full rounded-lg px-1"
                          : "border border-[#BFBFBF] h-[125px] w-fll rounded-lg px-1"
                      } `}
                    >
                      <div className="flex justify-center gap-2 h-full">
                        <div className="flex flex-col items-center justify-evenly basis-1/4 p-1">
                          <div>
                            <input
                              type="radio"
                              checked={checked}
                              // defaultChecked={checked}
                              onChange={() => !checked}
                              className="accent-secondary w-[20px] h-[20px] m-1"
                            />
                          </div>
                          <div className="h-10 w-10 short:h-8 short:w-8">
                            <img src={item.url} alt="icon" />
                          </div>
                        </div>
  
                        <div className="flex justify-center items-center w-full ">
                          <div className="px-2">
                            <h3 className="text-base font-[Helvetica-Neue] short:text-sm text-[#595959]">
                              {item.name}
                            </h3>
                            {/* <p className="custom-text text-[#8C8C8C] ">
                              {item.description}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              );
            })}
          </div>
        </RadioGroup>
    );
}
