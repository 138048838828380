import { useSelector } from "react-redux";
import buddy from "../../assets/icons/buddy.svg";
import BubblePopup from "./bubblePopup";
import _ from 'lodash';

type buddyPopUpType = {
  bubbleContent: any;
  dialogBoxStyle?:any;
  style?:any;
};

export default function BuddyPopup({ bubbleContent,dialogBoxStyle,style }: buddyPopUpType) {
  const userProfileData = useSelector(
    (state: any) => state.steps.userProfileData
  );
  let res = localStorage.getItem("selectedBuddy")
  res = JSON.parse(res)
  const selectedBuddy = _.get(res, 'selection.batchUrl');

  return (
    <div className="flex h-full flex-col">
        <BubblePopup text={bubbleContent} dialogBoxStyle={dialogBoxStyle} style={style}/>
      <div className="ml-12">
        <img
          src={userProfileData?.Badges?.batchUrl ?? selectedBuddy ?? buddy}
          alt="badge"
          className="w-44"
        />
      </div>
    </div>
  );
}
