export const Events = {
    CLICKED_ON_CVS_RESOURCE : 'clicked_on_cvs_resource',
    CLICKED_ON_MINUTE_RESOURCE : 'clicked_on_minute_resource',
    CLICKED_ON_NICORETTE_RESOURCE : 'clicked_on_nicorette_resource',
    CLICKED_ON_CDC_RESOURCE: 'clicked_on_cdc_resource',


    CONTACTS_ADDED : "contacts_added",
    COUPON_VIEWED : "coupon_viewed",
    CLICKED_LEARN_MORE : "clicked_on_learn_more"
}