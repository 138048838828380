import React, {Fragment, useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import edit from "../../assets/icons/edit.svg";
import DashboardHeader from "../dashboard/DashboardHeader";
import {InputField} from "../../common/InputField";
import {CustomButton} from "../../common/CustomButton";
import {useSelector} from "react-redux";
import {Listbox, Transition} from "@headlessui/react";
import {saveAboutMeDetails} from "../../api/myaccount";
import {userNameRegex} from "../../common/utils/Constants";
import {ROUTES} from "../../routes";
import {getUserDetails} from "../../api/dashboard";
import ToastMessage from "../../common/ToastMessage";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import Footer from "../../common/footer";
import { trackEventsPendo } from "../../common/utils/pendo";

const AboutMe=()=> {
    const navigate = useNavigate();
    const {userProfileData, questionnaire} = useSelector(
        (state: any) => state.steps
    );
    const [isEditable, setIsEditable] = useState(false)
    const [selectedAgeGroup, setSelectedAgeGroup] = useState(userProfileData?.age)
    const [selectedIntake, setSelectedIntake] = useState(userProfileData?.currentUsage)
    const [userName, setUserName] = useState(userProfileData?.user_name)
    const [userNameError, setUserNameError] = useState('')
    const [showToast, setShowToast] = useState({show:false,toastType:'', message:'' });

    useEffect(()=>{
        if(!showToast.show) {
            if (isEditable) {
                trackEvents('on_about_me_edit')
                trackEventsPendo('on_about_me_edit')
            } else {
                trackEvents('on_about_me')
                trackEventsPendo('on_about_me')
            }
        }
    },[isEditable])

    const handleNameChange=(e:any)=>{
        setUserNameError('')
        const result: boolean = userNameRegex.test(e.target.value);
        if (e.target.value === "") {
            setUserName("");
            setUserNameError('Please enter the name.')
        } else if (result) {
            setUserName(e.target.value);
        }
    }

    const handleSave=async ()=>{
        trackEvents('updated_user_info')
        trackEventsPendo('updated_user_info')
        try {
            const payload = {
                userId: userProfileData?.id,
                userName: userName,
                age: selectedAgeGroup,
                email: userProfileData.email,
                currentUsage: selectedIntake
            }
            const result: any = await saveAboutMeDetails(payload)
            if (result.status === 200) {
                getUserDetails({email_id: userProfileData?.email});
                setIsEditable(!isEditable)
                setShowToast({show: true, toastType: 'Success', message: 'User details saved successfully.'})
            }
        }catch (e){
            setShowToast({show: true, toastType: 'Error', message: 'Something went wrong. Please try again.'})
        }
    }
    return <div className="container bg-white flex flex-col grow h-full">
        {showToast.show && <ToastMessage toastType={showToast.toastType}
                                         message={showToast.message}
                                         handleClose={()=>{setShowToast({show: false, toastType:'', message:''})}} />}
        <div className="flex flex-col bg-sky-200 relative">
            <DashboardHeader back={true} title="My Account" handleBack={() => {
                navigate(ROUTES.HOME)
            }}/>
        </div>
        <div className="flex justify-center items-center mb-5 mt-4 px-5">
            <p className="custom-h3 w-[100%] py-[8px]">
                {isEditable ? 'Edit Info' : 'About me'}
            </p>
            {!isEditable && <div className="flex py-[8px]" onClick={() => {
                setIsEditable(!isEditable)
            }}>
                <img src={edit}/>
                <p className="subtitle-2 text-secondary px-[10px]">{'Edit'}</p>
            </div>}
        </div>
        <div className="flex flex-col px-5 w-full">
            <div className="w-full">
                <p className="custom-smallText text-[#8C8C8C]">{'Name'}</p>
                {isEditable ? <InputField type={'text'} value={userName} handleChange={(e:any)=>{handleNameChange(e)}}
                                          charLength={15}
                                          inputContainerClass="flex justify-end pb-5 short:py-1 items-center"
                                          inputStyleClass="px-[32px] py-[15px] w-full border-[0.75px] border-[#8C8C8C] text-[#434343] rounded-full"/> :
                    <p className="custom-bodyText text-[#434343]" style={{paddingBottom:'20px'}}>{userProfileData?.user_name}</p>}
            </div>
            {userNameError && <div className="flex justify-center">
                <h5 className="text-secondary custom-text">{userNameError}</h5>
            </div>}
            <div className="w-full">
                <p className="custom-smallText text-[#8C8C8C]">{'Email'}</p>
                {isEditable ? <InputField type={'text'} value={userProfileData?.email} isDisabled
                                          inputContainerClass="flex justify-end pb-5 short:py-1 items-center"
                                          inputStyleClass="px-[32px] py-[15px] w-full bg-[#F0F0F0] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"/> :
                    <p className="custom-bodyText text-[#434343]" style={{paddingBottom:'20px'}}>{userProfileData?.email}</p>}
            </div>
            <div className="w-full">
                <p className="custom-smallText text-[#8C8C8C]">{'Age range'}</p>
                {isEditable ? <div className="pb-5">
                    <Listbox value={selectedAgeGroup} onChange={(value:any) =>setSelectedAgeGroup(value)}>
                        <div className="relative h-[55px]">
                            <Listbox.Button className="relative w-full h-[55px] cursor-default border-[0.75px] border-[#8C8C8C] rounded-full bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-secondary sm:text-sm">
                                <span className="block truncate text-[#434343] px-[20px]">{selectedAgeGroup}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
                        <i className="fa-solid fa-angle-down text-[#8C8C8C]"/>
                      </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opacity-100 focus:outline-none sm:text-sm z-50 ">
                                    {questionnaire?.ageRange?.Options?.map((option:any, index:number) => (
                                        <Listbox.Option
                                            key={index}
                                            className={({ selected }) =>
                                                `relative cursor-default select-none py-2 pl-5 pr-4 ${
                                                    selected
                                                        ? "bg-secondary text-white"
                                                        : "text-gray-900"
                                                }`
                                            }
                                            value={option.id}
                                        >
                                            {({ selected }) => (
                                                <>
                                <span
                                    className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                    }`}
                                >
                                  {option.name}
                                </span>
                                                    {selectedAgeGroup ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"/>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div> : <p className="custom-bodyText" style={{paddingBottom:'20px'}}>{userProfileData?.age}</p>}
            </div>
            <div className="w-full">
                <p className="custom-smallText text-[#8C8C8C]">{'Nicotine Intake'}</p>
                {isEditable ? <div className="pb-5">
                    <Listbox value={selectedIntake} onChange={(value:any) => {setSelectedIntake(value)}}>
                        <div className="relative h-[55px]">
                            <Listbox.Button className="relative w-full h-[55px] cursor-default border-[0.75px] border-[#8C8C8C] rounded-full bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-secondary sm:text-sm">
                                <span className="block truncate text-[#434343] px-[20px]">{selectedIntake}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
                        <i className="fa-solid fa-angle-down text-[#8C8C8C]"/>
                      </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opacity-100 focus:outline-none sm:text-sm z-50 ">
                                    {questionnaire?.currentUsage?.Options?.map((options:any, index:number) => (
                                        <Listbox.Option
                                            key={index}
                                            className={({ selected }) =>
                                                options.active ? `relative cursor-default select-none py-2 pl-5 pr-4 ${
                                                    selected
                                                        ? "bg-secondary text-white"
                                                        : "text-gray-900"
                                                }` : "hidden"
                                            }
                                            value={options.name}
                                        >
                                            {({ selected }) => (
                                                <>
                                <span
                                    className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                    }`}
                                >
                                  {options.name}
                                </span>
                                                    {selectedIntake ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"/>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div> : <p className="custom-bodyText" style={{paddingBottom:'20px'}}>{userProfileData?.currentUsage}</p>}
            </div>
        </div>
        {isEditable && <div className="py-[20px]">
            <CustomButton isDisabled={userNameError} label={"Save"} handleClick={()=>{handleSave()}}
                          buttonStyleClass="disabled:bg-[#E4E4E4] disabled:text-[#434343] subtitle-2 bg-secondary text-white py-[10px] px-[24px] rounded-full w-[200px]"/>
            <CustomButton label={"Cancel"} handleClick={()=>{setIsEditable(!isEditable)}}
                          buttonStyleClass="subtitle-2 text-secondary py-2 px-6 short:px-3 border border-secondary rounded-full w-[200px]"/>
        </div>}
        <Footer/>
    </div>
}
export default AboutMe