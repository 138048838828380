import React, { useEffect, useState } from "react";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import { ROUTES } from "../../routes";
import { Link, useNavigate } from "react-router-dom";
import navlogo from "../../assets/images/nicoretteupNew.png";
import QR from "../../assets/images/qrprod.png";
import Footer from "../../common/footer";
import Divider from "@mui/material/Divider";
import CustomStepper from "./CustomStepper";
import { useSelector } from "react-redux";
import { useVariableValue } from "@devcycle/react-client-sdk";

function QrDesktop() {
  const variableKey = "landing-with-ads";
  const defaultValue = false;
  const landingfeaturewithads = useVariableValue(variableKey, defaultValue);
  const isMobile = useCheckMobileScreen();
  let user = useSelector((state: any) => state.steps.userProfileData.user_name);
  let newUser = useSelector((state: any) => state.steps.isNewUser);

  // const [user, setUSer]= useState()
  const navigate = useNavigate();
  useEffect(() => {
    if (isMobile) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [isMobile]);

  const adsData = [
    {
      Title: "Start to Stop®",
      Description:
        "Get personal counseling with a MinuteClinic® nurse practitioner to help you quit smoking.",
      extra: "Learn about our smoking cessation program",
      url: "https://storage.googleapis.com/nrtimage/habbits/istockphoto-1129154058-612x612.jpeg",
    },
    {
      Title: "What happens when you stop smoking?",
      Description:
        "Learn what to expect on your quit journey. ",
      extra: "Read about what happens when you quit",
      url: "https://storage.googleapis.com/nrtimage/habbits/istockphoto-1312969224-612x612.jpeg",
    },
    {
      Title: "What to do when cigarette cravings hit?",
      Description:
        "Resist the urge helpful tips to aid you on your quit.",
      extra: "Learn what to do when cravings hit",
      url: "https://storage.googleapis.com/nrtimage/habbits/seeking_focus.png",
    },
  ];
  return (
    <div className="flex min-h-screen  flex-col justify-start bg-primary-blue ">
      {/* nav for Desktop */}
      <div className="bg-custom-blue hidden h-[80px]  px-10 text-white md:flex md:items-center md:justify-between">
        <img src={navlogo} alt="Logo" />
      </div>

      <div className="flex w-full flex-col md:flex-row md:justify-start ">
        {
          newUser&& <CustomStepper />
        }
        
        <div className="short:gap-4 flex  w-full flex-col  gap-6 overflow-clip   md:gap-2 md:px-16 md:py-10">
          <div className="pt-10 min-h-[20vh] w-[100%] flex justify-center items-center flex-col">
            <div className="bg-white lg:w-[70%] md:w-[80%] sm:w-[90%] flex flex-col shadow-lg sm:pb-10">
              {/* CONGRATS */}
              <div className="text-center">
                <h1 className="custom-h1 md:text-[2rem] text-center pt-10 lg:text-[4rem] flex-row w-full text-custom-dark-blue sm:leading-tight">
                  {newUser? "Congratulations ": "Welcome Back "}
                  <span className="text-secondary">
                    {user ? user : "User "} !
                  </span>{" "}
                </h1>
              </div>

              {/* DIVIDER */}
              <Divider sx={{ mt: 5, color: "grey" }} />

              {/* QRSECTION */}
              <div className="w-full flex flex-row justify-start pt-10 sm:px-10">
                <div className="w-[70%] sm:px-0 lg:px-20 md:px-0 pr-20 ">
                  <h1 className="custom-h2 sm:text-[2rem] sm:text-left md:text-left text-custom-dark-blue lg:text-[3rem] sm:leading-snug ">
                    Next Steps
                  </h1>
                  <h1 className="py-5 text-[1rem] text-[#595959]">
                    To move forward with your Journey to Freedom, please scan
                    this QR code from your mobile device or visit{" "}
                    <Link to="https://journeytofreedom.vividplay.net/login">
                      https://journeytofreedom.vividplay.net/login
                    </Link>{" "}
                    and continue with mobile for the best experience.{" "}
                  </h1>
                </div>
                <div className="lg:w-[25%]">
                  <img src={QR} alt="Logo" />
                </div>
              </div>

              {landingfeaturewithads && (
                <div className="w-full flex flex-col justify-start items-center min-h-[500px] lg:px-16 lg:pt-10">
                  <h1 className="custom-h2 lg:text-left sm:text-left text-[2rem] text-custom-dark-blue sm:px-10 leading-tight">
                    Resources to help motivate, inspire and inform your journey
                  </h1>

                  {adsData?.map((item,key) => {
                    return (
                      <div className="w-full flex flex-row justify-around px-10 py-5">
                        <div
                          className={`w-[50%] rounded-md lg:h-[250px] sm:h-[200px] order-${key%2===0 ? "1" : "2" }`}
                          style={{
                            backgroundImage: `url(${item.url})`,
                            backgroundSize: isMobile ? "contain" : "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className={`w-[50%] min-h-[250px] px-5 order-1`}>
                          <h1 className="custom-h2 lg:text-[2rem] sm:text-[1.5rem] text-custom-dark-blue text-left leading-tight">{item.Title }</h1>
                          <h1 className="sm:text-[0.75rem] sm:leading-snug sm:pt-4 lg:text-[1rem] pt-5 leading-6 font-light">{item.Description}</h1>
                          <h1 className="sm:text-[0.75rem] sm:leading-snug sm:pt-3 lg:text-[1rem] text-[18px] pt-5 leading-6 font-light">{item.extra}</h1>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="h-[50px] bg-neutral-100 lg:w-[70%] z-100 shadow-lg"></div>
          </div>
        </div>
      </div>

      <div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px] mt-auto">
        <Footer showLinks={false} showBehaviouralText={true} />
      </div>
    </div>
  );
}

export default QrDesktop;
