import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvents } from "../../common/utils/mixpanel";
import { saveAnswer } from "../../slices/stepSlice";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function Usage() {
    const dispatch = useDispatch();
    const {questionnaire, userChoice, currentQuestion} = useSelector((state: any) => state.steps);
    let type = Object.keys(questionnaire)[currentQuestion];

    function handleClick(item: any) {
        let response = {
            serverValue: item.payloadValue,
            selection: item.name,
        };
        trackEvents( "question_third_usage_selected",{"answer":item.name})
        trackEventsPendo( "question_third_usage_selected",{"answer":item.name})
        dispatch(saveAnswer(response));
    }

    return (
        <div>
            <RadioGroup value={userChoice.currentUsage["selection"]}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-10 md:pt-3">
                    {questionnaire[type].Options.map(
                        (item: any, index: any) => {
                            return (
                                <RadioGroup.Option
                                    value={item.name}
                                    key={index}
                                    onClick={() => handleClick(item)}
                                    className={item.active ? "" : "hidden"}
                                >
                                    {({checked}) => (
                                        <div
                                            className={
                                                checked
                                                    ? "md:h-[150px] h-16 border-[1.5px] border-secondary rounded-lg flex justify-center items-center py-4 px-5"
                                                    : "md:h-[150px] h-16 flex justify-center items-center border border-[#BFBFBF] rounded-lg py-4 px-5"
                                            }
                                        >
                          <span
                              id={item.id}
                              className={
                                  checked
                                      ? "custom-text block"
                                      : "custom-text block"
                              }
                          >
                            {item.name}
                          </span>
                                        </div>
                                    )}
                                </RadioGroup.Option>
                            );
                        }
                    )}
                </div>
            </RadioGroup>
        </div>
    )
}