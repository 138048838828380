import { Tab } from "@headlessui/react";
import BottomNav from "./BottomNav";
import DashboardHeader from "./DashboardHeader";
import unlock from "../../assets/icons/unlock_badge.svg";
import LeftMenu from "../../common/LeftMenu";
import { setIsLeftDrawerOpen, setShowCart } from "../../slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import LogoutPopup from "../LogoutPopup";
import Cart from "./Cart";
// import coupon from '../../assets/images/newcoupon.png'
// import barcode from '../../assets/images/barcode.png'
import './coupon.css'
import coupon from '../../assets/images/NicoretteCoupon.png'
import { Dialog } from "@headlessui/react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { HowItWorks, AchievementInfo } from "./acheivementGuide";
import buttonArrow from '../../assets/icons/buttonArrow.png'
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { trackEvents } from "../../common/utils/mixpanel";
import { Events } from "../../common/Mixpanel_Events_constant";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function Achievements() {
  const dispatch = useDispatch();
  const badges = useSelector((state: any) => state.dashboard.journeyData);
  const { isLeftDrawerOpen, isLogoutPopupOpen, showCart } = useSelector(
    (state: any) => state.dashboard
  );
  const getNextBadgeToUnlock = useSelector(((state: any) => state.dashboard.userStatData))
  const { isNewUser } = useSelector((state: any) => state.steps);
  const toggleMenuDrawer = () => {
    dispatch(setIsLeftDrawerOpen(!isLeftDrawerOpen));
  };

  let [isOpen, setIsOpen] = useState(isNewUser ? true : false);
  const swiperRef = useRef(null);

  useEffect(() => {
    trackEvents("on_achievement_screen")
    trackEventsPendo("on_achievement_screen")
  }, [])

  const handleTab = (index) => {
    if (index) {
      trackEvents(Events.COUPON_VIEWED)
      trackEventsPendo(Events.COUPON_VIEWED)
    }
  }
  return (
    <>
      <div className="flex flex-col bg-sky-200 h-full w-full fixed">
        <DashboardHeader
          menu={true}
          title="My Journey"
          cart={true}
          handleMenu={() => toggleMenuDrawer()}
          handleCart={() => {
            dispatch(setShowCart(!showCart));
          }}
        />
        <div className="bg-white h-full overflow-hidden">
          <Tab.Group onChange={(index: any) => handleTab(index)}>
            <Tab.List className=" flex justify-around mt-5 short:mt-2">
              <Tab className="w-full pl-2">
                {({ selected }) => (
                  <div>
                    <p
                      className={
                        selected
                          ? "subtitle-2 text-secondary"
                          : "subtitle-2 text-[#8C8C8C]"
                      }
                    >
                      Badges
                    </p>
                    <div
                      className={
                        selected
                          ? "h-1  bg-secondary mt-4"
                          : "h-1 bg-[#8C8C8C] mt-4"
                      }
                    />
                  </div>
                )}
              </Tab>
              <Tab className="w-full pr-2">
                {({ selected }) => (
                  <div>
                    <p
                      className={
                        selected
                          ? "subtitle-2 text-secondary"
                          : "subtitle-2 text-[#8C8C8C]"
                      }
                    >
                      Savings
                    </p>
                    <div
                      className={
                        selected
                          ? "h-1  bg-secondary mt-4"
                          : "h-1 bg-[#8C8C8C] mt-4"
                      }
                    />
                  </div>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto px-6 pt-6 short:px-5 short:py-3">
              <Tab.Panel className="pb-[100px]">
                <div className="grid grid-cols-2 items-center">
                  {badges?.map((badge: any, index: number) => {
                    return (
                      badge.badge_ids !== null && (
                        <div key={index} className="object-cover w-40 h-36 flex flex-col items-center justify-center">
                          <img src={badge.badge_ids.batchUrl} alt="badge" className="h-full w-28" />
                          <p className="text-[#BFBFBF] text-[14px] text-center ">
                            {badge.badge_ids.batchName.toUpperCase()} SMOKE FREE
                          </p>
                        </div>
                      )
                    );
                  })}
                  {badges.length < 83 &&
                    <div className="object-cover w-40 h-36 flex flex-col items-center justify-center pt-2">
                      <img src={unlock} alt="badge" className="h-auto w-28 mb-1" />
                      <p className="text-[#BFBFBF] text-[14px] text-center mt-2">
                        {getNextBadgeToUnlock?.next_badge.batchName.toUpperCase()} SMOKE FREE
                      </p>
                    </div>

                  }
                </div>
              </Tab.Panel>

              <Tab.Panel className="pb-[100px]">
                <div className="row">
                  <div className="coupon-box">
                    <img src={coupon} alt="" style={{ width: "100%", objectFit: 'contain' }} />
                  </div>

                  {/* <div className="coupon-box">
                    <div>
                      <img src={coupon} alt="" className="coupon-image" />
                    </div>
                    <div>
                      <img src={barcode} alt="" className="coupon-barcode" />
                    </div>
                    <div className="coupon-desc">
                      Redeemable at food, drug and mass merchandise stores. CONSUMER: Limit 1 coupon per purchase of indicated product(s). You pay any sales tax. RETAILER: Haleon group of companies will reimburse you the face value of this coupon plus 12¢ Handling Fee if submitted in compliance with our Redemption Policy. Copy available upon request. Cash value 1/100 of 1¢. Coupon is void if altered, transferred, exchanged, sold or auctioned, copied or if prohibited by law. Good only in USA and at APOs, FPOs. Send coupons to: Haleon group of companies, P.O. Box 880001, El Paso, TX 88588-0001. ©2023 Haleon group of companies. Read and follow label directions.
                    </div>
                  </div> */}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <BottomNav achievementsIsActive />
        <LeftMenu />
        {isLogoutPopupOpen && <LogoutPopup />}
        {!isLeftDrawerOpen && (isNewUser || showCart) && <Cart />}
      </div>
      {/* Acheivement Introduction modal */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50 bg-blue-100"
      >
        <div className="fixed w-full inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-[80%] w-full max-w-sm rounded bg-white overflow-auto">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRef}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="mySwiper pop-up"
              >
                <SwiperSlide>
                  <AchievementInfo />
                </SwiperSlide>

              </Swiper>
              <button
                onClick={() => {
                  // Check if it is the last slide
                  if (swiperRef.current.swiper.isEnd) {
                    setIsOpen(!isOpen);
                    // setBuddyPopUpIsOpen(true);
                  }
                  else {
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideNext();
                      return;
                    }
                  }

                }}
                className="mb-[20px] h-14 w-14 flex justify-center items-center text-2xl rounded-full bg-secondary font-medium text-white"
              >
                <img src={buttonArrow} alt="forword button" />
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
