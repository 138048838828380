import { createSlice } from "@reduxjs/toolkit";
import {ROUTES} from "../routes";

const initialState: any = {
    isLeftDrawerOpen:false,
    isLogoutPopupOpen:false,
    selectedFeeling:{id: 0, title: '', imageUrl: ''},
    feelingsData:[],
    showCart:false,
    parentPath:ROUTES.DASHBOARD,
    isSessionExpired:false,
    journeyData:[],
    userStatData:{},
    popupShown:false
};
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setIsLeftDrawerOpen:(state,data)=>{
            state.isLeftDrawerOpen= data.payload
        },
        setIsLogoutPopupOpen:(state,data)=>{
            state.isLogoutPopupOpen= data.payload
        },
        setSelectedFeeling:(state,data)=>{
            state.selectedFeeling= data.payload
        },
        setFeelingsData:(state,data)=>{
            state.feelingsData= data.payload
        },
        setShowCart:(state,data)=>{
            state.showCart= data.payload
        },
        setParentScreen:(state,data)=>{
            state.parentPath= data.payload
        },
        setSessionExpired:(state,data)=>{
            state.isSessionExpired= data.payload
        },
        setJourneyData:(state,data)=>{
            state.journeyData= [...data.payload]
        },
        setUserStatData:(state,data)=>{
            state.userStatData= data.payload
        },
        setPopupShown:(state,data)=>{
            state.popupShown= data.payload
        }
    }
})

export const {
    setIsLeftDrawerOpen,
    setIsLogoutPopupOpen,
    setSelectedFeeling,
    setFeelingsData,
    setShowCart,
    setParentScreen,
    setSessionExpired,
    setJourneyData,
    setUserStatData,
    setPopupShown
} = dashboardSlice.actions;
export default dashboardSlice.reducer;