import { useSelector } from "react-redux";


export default function Progress() {
const numOfSteps = useSelector((state: any) => state.steps.currentQuestion);
let steps: any[] = [];
for (let i = 0; i < 6; i++) {
    let item = (
      <div key={i} className="bg-[#002744] h-[8px] w-full max-w-full mx-2"/>
    );
    if (i <= numOfSteps) {
      if (i === numOfSteps) {
        item = (
          <div key={i} className="bg-secondary h-[8px] w-full max-w-full mx-2 relative">
          </div>
        );
      } else {
        item = (
          <div key={i} className="bg-secondary h-[8px] w-full max-w-full mx-2"/>
        );
      }
    }
    steps.push(item);
  }
  return (
    <div className="flex bg-sky-200 py-4 px-2 "> {steps}</div>
  )
}
