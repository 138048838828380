import cvs_Health from "../../../assets/images/cvsHealth.png";
import cvslogored2 from "../../../assets/images/cvslogored2.png";
import tick from "../../../assets/images/tick.svg";
import logo from "../../../assets/images/journey_logo.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import "./intro.css";
import { trackEvents } from "../../../common/utils/mixpanel";
import { useEffect } from "react";
import Footer from "../../../common/footer";
import navlogo from "../../../assets/images/nicoretteupNew.png";
import useCheckMobileScreen from "../../../common/useCheckMobileScreen";
import { trackEventsPendo } from "../../../common/utils/pendo";

export default function Intro() {


  const navigate = useNavigate();

  useEffect(() => {
    trackEvents("on_landing_page");
    trackEventsPendo("on_landing_page")
  }, []);

  function handleGetStarted() {
    trackEvents("clicked_on_get_started");
    trackEventsPendo("clicked_on_get_started");
    navigate(ROUTES.GETSTARTED);
  }

  function handleLogin() {
    trackEvents("on_get_started_login_clicked");
    trackEventsPendo("on_get_started_login_clicked");
    navigate(ROUTES.LOGIN);
  }

  return (
    <>
      <div className="bg-primary-blue flex min-h-screen flex-col  justify-between ">
        {/* nav for desktop view */}
        {/* nav for mobile */}
        <div className="flex sm:flex md:hidden  bg-custom-blue text-white h-[70px] px-5 py-5 justify-around items-center">
          <img src={navlogo} alt="cvs logo" className="h-[22px]" />
        </div>

        {/* nav for Desktop */}
        <div className="hidden px-10 md:flex  md:items-center md:justify-between bg-custom-blue text-white h-[80px]">
          <img src={navlogo} alt="Logo" />
        </div>

        {/* bg-primary */}
        <div className="">
          {/* <Branding /> */}
          <div className="sm:block md:hidden">
            <div className="mt-5 flex items-center justify-center">
              <div className="flex justify-center">
                <img
                  src={logo}
                  alt="journey to freedom"
                  className="h-[100px] object-contain"
                />
              </div>
            </div>
          </div>

          <div className="sm:block md:hidden">
            <div className="mt-2 px-11 text-sm">
              <div className="grid grid-cols-1 gap-3">
                <div className="grid-content-intro short:py-2 flex items-center px-4 py-4">
                  <img src={tick} className="tick-icon" />
                  <div className="grid-content-text">
                    Track your journey with daily check-ins and milestones
                  </div>
                </div>
                <div className="grid-content-intro short:py-2 flex items-center px-4 py-4">
                  <img src={tick} className="tick-icon" />
                  <div className="grid-content-text">
                    Insights into money saved and health benefits
                  </div>
                </div>
                <div className="grid-content-intro short:py-2 flex items-center px-4 py-4">
                  <img src={tick} className="tick-icon" />
                  <div className="grid-content-text">
                    Support resources with trusted health partners
                  </div>
                </div>
                <div className="grid-content-intro short:py-2 flex items-center px-4 py-4">
                  <img src={tick} className="tick-icon" />
                  <div className="grid-content-text">
                    Learn more about products to help reduce cravings
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center px-11 py-[15px]">
              <button
                className=" subtitle-2 bg-secondary w-[340px] rounded-full px-4 py-2 text-white "
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>

            <div className="flex flex-col gap-3">
              <p className="custom-text text-center text-sm text-slate-700 xl:text-start">
                Already a user?
                <span
                  className="custom-text text-secondary"
                  onClick={handleLogin}
                >
                  {" "}
                  &nbsp; Login
                </span>
              </p>
            </div>
          </div>

          {/* DESKTOP */}
          <div className="hidden flex-col items-center justify-start md:flex">
            <div>
              <img
                src={logo}
                alt="journey to freedom"
                className="h-[180px] object-contain"
              />
            </div>
            <div className="flex px-7 ">
              <p
                className="text-custom-dark-blue  custom-h1 text-center"
                style={{ fontSize: "54px", fontWeight: 500, lineHeight: "1" }}
              >
                {" "}
                There’s an easier way to quit smoking
              </p>
            </div>

            {/* <div className="px-10 mt-2"> */}
            <div className="mt-5 grid grid-cols-2 gap-6">
              <div className="grid-content-intro-desktop flex ">
                <img src={tick} className="tick-icon-desktop" />
                <div className="grid-content-text">
                  Track your journey with daily check-ins and milestones
                </div>
              </div>
              <div className="grid-content-intro-desktop flex">
                <img src={tick} className="tick-icon-desktop" />
                <div className="grid-content-text">
                  Insights into money saved and health benefits
                </div>
              </div>
              <div className="grid-content-intro-desktop flex">
                <img src={tick} className="tick-icon-desktop" />
                <div className="grid-content-text">
                  Support resources with trusted health partners
                </div>
              </div>
              <div className="grid-content-intro-desktop flex">
                <img src={tick} className="tick-icon-desktop" />
                <div className="grid-content-text">
                  Learn more about products to help reduce cravings
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="flex px-11 pt-10">
              <button
                className="text-[16px] bg-secondary short:px-4 h-[60px] w-[450px] rounded-full px-11  text-white"
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>

            <div className="flex flex-col gap-3 pt-5">
              <p className="custom-text text-center text-lg text-slate-700 xl:text-start">
                Already a user?
                <span className=" text-secondary " onClick={handleLogin}>
                  {" "}
                  &nbsp; Login
                </span>
              </p>
            </div>
          </div>
          <div
            className="flex justify-center px-5 py-2"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <img
              src={cvslogored2}
              alt="icon"
              className="h-[100px] object-contain"
            />
          </div>
        </div>
        {/* <div className="bg-custom-dark-blue fixed bottom-0 w-full text-center text-white flex flex-col justify-center p-2 py-5 md:py-10">
          <p className="my-[5px] px-[10px] text-center md:text-[18px] text-[10px]  text-[#999]">
            Behavioral support program increases chances of success. Use as
            directed. {!isMobile && <br></br>}The content of this website is intended for US audiences
            only.©2023 Haleon group of companies or its licensor. All rights
            reserved.
          </p>
        </div> */}
        {/* <Footer showLinks={false} showBehaviouralText={true} /> */}
        <div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
          <Footer showLinks={false} showBehaviouralText={true} />
        </div>
      </div>
    </>
  );
}