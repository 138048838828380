import Branding from "../../../common/header/Branding";
import footer from "../../../assets/images/journey_logo.png";
import shild from '../../../assets/images/shield.png'
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import {useEffect} from "react";
import {getAppConfig} from "../../../api/onboarding";
import { trackEvents } from "../../../common/utils/mixpanel";
import { trackEventsPendo } from "../../../common/utils/pendo";

export default function InfoDisclaimer() {
  const navigate = useNavigate()

  useEffect(() => {
    trackEvents('on_disclaimer_info')
    trackEventsPendo('on_disclaimer_info')
    getAppConfig()
  }, [])

  return (
      <>
        <div className="h-full flex flex-col overscroll-auto md:border-2">
          <div className="basis-3/4 bg-primary">
            <Branding/>
            <div className="w-72 h-60 bg-[#163554] mx-auto my-2 flex flex-col justify-center rounded-2xl ">
              <div className="flex justify-center">
                <img src={shild} alt="shield icon" className="block w-14"/>
              </div>
              <div className="flex justify-center p-2 ">
                <h1 className="custom-h2 text-center text-white">Why should I share my personal information?</h1>
              </div>

            </div>
            <div className="flex justify-center px-7">
              <p className="text-center subtitle text-[#F5F5F5]">
                It helps us <span className="text-secondary">personalize your experience!</span> The information you
                share
                allows us to tailor the journey specifically to you.
              </p>
            </div>
            <div className="flex justify-center py-5 mt-2">
              <button className=" subtitle-2 bg-secondary text-white py-2 px-6 short:px-4 rounded-full w-[340px] "
                      onClick={() => navigate(ROUTES.QUESTIONCARD)}>
                I Understand
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center basis-1/5 p-5">
            <div>
              <div className="flex justify-center">
                <div className="h-[66px] w-[187px]">
                  <img src={footer} alt="journey to freedom" className="h-[120px] object-contain"/>
                </div>
              </div>
              <div className="flex justify-center">
                <p className="text-[14px] text-[Helvetica-Neue]">
                  Supporting your journey from start to success
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
