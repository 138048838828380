// import cvs_logo from '../../assets/icons/branding_details.svg'
import cvs_logo from '../../assets/images/nicoretteLogo.png'

export default function Branding() {
  return (
     <header className="flex justify-center w-full bg-transparent px-5 pt-8 short:pt-[20px]">
        <img src={cvs_logo} alt="cvs logo" className='w-21 md:w-[84px]' />
     </header>
  )
}
