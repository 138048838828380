import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import Branding from "../../../common/header/Branding";
import { useEffect } from "react";
import { getAppConfig } from "../../../api/onboarding";
import { CustomButton } from "../../../common/CustomButton";
import { trackEvents } from "../../../common/utils/mixpanel";
import Copyright from "../../../common/footer/copyright";
import navlogo from "../../../assets/images/nicoretteupNew.png";
import Footer from "../../../common/footer";
import logo from "../../../assets/images/journey_logo.png";
import useCheckMobileScreen from "../../../common/useCheckMobileScreen";
import { trackEventsPendo } from "../../../common/utils/pendo";

export default function GreetUser() {
  const isMobile = useCheckMobileScreen();
  const name = localStorage.getItem("userName");
  const navigate = useNavigate();

  useEffect(() => {
    trackEvents("on_disclaimer_info");
    trackEventsPendo("on_disclaimer_info");
    getAppConfig();
  }, []);

  function handleGetStarted() {
    trackEvents("Clicked_On_Understand");
    trackEventsPendo("Clicked_On_Understand");
    navigate(ROUTES.QUESTIONCARD);
  }

  return (
    <>
      <div className="bg-primary-blue flex min-h-screen flex-col  justify-between ">
        {/* Logo Section */}
        {/* <header className="xl:flex">
          <Branding />
          <div className="hidden xl:block xl:px-36 xl:py-20">
            <img
              src={footer}
              alt="journey to freedom"
              className="h-[100px] w-[106px] object-contain"
            />
          </div>
        </header> */}

        {/* nav for mobile */}
        <div className="bg-custom-blue flex h-[70px]  items-center justify-around px-5 text-white sm:flex md:hidden">
          <img src={navlogo} alt="cvs logo" className="h-[22px]" />
        </div>

        {/* nav for Desktop */}
        <div className="bg-custom-blue hidden h-[80px]  px-10 text-white md:flex md:items-center md:justify-between">
          <img src={navlogo} alt="Logo" />
        </div>

        <section className="flex flex-col gap-2  xl:mt-0 ">
          {/* <div className="flex justify-center px-24 py-12 md:px-0 md:py-0 xl:mb-10 xl:justify-center">
            <div className="short:w-12 w-16"></div>
          </div> */}
          <div className="px-10 flex flex-col items-center justify-start">
            <div>
              <img
                src={logo}
                alt="journey to freedom"
                className="h-[130px] md:h-[180px] object-contain"
              />
            </div>
            <div className="short:py-1 m-auto px-7 md:mt-10 py-10 ">
            <p className="text-custom-dark-blue custom-h1 text-center md:text-[54px] ">
              Hi {name === null || undefined ? "Guest" : name}!
              <br className="xl:hidden" />
              Your Journey is <span className="text-secondary">Unique</span>
            </p>
          </div>
          </div>
          

          {/* <div className="flex w-full flex-col items-center justify-center px-8 md:mt-12">
            <p className="md:text-[18px] ">
              Tell us a little about yourself so we can connect with you on your
              personal journey.
            </p>
            <p className="md:text-[18px] ">
              It helps us personalize your experience! The information you share
              allows us to tailor the journey specifically to you.
            </p>
          </div> */}

          <div className="flex w-full items-center justify-center md:mt-12">
            <p className="custom-p-greet px-8">
              Tell us a little about yourself so we can connect with you on your
              personal journey.
            </p>
          </div>
          <div className="flex w-full items-center justify-center px-8 md:mb-10 ">
            <p className="">
              It helps us{" "}
              <span className="text-secondary">
                personalize your experience!
              </span>{" "}
              The information you share allows us to tailor the journey
              specifically to you.
            </p>
          </div>

          {
  isMobile ?
  <div className="mt-10 sm:py-5 ">
  <CustomButton
   centeralign={true}
    label={"I Understand"}
    buttonStyleClass="btn-primary text-sm  "
    handleClick={handleGetStarted}
  />
</div>:
 <div className="mt-10 sm:py-5">
 <CustomButton
  centeralign={true}
   label={"I Understand"}
   buttonStyleClass="btn-primary-desktop text-lg h-[60px] w-[450px]"
   handleClick={handleGetStarted}
 />
</div>
}
         

          {/* <div className="flex hidden justify-center md:mt-8">
            <p className="text-[12px] text-[Helvetica-Neue]">
              {" "}
              <span className="text-[#BFBFBF]">
                By clicking ‘I Understand’ you agree to our{" "}
              </span>{" "}
              <span
                className="font-medium text-[#FFFFFF] no-underline"
                onClick={() => {
                  navigate("/privacyPolicy/greet");
                }}
              >
                Privacy Policy
              </span>
            </p>
          </div> */}
        </section>

        {/* Footer */}
        {/* <footer className="short:p-4 mt-10 flex items-center justify-center p-7 xl:hidden xl:px-36">
          <div>
            <div className="flex justify-center">
              <img
                src={footer}
                alt="journey to freedom"
                className="h-[120px] object-contain"
              />
            </div>
          </div>
        </footer>
        <Copyright /> */}

<div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
          <Footer showLinks={false} showBehaviouralText={false} />
        </div>
      </div>
    </>
  );
}