import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import stat from '../../assets/images/stat.png'
import { incrementCurrentQuestion } from '../../slices/stepSlice'
import { ROUTES } from '../../routes'
import Branding from '../../common/header/Branding'
import { CustomButton } from "../../common/CustomButton"
import { store } from "../../store"
import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { trackEvents } from '../../common/utils/mixpanel'
import { trackEventsPendo } from '../../common/utils/pendo'

export default function Stats() {
    let naviagate = useNavigate()
    const dispatch = useDispatch()
    let getUserId = store.getState().steps.userProfileData.id

    function handleClick() {
        if (getUserId) {
            trackEvents('on_did_you_know_action')
            trackEventsPendo('on_did_you_know_action')
            dispatch(incrementCurrentQuestion())
            naviagate(ROUTES.QUESTIONCARD)
        } else {
            naviagate(ROUTES.SIGNUP)
        }
    }

    useEffect(()=>{
       trackEvents('on_did_you_know')
       trackEventsPendo('on_did_you_know')
    },[])

    return (
        <div className='flex flex-col bg-primary h-full'>
            <div>
                <Branding />
            </div>
            <div className='grow flex flex-col gap-3 justify-evenly items-center px-5'>
                <div>
                    <h1 className='custom-h1 text-white'>Did you know?</h1>
                </div>
                <div>
                    <p className='custom-subtitle text-white text-center'>Combining additional behavioral support tools
                        with Nicotine Replacement Therapy (NRT), like Nicorette, can help increase the chances of quit
                        success by:</p>
                </div>
                <div className='w-40 short:w-36'>
                    <img src={stat} alt="stat" />
                </div>
            </div>
            <div className='bg-white basis-1/5  flex flex-col justify-center items-center gap-2 px-6'>
                <div>
                    <p className='text-[12px] text-[#8C8C8C]'>Source: Stead LF and Lancestor T (2012) combined
                        pharmacotherapy and behavioral interventions for smoking cessation (Review). Cochrane database
                        of Systemic Reviews, Issue 10</p>
                </div>
                <div>
                    <CustomButton
                        label={'Continue'}
                        buttonStyleClass='subtitle-2 bg-secondary text-white py-2 px-6 short:px-4 short:py-1 rounded-full w-[340px] short:w-80 '
                        handleClick={handleClick} />
                </div>
            </div>
        </div>
    )
}
