import footer from "../../../assets/images/journey_logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import Branding from "../../../common/header/Branding";
import { userNameRegex, userEmailIdRegex } from "../../../common/utils/Constants";
import { CustomButton } from "../../../common/CustomButton";
import { InputField } from "../../../common/InputField";
import './notifications.css'
import { useSelector } from 'react-redux';
import { trackEvents } from "../../../common/utils/mixpanel";
import { trackEventsPendo } from "../../../common/utils/pendo";
import useCheckMobileScreen from "../../../common/useCheckMobileScreen";

export default function OptIn() {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { isLoggedIn } = useSelector(
    (state: any) => state.steps
  );
  useEffect(() => {
    trackEvents('on_get_started')
    trackEventsPendo('on_get_started')
  }, [])

  useEffect(()=>{
    if(!isMobile){ 
      navigate(ROUTES.DESKTOP_LANDING);
    }
  },[isMobile])
  function handleChange(e: any) {

    const result: boolean = userNameRegex.test(e.target.value);
    if (e.target.value === "") {
      setName("");
      setError("");
    } else if (result) {
      setName(e.target.value);
      setError("");
    }
  }

  function handleEmailChange(e: any) {
    setEmail(e.target.value);
  }

  function getOtp() {
    // localStorage.setItem("userName", name);
    // localStorage.setItem("userEmail", email);
    // navigate(ROUTES.VERIFICATION);
    navigate(ROUTES.SMS_TIME)
  }
  return (
    <div className="h-full bg-sky-200 overflow-scroll flex flex-col justify-between">
      <div className="basis-4/5 flex flex-col md:border-2">
        <Branding />
        <div className="flex justify-center px-6 py-7 ">
          <h2 className="custom-h2 text-slate-700">Receive health notifications?</h2>
        </div>
        <div className="flex justify-center px-10">
          <p className="text-slate-700 custom-text text-left">Users who receive notifications from us boost their chances of success!</p>
        </div>

        <div className="flex justify-center px-5 py-5 mt-5">
          <CustomButton
            label={'Receive Notifications'}
            buttonContainerClass="flex justify-center px-3 short:px-2"
            buttonStyleClass="subtitle-2 bg-secondary text-white py-3 px-6 short:px-4 rounded-full w-[340px]"
            handleClick={getOtp}
          />
        </div>
        <div className="flex justify-center">
          <CustomButton
            centeralign={true}
            label={'Maybe Later'}
            buttonStyleClass="text-secondary custom-text"
            handleClick={() => {
              trackEvents('get_started_skipped')
              trackEventsPendo('get_started_skipped')
              localStorage.removeItem("userName");
              if (!isLoggedIn) {
                navigate(ROUTES.GREET)
              } else {
                navigate(ROUTES.DASHBOARD)
              }
            }}
          />
        </div>


      </div >
      <div className="flex justify-center items-center  p-7 short:p-4">
        <div>
          <div className="flex justify-center otpin-footer">
            <img src={footer} alt="journey to freedom" className="h-[120px] object-contain"/>
          </div>
        </div>
      </div>
    </div>
  );
}