import journyInfo from "../../assets/images/journeyGuide.png";

export const JourneyInfo = () => {
  return (
    <>
      <h1 className="subtitle-medium text-center pt-10 pb-2">Journey</h1>
      <p className="text-center text-[#595959] text-base font-normal font-[Helvetica-Neue]">Welcome to your Journey of smoke free life</p>
      {/* <img src={journyInfo} alt="journey map picture" className="pt-12 m-auto" /> */}
    </>
  );
}

export const HowItWorks = () => {
  return (
    <>
      <h1 className="subtitle-medium text-center pt-5 pb-2">How it works?</h1>
        <div className="mt- px-7 flex flex-col gap-7 short:gap-5">
            <div>
            <h3>✅ Log-in daily to add progress</h3>
            </div>
            <div>
            <h3>📊 Track your journey and milestones</h3>
            </div>
            <div>
            <h3>💊 Use resources and tools to help improve quit success</h3>
            </div>
        </div>
          
          
          
    </>
  );
}
