import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import DashboardHeader from "./dashboard/DashboardHeader";
import {ROUTES} from "../routes";

const TermsAndConditions=()=>{
    const navigate = useNavigate();
    const { fromPath } = useParams()

    const handleBackClick = () => {
        switch (fromPath) {
            case 'Settings':
                navigate(ROUTES.SETTING)
                break;
            default:
                navigate(ROUTES.DASHBOARD)
        }
    }

    return <div className="basis-full">
        <DashboardHeader back={true} handleBack={()=>{handleBackClick()}}/>
        <div className="px-[24px] py-[36px] bg-white">
            <p className="custom-h2 py-[10px] text-[#002744]">Terms and Conditions</p>
            <p className="text-[#595959] custom-bodyText">Lorem ipsum dolor sit amet consectetur. Blandit dui eget molestie in enim quis id vitae porta. At vehicula purus tellus feugiat cras et purus id. Faucibus amet ac ac vulputate. Velit a massa pellentesque sodales sapien parturient.</p>
            <br/>
            <p className="text-[#595959] custom-bodyText">Magna scelerisque libero arcu et orci risus ligula venenatis. Scelerisque mattis ut a pellentesque molestie.</p>
            <p className="text-[#595959] custom-bodyText">
                <ul style={{listStyle: 'inside' }}>
                    <li>Turpis euismod eget dapibus consectetur ullamcorper.</li>
                    <li>Amet fames a leo risus et ultricies ultrices lacus viverra.</li>
                    <li>Consequat mattis ac scelerisque leo. Pretium nulla in in ornare ornare in nunc.</li>
                    <li>Orci massa mattis vitae nunc. Vulputate in ultrices nunc quis sit est erat.</li>
                    <li>Imperdiet accumsan tincidunt elit est sed quisque quis dui egestas.</li>
            </ul></p>
            <br/>
            <p className="text-[#595959] custom-bodyText">Posuere gravida mollis donec ultricies fermentum congue mi sapien. Leo viverra at iaculis sit consequat pulvinar et tellus elit. Sed elit eget risus tellus aliquet in. Sem quam lacus convallis lobortis ipsum id nulla. Eu lacus dictumst vestibulum orci iaculis arcu. Adipiscing sapien nisi nulla mauris auctor tortor id. At hendrerit vitae massa nunc nulla cursus ullamcorper. Ac nibh nunc et eu risus lorem sodales. Quam risus elementum cras justo urna.</p>
        </div>
    </div>
}
export default TermsAndConditions;