// export const BASEURL = "http://localhost:3000/api/";
export const BASEURL = process.env.REACT_APP_API_BASE_URL;
export const getAppConfigUrl = "allDetails";
export const GETGOALIMAGE = "getGoalImg";
export const getProductsUrl = "filterProduct";
export const loginUrl = "auth/login";
export const signupUrl = "auth/signup";
export const addUserFriendUrl = "userFriend";
export const removeUserFriendUrl = "deleteUserFriend";
export const sendEmailToUserFriendUrl = "email";
export const ShareProgress = "email/shareBadge";
export const setUserBadgeUrl = "AddFixedBadge";
export const setForgotPassMailUrl = "email/foregetPassmail";
export const resetPasswordUrl = "auth/editPassword";
export const userDetailsUrl = "auth/userAllDetail";
export const getArticleOptions = "article/options";
export const getArticles = "articles";
export const getRatingDetailsUrl = "feelingImages";
export const setRatingUrl = "addFeelingImage";
export const editMotivationUser = "editUser";
export const getSmsOtp = "sms/sendotp";
export const verifyOtp = "sms/notification/store/data";
export const unsubscribe = "sms/notification/unsubscribe";
export const getJourneyDetails = BASEURL + "dashboard";
export const updateMyPreferences = BASEURL + "editUser";
export const userStatDetails = "dashboard/userStats";
export const getProspectUrl = "getStarted";
