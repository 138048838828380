import React, { useEffect, useState } from "react";
import DashboardHeader from "../dashboard/DashboardHeader";
import { InputField } from "../../common/InputField";
import { CustomButton } from "../../common/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../routes";
import { resetPassword } from "../../api/onboarding";
import { Dialog } from "@headlessui/react";
import * as momentTimezone from "moment-timezone";
import moment from "moment";
import footer from "../../assets/images/journey_logo.png";

const ResetPassword = () => {
    const navigate = useNavigate()
    const { userId, date, time } = useParams()

    const [error, setError] = useState('')
    const [newPassError, setNewPassError] = useState('')
    const [confirmPassError, setConfirmPassError] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLinkExpired, setIsLinkExpired] = useState(false)

    useEffect(() => {
        if (date && time) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            console.log(timezone)
            const currentDate = momentTimezone.tz(new Date(), timezone).format('YYYY-MM-DD')
            const timeAfterLink = momentTimezone.tz(moment(`${time}`, 'HH:mm:ss').add(15, 'minutes'), timezone).format('HH:mm:ss')
            setIsLinkExpired(currentDate === date && momentTimezone.tz(new Date(), timezone).format('HH:mm:ss') > timeAfterLink)
        }
    }, [])

    const handlePasswordChange = (e: any) => {
        let value = e.target.value.trim()
        setNewPassError('')
        setError('')
        setNewPassword(value)
    }
    const handleConfirmPasswordChange = (e: any) => {
        let value = e.target.value.trim()
        setConfirmPassError('')
        setError('')
        setConfirmPassword(value)
    }
    const handleSaveClick = async () => {
        if (newPassword.length < 8 || confirmPassword.length < 8) {
            setError('')
            newPassword.length < 8 && setNewPassError('Password must contain 8 characters')
            confirmPassword.length < 8 && setConfirmPassError('Password must contain 8 characters')
        } else {
            if (newPassword === confirmPassword) {
                try {
                    const payload = {
                        userId: userId,
                        password: confirmPassword
                    }
                    const result: any = await resetPassword(payload)
                    if (result.status === 200) {
                        navigate(ROUTES.LOGIN)
                    }
                } catch (e) {
                    setError('Something went wrong.')
                }
            } else {
                setError('Password and confirm password does not match')
            }
        }
    }

    return <div className="flex flex-col bg-sky-200 h-full relative">
        {isLinkExpired && <Dialog
            open={isLinkExpired}
            onClose={() => { }}
            className="absolute top-0 z-50 w-full md:w-96 md:mx-[40%]"
        >
            <div
                className="fixed w-full md:w-96 h-full md:mt-8 bg-black/30 "
                aria-hidden="true"
            >
                <Dialog.Panel className="relative">
                    <div className="h-[400px] bg-[#F5F5F5] flex flex-col justify-center text-center border rounded-lg shadow-md mx-5 my-[50%]">
                        <i className="fa-solid fa-triangle-exclamation text-[50px]" />
                        <p className="custom-h3 px-[30px] py-[10px]">
                            Your password reset link expired.
                        </p>
                        <p className="px-[30px] py-[20px]">
                            For security reasons, password reset link expire after 15 minutes. If you still need to reset your password, you can request a new reset email.
                        </p>
                        <CustomButton label={"Back To Password Reset"} handleClick={() => { navigate(ROUTES.FORGOTPASSWORD) }} />
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>}
        <DashboardHeader menu={false} title="Reset Password" cart={false} back={false} />
        <div className="bg-sky-200 h-full overflow-auto py-[50px]">
            <div className="flex flex-col justify-center items-center">
                <InputField
                    type={'password'}
                    value={newPassword}
                    placeholderText={'Enter New Password'}
                    inputStyleClass="px-[32px] py-[15px] w-[340px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                    handleChange={handlePasswordChange} />
                {newPassError && <div className="flex justify-center">
                    <h5 className="text-secondary custom-text">{newPassError}</h5></div>}
                <InputField
                    type={'password'}
                    value={confirmPassword}
                    placeholderText={'Confirm New Password*'}
                    inputStyleClass="mt-2 px-[32px] py-[15px] w-[340px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                    handleChange={handleConfirmPasswordChange} />
                {confirmPassError && <div className="flex justify-center">
                    <h5 className="text-secondary custom-text">{confirmPassError}</h5></div>}
            </div>
            {error && <div className="flex justify-center">
                <h5 className="text-secondary custom-text">{error}</h5></div>}
            <div className="py-[30px]">
                <CustomButton label={'Save'} buttonStyleClass={"disabled:bg-[#E4E4E4] disabled:text-[#434343] subtitle-2 bg-secondary text-white py-[10px] px-[24px] rounded-full w-full"} handleClick={() => handleSaveClick()} />
                <CustomButton label={'Cancel'} buttonStyleClass={"text-secondary custom-text"} handleClick={() => { navigate(ROUTES.LOGIN) }} />
            </div>

            <div className="flex justify-center">
                <div className="">
                    <img src={footer} alt="journey to freedom" className="h-[120px] object-contain"/>
                </div>
            </div>
        </div>
    </div>
}
export default ResetPassword