import support from "../../assets/images/support.png";

export const SupportInfo = () => {
  return (
    <>
      <h1 className="subtitle-medium text-center pt-10 pb-2">Resources</h1>
      <p className="text-center text-[#595959] text-base font-normal font-[Helvetica-Neue]">Find Support and Articles to help you Quit.</p>
      <img src={support} alt="journey map picture" className="pt-[5px] m-auto" />
      <h1 className="subtitle-medium text-center pt-10 pb-2">How it works?</h1>
      <div className="mt-4 px-7 flex flex-col ">
        <div>
          <h3>📚 Find support & Articles</h3>
          <p className="subtitle-modal text-[#595959]">Through Smoking Cessation Programs like Minute clinic, Aetna, etc and access to useful Articles.</p>
        </div>
      </div>
    </>
  );
}

export const HowItWorks = () => {
  return (

    <>
      
    </>
  );
}
