import footer from "../../../assets/images/journey_logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import Branding from "../../../common/header/Branding";
import { userNameRegex, userEmailIdRegex } from "../../../common/utils/Constants";
import { CustomButton } from "../../../common/CustomButton";
import { InputField } from "../../../common/InputField";
import './notifications.css'
import { getOtpForSmsNotifications } from '../../../api/smsnotifications'
import { useSelector } from "react-redux";
import { trackEvents } from "../../../common/utils/mixpanel";
import { trackEventsPendo } from "../../../common/utils/pendo";
export default function Verification() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [error, setError] = useState("");
  let userProfileData = useSelector(
    (state: any) => state.steps.userProfileData
  );
  useEffect(() => {
    trackEvents('on_get_started')
    trackEventsPendo('on_get_started')
  }, [])

  function handleChange(e: any) {

    const result: boolean = userNameRegex.test(e.target.value);
    if (e.target.value === "") {
      setName("");
      setError("");
    } else if (result) {
      setName(e.target.value);
      setError("");
    }
  }

  function handlePhoneChange(e: any) {
    setPhone(e.target.value);
  }

  function getOtp() {
    localStorage.setItem("phoneno", phone)
    if (phone.length > 0) {
      getOtpForSmsNotifications({ email: userProfileData?.email, toNumber: phone });
      navigate(ROUTES.GETOTP);
    } else {
      setPhoneError(true)
    }
  }
  return (
    <>
      <div className="basis-4/5 flex flex-col md:border-2 bg-sky-200 h-full">
        <Branding />
        <div className="flex justify-center px-6 py-7 ">
          <h2 className="custom-h2 text-slate-700">Enter your Phone Number</h2>
        </div>
        <div className="flex justify-center px-10">
          <p className="text-slate-700 custom-text text-left">We will send a One Time Password on this mobile number</p>
        </div>

        <div className="flex flex-col justify-center items-center mt-3">
          <InputField
            type={'text'}
            placeholderText={'10 Digit Phone Number'}
            handleChange={handlePhoneChange}
            className="px-4"
            inputContainerClass={'flex justify-left max-w-[345px] w-full short:w-4/5 m-auto'}
          />
          {phoneError && <p className="text-[12px] text-red-700">Please enter Phone number.</p>}
        </div>

        <div className="flex justify-center px-5 py-5 mt-5">
          <CustomButton
            label={'Get OTP'}
            buttonContainerClass="flex justify-center px-3 short:px-2"
            buttonStyleClass="subtitle-2 bg-secondary text-white py-3 px-6 short:px-4 rounded-full w-[340px]"
            handleClick={getOtp}
          />
        </div>
        <div className="flex justify-center px-5 py-1">
          <CustomButton
            label={'Back'}
            buttonContainerClass="flex justify-center px-3 short:px-2"
            buttonStyleClass="subtitle-2 bg-white text-secondary py-3 px-6 short:px-4 rounded-full w-[340px]"
            handleClick={() => navigate(ROUTES.SETTING)}
          />
        </div>
        <div>
          <div className="flex justify-center otpin-footer">
            <img src={footer} alt="journey to freedom" className="h-[120px] object-contain"/>
          </div>
        </div>
      </div >
      {/* <div className="flex justify-center items-center basis-1/5 p-7 short:p-4 bg-sky-200">
        
      </div> */}
    </>
  );
}