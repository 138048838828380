export const ROUTES ={
    ROOT:"/",
    INTRO:"/intro",
    GETSTARTED:"/get-started",
    GREET:'/greet',
    INFODISCLAIMER:'/info-disclaimer',
    QUESTIONCARD:'/question-card',
    STATS:'/stats',
    SIGNUP:'/sign-up',
    PRODUCTDETAILS:'/product-details',
    DASHBOARD:'/dashboard',
    EARNBADGE:'/earn-badge',
    SUPPORT:'/support',
    ACHIEVEMENTS:'/achievements',
    LOGIN:'/login',
    FORGOTPASSWORD:'/forgotPassword',
    RESETPASSWORD:'/resetpwd/:userId/:date/:time',
    TERMSANDCONDITIONS:'/termsAndCondition/:fromPath',
    PRIVACYPOLICY:'/privacyPolicy/:fromPath',
    QUICKACCESSTUTORIAL:'/quickAccessTutorial',
    ARTICLEDETAILS:'/article-details',
    MYMOTIVATIONS:'/myMotivations',
    HOME:'/myAccount/home',
    PRODUCTS:'/myAccount/product',
    ABOUTME:'/myAccount/aboutMe',
    MYCONTACTS: '/myAccount/myContacts',
    SETTING:'/myAccount/Settings',
    FAQ:'/myAccount/FAQ',
    CHANGEPASSWORD:'/myAccount/changePassword',
    MYPREFERENCES:'/myAccount/preferences/:fromPath',
    NOTFOUND:'*',
    JOURNEY:"/journey",
    OPTIN:"/notifications/optin",
    GETOTP:"/notifications/getotp",
    OTPSUCCESS:"/notifications/otpsuccess",
    VERIFICATION:"/notifications/verification",
    SMS_TIME:'/notifications/sms_time',
    DESKTOP_LANDING:'/desktop_landing'
}