import footer from "../../../assets/images/journey_logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import Branding from "../../../common/header/Branding";
import { userNameRegex, userEmailIdRegex } from "../../../common/utils/Constants";
import { CustomButton } from "../../../common/CustomButton";
import { InputField } from "../../../common/InputField";
import './notifications.css'
import { verifyNotificationsOtp } from '../../../api/smsnotifications'
import { Dialog } from "@headlessui/react";
import _ from 'lodash';
import { getOtpForSmsNotifications } from '../../../api/smsnotifications'
import { useSelector } from "react-redux";
import { trackEvents } from "../../../common/utils/mixpanel";
import { trackEventsPendo } from "../../../common/utils/pendo";

export default function GetOtp() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const phone = localStorage.getItem('phoneno')
  const [showOtpFailed, setShowOtpFailed] = useState(false);
  let userProfileData = useSelector(
    (state: any) => state.steps.userProfileData
  );
  const state = localStorage.getItem("persist:root")

  useEffect(() => {
    trackEvents('on_get_started')
    trackEventsPendo('on_get_started')
  }, [])

  function handleOtpChange(e: any) {
    setOtp(e.target.value);
  }

  async function otpSuccess() {
    // call notification/store/data api
    console.log(state);

    if (otp.length == 6) {
      let email = userProfileData?.email
      let verifyOtpResponse = await verifyNotificationsOtp({ email: email, phoneno: phone, otp: otp });
      console.log("********************", verifyOtpResponse);

      let resMsg = _.get(verifyOtpResponse, 'data.response');
      // if (resMsg == 'success') {
      if (resMsg != 'success') {
        setShowOtpFailed(true);
        return;
      }
      navigate(ROUTES.OTPSUCCESS);
    } else {
      setError(true)
    }
  }
  return (
    <>
      <div className="basis-full h-full flex flex-col md:border-2 bg-sky-200">
        <Branding />
        {showOtpFailed && <Dialog open={showOtpFailed}
          onClose={() => { }}
          className="absolute top-0 z-50 w-full md:w-96 md:mx-[40%]">
          <div
            className="fixed w-full md:w-96  h-full md:h-[80vh] md:mt-8 bg-black/30 "
            aria-hidden="true"
          >
            <Dialog.Panel className="relative">
              <div className="h-72 bg-[#F5F5F5] short:h-60 border rounded-lg shadow-md mx-5 my-[50%]">
                <div className="px-[30px] pt-[30px] flex flex-col justify-center items-center text-center">
                  <i className="fa-solid fa-triangle-exclamation text-[50px]" />
                  <h3 className="pt-[30px]">Something went wrong, try again!</h3>
                </div>
                <CustomButton label={'Ok'}
                  buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border w-40 border-secondary rounded-full"
                  handleClick={() => {
                    setShowOtpFailed(false)
                    navigate(ROUTES.GETOTP)
                  }} />
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
        }
        <div className="px-6 py-7 ">
          <h2 className="custom-h2 text-slate-700 text-left">OTP Verification </h2>
          <p className="text-[12px] text-[Helvetica-Neue] mt-3"> <span className="text-slate-700">Enter OTP sent to </span> <span className="text-slate-700 " style={{ textDecoration: 'underline', fontWeight: "500" }}>{phone}</span></p>
        </div>

        <div className="flex flex-col mt-3 px-6" style={{ alignItems: "left" }}>
          <InputField
            type={'text'}
            placeholderText={'Enter OTP'}
            handleChange={handleOtpChange}
            className="px-4"
            inputContainerClass={"flex justify-left max-w-[345px] w-full m-auto"}
          />
          {error && <p className="text-[12px] text-red-700">Please enter correct otp.</p>}

          <div className="flex justify-left">
            <CustomButton
              label={'Resend Otp'}
              buttonStyleClass="text-secondary custom-text px-5"
              handleClick={() => {
                getOtpForSmsNotifications({ email: userProfileData?.email, toNumber: phone });
              }}
              buttonContainerClass={"flex justify-left max-w-[345px] w-full m-auto"}
            />
          </div>
        </div>

        <div className="flex justify-center px-5 py-5 mt-5 short:px-2" style={{ alignItems: "left" }}>
          <CustomButton
            label={'Verify'}
            buttonContainerClass="flex justify-center px-3 short:px-2"
            buttonStyleClass="subtitle-2 bg-secondary text-white py-3 px-6 short:px-4 rounded-full w-[340px]"
            handleClick={otpSuccess}
          />
        </div>

        <div className="flex justify-center px-5 py-1">
          <CustomButton
            label={'Back'}
            buttonContainerClass="flex justify-center px-3 short:px-2"
            buttonStyleClass="subtitle-2 bg-white text-secondary py-3 px-6 short:px-4 rounded-full w-[340px]"
            handleClick={() => navigate(ROUTES.VERIFICATION)}
          />
        </div>

        <div className="flex justify-center otpin-footer">
          <img src={footer} alt="journey to freedom" className="h-[120px] object-contain" />
        </div>
      </div >
    </>
  );
}