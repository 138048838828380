import back from "../../../../assets/icons/backArrow.svg";
// import cvs_logo from "../../../../assets/icons/branding_details.svg";
import cvs_logo from '../../../../assets/images/nicoretteLogo.png'
import { useDispatch, useSelector } from "react-redux";
import { decrement, decrementCurrentQuestion, gotoQuestion } from "../../../../slices/stepSlice";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../../../../routes";
import Cart from "../../../../common/cart/Cart";
import Progress from "../../Progress";

export default function QuestionCardHeader() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  

  const {currentQuestion,questionnaire, userChoice} = useSelector(
    (state: any) => state.steps
  );
  console.log("currentQuestion", currentQuestion);

  const type = Object.keys(questionnaire)[currentQuestion]

  function handleBack(){
    if(currentQuestion === 0){
        navigate(ROUTES.GETSTARTED)
        localStorage.clear()
        return
    }
    if(currentQuestion === 10 && userChoice.maintenance.selection.includes("low")){
      dispatch(decrement())
       dispatch(gotoQuestion(6))
       return
    }
    if(currentQuestion === 3 || currentQuestion === 5 ||currentQuestion === 6 || currentQuestion === 10 || currentQuestion === 11 ){
      dispatch(decrement())
      dispatch(decrementCurrentQuestion())
      return
     }
    if(currentQuestion === 12){
      navigate(ROUTES.QUESTIONCARD)
      dispatch(decrementCurrentQuestion())
      return
    }
     dispatch(decrementCurrentQuestion())
  }

  function handleLogoClick(){
    navigate(ROUTES.GETSTARTED)
  }
  return (
    // <div className="fixed w-full">
    <div>
      <div className="flex flex-col justify-evenly h-[136px] short:h-[130px] bg-sky-200">
        <div className="flex justify-center md:justify-start px-14">
          <img src={cvs_logo} alt="cvs logo" className="block" onClick={handleLogoClick} />
        </div>
        <div className="flex justify-between px-6 md:px-14">
          <div className="basis-[10%]">
            <button onClick={handleBack} className="flex gap-3">
              <img src={back} alt="back"/>
              <span className="hidden md:block font-[Helvetica-Neue] text-base font-medium text-slate-700">Back</span>
            </button>
          </div>
          <div className="basis-3/4">
            <h2 className="text-slate-700 custom-h2 text-center">{questionnaire[type]?.Title}</h2>
          </div>
          <div className="basis-[10%]">
            {
              (currentQuestion === 9 || currentQuestion === 10) && (
                <Cart/>
              )
            }          
          </div>
        </div>
      </div>
      <Progress/>
    </div>
  );
}
