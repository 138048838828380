import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvents } from "../../common/utils/mixpanel";
import { saveAnswer } from "../../slices/stepSlice";
import { MultiSelect } from "../../types/product";
import { trackEventsPendo } from "../../common/utils/pendo";


export default function MyGoals(props:MultiSelect) {
    const [enabled, setEnabled] = useState(false);
    const dispatch = useDispatch();
    const choice = useSelector((state: any) => state.steps.userChoice);
    function handleClick() {
        let payload = {
            title:props.value,
            serverValue:props.id
          }
          trackEvents( "question_sixth_picture_yourself_selected",{"answer":props.value})    
          trackEventsPendo( "question_sixth_picture_yourself_selected",{"answer":props.value}) 
      dispatch(saveAnswer(payload));
    }
    useEffect(() => {
      if (choice.myGoals.selection.includes(props.value)) {
        setEnabled(true);
      }
    }, []);
    return (
      <>
        <div
          className={`${
            enabled
              ? "border-[1.5px] border-secondary h-[270px] rounded-2xl"
              : "border border-[#BFBFBF] h-[270px] rounded-2xl "
          } `}
          onClick={() => {
            handleClick();
            setEnabled(!enabled);
          }}
        >
          <div className="flex flex-col gap-2 h-full">
            <div
              className="basis-9/12 rounded-t-2xl"
              style={{
                backgroundImage: `url(${props.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                checked={enabled}
                onChange={() => setEnabled(!enabled)}
                className="block accent-secondary m-3"
              />
            </div>
            <div className="flex items-center basis-1/5 p-3">
              <h3 className="custom-subtitle mx-2 text-primary leading-5 text-lg text-left">
                {props.value}
              </h3>
            </div>
          </div>
        </div>
      </>
    )
}