import {setIsLoggedIn, setLoading} from "../slices/stepSlice";
import {post} from "./common";
import {store} from "../store";
import {editMotivationUser} from "./constants";
import {setSessionExpired} from "../slices/dashboardSlice";
const dispatch = store.dispatch;

export const saveAboutMeDetails=async (payload:any)=> {
    return new Promise(async (resolve, reject) => {
        dispatch(setLoading(true));
        try {
            let response:any = await post(editMotivationUser, payload);
            if (response.status === 200) {
                resolve(response)
            }
            dispatch(setLoading(false))
        } catch (e:any) {
            dispatch(setLoading(false))
            if(e.request.status === 401){
                localStorage.setItem('loggedIn', 'false')
                dispatch(setSessionExpired(true))
                dispatch(setIsLoggedIn(false))
            }
            reject(e);
        }
    })
}