import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/branding_details.svg'
import back_icon from '../../assets/icons/product_back.svg'
import Coupon from '../../components/coupon/Coupon';
import { ROUTES } from '../../routes';
import Rating from '../rating/Rating';
import {useDispatch, useSelector} from "react-redux";
import {setParentScreen} from "../../slices/dashboardSlice";
import {CustomButton} from "../CustomButton";
import { trackEvents } from '../utils/mixpanel';
import { trackEventsPendo } from '../utils/pendo';


export default function ProductDetails() {
  const {state} = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { parentPath} = useSelector(
      (state: any) => state.dashboard
  );
  let {  isLoggedIn } = useSelector(
      (state: any) => state.steps
  );
  const [displayDetails ,setDisplayDetails] = useState(true)

  function handleBack() {
    navigate(parentPath)
    dispatch(setParentScreen(ROUTES.DASHBOARD))
  }
  function handleBuyNow(){
    trackEvents('buy_now_product', {productName: state.productName, productSku: state.productSku, isUserLoggedIn: isLoggedIn})
    trackEventsPendo('buy_now_product', {productName: state.productName, productSku: state.productSku, isUserLoggedIn: isLoggedIn})
    window.open(state.productPageUrl)
  }

  function handleCuponBack(){
    setDisplayDetails(true)
  }

  function handleSavings(){
    trackEvents('on_prod_details_unlock_savings',{productName: state.productName, productSku: state.productSku, isUserLoggedIn: isLoggedIn})
    trackEventsPendo('on_prod_details_unlock_savings',{productName: state.productName, productSku: state.productSku, isUserLoggedIn: isLoggedIn})
    setDisplayDetails(false)
  }

  return (
    <>
    {
      displayDetails ? (
        <div className="h-full flex flex-col p-7 short:p-6 gap-3">
        <div className='flex justify-between'>
          <div>
            <button onClick={handleBack}>
            <img src={back_icon} alt="back button" />
            </button>
          </div>
          <div>
            <img src={logo} alt="cvs logo" />
          </div>
          <div>
          </div>
        </div>
        <div className='flex justify-center m-2'>
          <div className='h-28 w-28'>
            <img src={state.productImageURL} alt={state.productBrand} />
          </div>
        </div>
        <hr />
        <div className='flex justify-between mt-4 gap-2 short:mt-3'>
           <div className=''>
              <h3 className='subtitle-2 text-primary'>{state.productName}</h3>
           </div>
           <div>
              <h3 className='subtitle-2 text-primary'>${state.productPrice}</h3>
           </div>
        </div>
        <div className='flex gap-1'>
          <span><Rating startRating={state.productRating}/></span>
          <span className='text-[12px] text-[#8C8C8C]'>{state.productRating} ({state.productReview} Reviews)</span>
          <span className='text-[12px] text-primary px-2'>Quantity : {state.productSkuSize}</span>
        </div>
        <div className='basis-1/4 overflow-scroll text-sm  text-[#595959] font-[Helvetica-Neue] my-2' dangerouslySetInnerHTML={{__html:state.productDetails}}>
        </div>
        <div className='flex flex-col justify-center gap-2'>
          <CustomButton
              label={'Buy Now'}
              handleClick={handleBuyNow}
              buttonContainerClass={"flex justify-center px-3 short:px-6"}
              buttonStyleClass='w-[340px] subtitle-2 bg-secondary text-white  py-2 px-6 border border-secondary short:px-3 rounded-full'/>
          <CustomButton
              label={'Unlock my Savings'}
              handleClick={handleSavings}
              buttonContainerClass={"flex justify-center px-3 short:px-6"}
              buttonStyleClass='w-[340px] subtitle-2 text-secondary  py-2 px-6 short:px-3 border border-secondary rounded-full'/>
        </div>
        <div className='basis-1/4 overflow-auto text-sm'>
        <div  dangerouslySetInnerHTML={{__html:state.productWarnings}}/>
        <div  dangerouslySetInnerHTML={{__html:state.productDirection}}/>
        </div>
      </div>
      ): <Coupon handleCuponBack ={handleCuponBack}/>
    }
    </>
  )
}
