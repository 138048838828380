import { createSlice } from "@reduxjs/toolkit";

const articleState = {
    selected:"Science of Quitting",
    articleThemes:[{ theme: "Science of Quitting" }],
    selectedIndex:0
}

const articleSlice = createSlice({
    name:"article",
    initialState:articleState,
    reducers:{
        setArticlesTheme:(state,action)=>{
            state.articleThemes = action.payload
        },
        setSelectedIndex:(state,action)=>{
            state.selectedIndex = action.payload
        },
        setSelectedValue:(state,action) =>{
            state.selected = action.payload
        }
    }
})

export const {setArticlesTheme,setSelectedIndex,setSelectedValue} = articleSlice.actions


export default articleSlice.reducer;