import splash from '../assets/images/splash_screen.png'
export default function SplashCard() {
  return (
      <>
        <div className='flex justify-center h-5/6'>
          <img src={splash} alt="splash card"/>
        </div>
      </>
  )
}
