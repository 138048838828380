import { Switch } from "@headlessui/react";
import { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import DashboardHeader from "../dashboard/DashboardHeader";
import { saveAboutMeDetails } from "../../api/myaccount";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../api/dashboard";
import ToastMessage from "../../common/ToastMessage";
import mixpanel from "mixpanel-browser";
import { Dialog, Transition } from "@headlessui/react";
import morning from "../../assets/images/morning.png";
import afternoon from "../../assets/images/afternoon.png";
import evening from "../../assets/images/evening.png";
import { RadioGroup } from "@headlessui/react";
import { CustomButton } from "../../common/CustomButton";
import { unsubscribeSms } from '../../api/smsnotifications'
import _ from 'lodash';
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function SmsTime() {
  const navigate = useNavigate();
  const { userProfileData } = useSelector((state: any) => state.steps);
  const [enabled, setEnabled] = useState(userProfileData?.email_notification);
  const [smsEnabled, setSmsEnabled] = useState(
    userProfileData?.sms_notification
  );
  // const [showToast, setShowToast] = useState({ show: false, toastType: '', message: '' });
  const [showToast, setShowToast] = useState({
    show: false,
    toastType: "Error",
    message: "Something went wrong. Please try again.",
  });
  const [mainDivClass, setMainDivClass] = useState(
    /* "h-full flex flex-col bg-white" */ "h-full flex flex-col opacity-50 bg-black"
  );
  // const [enableNotification, setEnableNotification] = useState(localStorage.getItem("enableNotification"));
  const [enableNotification, setEnableNotification] = useState("true");
  const [checkedNotificationOption, setCheckedNotificationOption] =
    useState("morning");
  const [notificationDialog, setNotificationDialog] = useState(false)

  function handleBack() {
    navigate(ROUTES.DASHBOARD);
  }

  useEffect(() => {
    trackEvents("on_settings");
    trackEventsPendo("on_settings");
  }, []);

  const [isOpen, setIsOpen] = useState(true);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
    setMainDivClass("h-full flex flex-col opacity-50 bg-black");
  }

  async function handleEmailNotification() {
    trackEvents("updated_email_notification", { emailPreference: !enabled });
    trackEventsPendo("updated_email_notification", { emailPreference: !enabled });
    setEnabled(!enabled);
    try {
      const payload = {
        userId: userProfileData?.id,
        emailNotification: !enabled,
      };
      const result: any = await saveAboutMeDetails(payload);
      if (result.status === 200) {
        setShowToast({
          show: true,
          toastType: "Success",
          message: "Email preferences saved successfully.",
        });
        getUserDetails({ email_id: userProfileData?.email });
      } else {
        setShowToast({
          show: true,
          toastType: "Error",
          message: "Something went wrong. Please try again.",
        });
      }
    } catch (e) {
      setShowToast({
        show: true,
        toastType: "Error",
        message: "Something went wrong. Please try again.",
      });
    }
  }

  async function handleSmsNotification() {
    if (!smsEnabled) {
      // navigate(ROUTES.VERIFICATION);
      setEnableNotification("true")

    } else {
      let email = localStorage.getItem('userEmail')

      if (!email) {
        email = userProfileData?.email
      }
      let unsubscribeSmsRes = await unsubscribeSms({ email: email });

      let resMsg = _.get(unsubscribeSmsRes, 'data.response');

      if (resMsg == 'success') {
        setSmsEnabled(false);
      }
    }
  }

  function handleGetStarted() {
    localStorage.removeItem("enableNotification");
    navigate(ROUTES.DASHBOARD);
  }

  return (
    <>
      {showToast.show && (
        <ToastMessage
          toastType={showToast.toastType}
          message={showToast.message}
          handleClose={() => {
            setShowToast({ show: false, toastType: "", message: "" });
          }}
        />
      )}
      <div className={"flex h-full flex-col bg-white"}>
        <div className="bg-sky-200">
          <DashboardHeader
            title="Settings"
            back={true}
            handleBack={handleBack}
          />
        </div>
        <div className="mt-4 flex grow flex-col gap-3 bg-white">
          <div className="my-6 px-6 short:my-2">
            <h3 className="custom-subtitle text-primary sm:text-lg">
              My Preferences
            </h3>
          </div>
          <div className="px-6">
            <hr />
          </div>

          {/* Email */}
          <div className="my-6 flex justify-between px-6 short:my-2">
            <h3 className="custom-text text-[#8C8C8C]">Email Notifications</h3>
            <div>
              <Switch
                checked={enabled}
                onChange={() => handleEmailNotification()}
                className={`${enabled ? "bg-secondary" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${enabled ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
          {/* Sms */}
          <div className="my-6 flex justify-between px-6 short:my-2">
            <h3 className="custom-text text-[#8C8C8C]">Sms Notifications</h3>
            <div>
              <Switch
                checked={smsEnabled}
                onChange={() => handleSmsNotification()}
                className={`${smsEnabled ? "bg-secondary" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${smsEnabled ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>

          <div className="h-2 bg-[#F5F5F5] " />
          {/* Language */}
          <div className="my-6 px-6 short:my-2">
            <h3 className="custom-subtitle text-primary">Language</h3>
          </div>
          <div className="px-6">
            <hr />
          </div>
          <div className="custom-text my-6 px-6 text-[#8C8C8C] short:my-4">
            <h3 className="custom-text text-[#8C8C8C]">English (US)</h3>
          </div>
          <div className="h-2 bg-[#F5F5F5] " />
          {/* TC and FAQ */}
          <Link to={`/termsAndCondition/Settings`}>
            <div className="my-6 px-6 short:my-2">
              <h3 className="custom-subtitle text-primary">
                Terms and Conditions
              </h3>
            </div>
          </Link>
          <div className="px-6">
            <hr />
          </div>
          <Link to={"/privacyPolicy/Settings"}>
            <div className="my-6 px-6 short:my-2">
              <h3 className="custom-subtitle text-primary">Privacy Policy</h3>
            </div>
          </Link>
          <div className="px-6">
            <hr />
          </div>
          <Link to={ROUTES.FAQ}>
            <div className="my-6 mb-20 px-6 short:my-2">
              <h3 className="custom-subtitle text-primary">FAQ</h3>
            </div>
          </Link>
        </div>
      </div>

      {/* {enableNotification == "true" && <Dialog open={true}
        onClose={() => { }}
        className="absolute top-0  w-full md:w-96 md:mx-[40%]">
        <div
          className="fixed w-full md:w-96  h-full md:h-[80vh] md:mt-8 bg-black/30 "
          aria-hidden="true"
        >
          <Dialog.Panel className="relative z-10 bg-yellow-200">
            <div className="h-[22rem] bg-white z-20 short:h-60 border rounded-lg shadow-md my-[50%]">
              <div className="mt-2 text-left px-5 z-40 bg-yellow-500">
                <p className="text-sm text-gray-700 pt-2 z-50">
                  Notifications
                  <button onClick={()=>console.log("clicked")}>test</button>
                  <Switch
                    checked={enabled}
                    onChange={() => handleEmailNotification()}
                    className={`${enabled ? "bg-secondary" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full float-right`}
                  >
                    <span
                      className={`${enabled ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </p>
                <p className="text-sm text-gray-700 pt-4">
                  Select your preferred time slot
                </p>
              </div>

              <div className="grid grid-cols-3 gap-3 px-10">
                <div className="grid-content flex">
                  <div className="grid-content-text">
                    <div className="notificationMainBox">
                      <div className="notificationBox">
                        <img src={morning} />
                      </div>
                      <div className="text-sm text-left px-3">
                        Morning
                      </div>
                      <div className="text-left text-sm">
                        (8 am - 12 pm)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-content flex">
                  <div className="grid-content-text">
                    <div className="notificationMainBox">
                      <div className="notificationBox">
                        <img src={afternoon} />
                      </div>
                      <div className="text-sm text-left px-3">
                        Afternoon
                      </div>
                      <div className="text-left text-sm">
                        (12 pm - 5 pm)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-content flex">
                  <div className="grid-content-text">
                    <div className="notificationMainBox">
                      <div className="notificationBox">
                        <img src={evening} />
                      </div>
                      <div className="text-sm text-left px-3">
                        Evening
                      </div>
                      <div className="text-left text-sm">
                        (5 pm - 8 pm)
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RadioGroup value="notification">
                <div className="grid grid-cols-3 gap-3">

                  <RadioGroup.Option
                    value={1}
                    key={1}
                    onClick={() => { setCheckedNotificationOption("morning") }}
                  >
                    <div
                      className={
                        checkedNotificationOption == "morning"
                          ? "h-[124px]  flex justify-center items-center py-[5px] relative"
                          : "h-[124px]  flex justify-center items-center border border-[#BFBFBF] rounded-lg py-[5px] relative"
                      }
                    >
                      <div className="absolute top-1 left-14">
                        <input
                          type="radio"
                          checked={checkedNotificationOption == 'morning'}
                          onChange={() => alert()}
                          className="accent-secondary w-[20px] h-[113px] m-1"
                        />
                      </div>


                    </div>
                  </RadioGroup.Option>

                  <RadioGroup.Option
                    value={2}
                    key={2}
                    onClick={() => { alert(); setCheckedNotificationOption("afternoon"); }}
                  >
                    <div
                      className={
                        checkedNotificationOption == "afternoon"
                          ? "h-[124px]  flex justify-center items-center py-[5px] relative"
                          : "h-[124px]  flex justify-center items-center border-[#BFBFBF] rounded-lg py-[5px] relative"
                      }
                    >
                      <div className="absolute top-1 left-10">
                        <input
                          type="radio"
                          checked={checkedNotificationOption == 'afternoon'}
                          onChange={() => alert()}
                          className="accent-secondary w-[20px] h-[113px] m-1"
                        />
                      </div>
                    </div>
                  </RadioGroup.Option>

                  <RadioGroup.Option
                    value={3}
                    key={3}
                    onClick={() => { setCheckedNotificationOption("evening") }}
                  >
                    <div
                      className={
                        checkedNotificationOption == "evening"
                          ? "h-[124px]  flex justify-center items-center py-[5px] relative"
                          : "h-[124px]  flex justify-center items-center border-[#BFBFBF] rounded-lg py-[5px] relative"
                      }
                    >
                      <div className="absolute top-1 left-4">
                        <input
                          type="radio"
                          checked={checkedNotificationOption == 'evening'}
                          // checked={checked}
                          onChange={() => alert()}
                          className="accent-secondary w-[20px] h-[113px] m-1"
                        />
                      </div>
                    </div>
                  </RadioGroup.Option>
                </div>
              </RadioGroup>
            </div>


          </Dialog.Panel>
        </div>
        <div className="mt-5 flex justify-center pb-7 pt-[37rem] px-5 relative">
          <button
            className="subtitle-2 bg-secondary text-white py-2 px-6 short:px-4 rounded-full w-[235px] "
            onClick={()=>{navigate(ROUTES.VERIFICATION)}}>
            Next
          </button>
        </div>

        <div className="mt-5 flex justify-center pb-7 pt-[37rem] px-5 relative">
          <button
            className="subtitle-2 bg-secondary text-white py-2 px-6 short:px-4 rounded-full w-[235px] "
            onClick={handleGetStarted}>
            Back My Jouney
          </button>
        </div>
      </Dialog>} */}
      {enableNotification == "true" && (
        <Dialog className="relative z-10" open={true} onClose={() => { }}>
          <div className="fixed inset-0 bg-black bg-opacity-30">
            <div className="fixed inset-0 overflow-y-auto flex flex-col justify-center ">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-[21px] text-left align-middle shadow-xl">
                <div className="flex justify-between">
                  <p>Notifications</p>
                  <Switch
                    disabled={true}
                    checked={enabled}
                    onChange={() => handleEmailNotification()}
                    className={`${enabled ? "bg-secondary" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span
                      className={`${enabled ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
                <p className="mt-6">Select your preferred time slot</p>
                <RadioGroup value="notification" className="mt-6">
                  <div className="grid grid-cols-3 gap-3">
                    <RadioGroup.Option
                      value={1}
                      key={1}
                      onClick={() => {
                        setCheckedNotificationOption("morning");
                      }}
                    >
                      <div
                        className={
                          checkedNotificationOption == "morning"
                            ? "relative flex items-center justify-center py-[5px]"
                            : "relative flex items-center justify-center rounded-lg py-[5px]"
                        }
                      >
                        <div className="flex flex-col items-center gap-[6px]">
                          <img src={morning} alt="morning" className="border rounded-2xl p-[14px] border-[#F0F0F0]" />
                          <p>Morning</p>
                          <p>(8am - 12pm)</p>
                          <input
                            type="radio"
                            checked={checkedNotificationOption == "morning"}
                            onChange={() => { }}
                            className="m-1 accent-secondary"
                          />
                        </div>
                      </div>
                    </RadioGroup.Option>

                    <RadioGroup.Option
                      value={2}
                      key={2}
                      onClick={() => {
                        // alert();
                        setCheckedNotificationOption("afternoon");
                      }}
                    >
                      <div
                        className={
                          checkedNotificationOption == "afternoon"
                            ? "relative flex items-center justify-center py-[5px]"
                            : "relative flex items-center justify-center rounded-lg border-[#BFBFBF] py-[5px]"
                        }
                      >
                        <div className="flex flex-col items-center gap-[6px]">
                          <img
                            src={afternoon}
                            alt="afternoon"
                            className="border rounded-2xl p-[14px] border-[#F0F0F0]"
                          />
                          <p>Afternoon</p>
                          <p>(12pm - 5pm)</p>
                          <input
                            type="radio"
                            checked={checkedNotificationOption == "afternoon"}
                            onChange={() => { }}
                            className="m-1 accent-secondary"
                          />
                        </div>
                      </div>
                    </RadioGroup.Option>

                    <RadioGroup.Option
                      value={3}
                      key={3}
                      onClick={() => {
                        setCheckedNotificationOption("evening");
                      }}
                    >
                      <div
                        className={
                          checkedNotificationOption == "evening"
                            ? "relative flex items-center justify-center py-[5px]"
                            : "relative flex items-center justify-center rounded-lg py-[5px]"
                        }
                      >
                        <div className="flex flex-col items-center gap-[6px]">
                          <img src={evening} alt="evening" className="border rounded-2xl p-[14px] border-[#F0F0F0]" />
                          <p>Evening</p>
                          <p>(5pm - 8pm)</p>
                          <input
                            type="radio"
                            checked={checkedNotificationOption == "evening"}
                            // checked={checked}
                            onChange={() => { }}
                            className="m-1 accent-secondary"
                          />
                        </div>
                      </div>
                    </RadioGroup.Option>
                  </div>
                </RadioGroup>
              </Dialog.Panel>
              <div className="mt-36 flex justify-center px-5">
                <button
                  className="subtitle-2 w-[235px] rounded-full bg-secondary py-2 px-6 text-white short:px-4 "
                  onClick={() => { localStorage.setItem("notificationTime", checkedNotificationOption); navigate(ROUTES.VERIFICATION) }}
                >
                  Next
                </button>
              </div>
              <div className="mt-2 flex justify-center px-5">
                <button
                  className="subtitle-2 w-[235px] rounded-full bg-white border-secondary py-2 px-6 text-secondary short:px-4 "
                  onClick={handleGetStarted}
                >
                  Back My Jouney
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
