import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  userProfileData: {},
  isLoggedIn: false,
  isNewUser: false,
  questionnaire: {},
  numOfSteps: 1,
  currentQuestion: 0,
  userChoice: {
    gender: { serverValue: "", selection: "", isDisabled: true },
    age: { serverValue: "", selection: "", isDisabled: true },
    currentUsage: { serverValue: "", selection: "", isDisabled: true },
    motivations: { selection: [], isDisabled: true, serverValue: [] },
    habbit_behaviour: { selection: [], isDisabled: true, serverValue: [] },
    myGoals: { selection: [], isDisabled: true, serverValue: [] },
    maintenance: { serverValue: "", selection: "", isDisabled: true },
    productForm: { serverValue: "", selection: "", isDisabled: true },
    duration: { serverValue: "", selection: "", isDisabled: true },
    flavour: { serverValue: "", selection: "", isDisabled: true },
    fixedBadge: { serverValue: "", selection: "", isDisabled: true },
    shareProgress: { serverValue: "", memberDetails: [], isDisabled: true },
  },
  cart: [],
};
const stepSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    increment: (state) => {
      if (state.numOfSteps < 13) {
        state.numOfSteps += 1;
      }
    },
    decrement: (state) => {
      if (state.numOfSteps > 0) {
        state.numOfSteps -= 1;
      }
    },
    incrementCurrentQuestion: (state) => {
      state.currentQuestion += 1;
    },
    decrementCurrentQuestion: (state) => {
      state.currentQuestion -= 1;
    },
    gotoQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    saveAnswer: (state, action) => {
      // alert(state.currentQuestion)
      if (state.currentQuestion === 0) {
        state.userChoice.gender = action.payload;
        state.currentQuestion++
        return;
      }
      if (state.currentQuestion === 1) {
        state.userChoice.age = action.payload;
        state.currentQuestion++
        if (
          state.userChoice.age.selection !== "" &&
          state.userChoice.age.isDisabled === true
        ) {
          state.userChoice.age.isDisabled = false;
          return;
        }
        /* add */
        state.numOfSteps++

        return;
      }
      if (state.currentQuestion === 2) {
        state.userChoice.currentUsage = action.payload;
        state.currentQuestion++
        state.numOfSteps++
        return;
      }
      if (state.currentQuestion === 3) {

        if (!state.userChoice.motivations?.selection?.includes(action.payload.title)) {
          state.userChoice.motivations?.selection?.push(action.payload.title);
          state.userChoice.motivations?.serverValue?.push(action.payload.serverValue);
        } else {

          state.userChoice.motivations?.selection?.splice(
            state.userChoice.motivations?.selection?.indexOf(action.payload.title),
            1
          );
          state.userChoice.motivations?.serverValue?.splice(
            state.userChoice.motivations?.serverValue?.indexOf(action.payload.serverValue),
            1
          );
        }
        if (state.userChoice.motivations?.selection?.length === 0) {
          state.userChoice.motivations.isDisabled = true;
          state.numOfSteps--

        }
        if (
          state.userChoice.motivations?.selection?.length !== 0 &&
          state.userChoice.motivations?.isDisabled
        ) {
          state.userChoice.motivations.isDisabled = false;
          state.numOfSteps++

        }

        // state.numOfSteps = 4
        // state.numOfSteps++

        return;
      }
      if (state.currentQuestion === 4) {
        if (!state.userChoice.habbit_behaviour?.selection?.includes(action.payload.title)) {
          state.userChoice.habbit_behaviour?.selection?.push(action.payload.title);
          state.userChoice.habbit_behaviour?.serverValue?.push(action.payload.serverValue);
        } else {
          state.userChoice.habbit_behaviour?.selection?.splice(
            state.userChoice.habbit_behaviour?.selection?.indexOf(action.payload?.title),
            1
          );
          state.userChoice.habbit_behaviour?.serverValue?.splice(
            state.userChoice.habbit_behaviour?.serverValue?.indexOf(action.payload?.serverValue),
            1
          );
        }
        if (state.userChoice.habbit_behaviour?.selection?.length === 0) {
          state.userChoice.habbit_behaviour.isDisabled = true;
          state.numOfSteps--
        }
        if (
          state.userChoice.habbit_behaviour?.selection?.length !== 0 &&
          state.userChoice.habbit_behaviour?.isDisabled === true
        ) {
          state.userChoice.habbit_behaviour.isDisabled = false;
          state.numOfSteps++
        }
        return;
      }
      if (state.currentQuestion === 5) {
        if (!state.userChoice.myGoals?.selection?.includes(action.payload.title)) {
          state.userChoice.myGoals?.selection?.push(action.payload.title);
          state.userChoice.myGoals?.serverValue?.push(action.payload.serverValue);
        } else {
          state.userChoice.myGoals?.selection?.splice(
            state.userChoice.myGoals?.selection?.indexOf(action.payload?.title),
            1
          );
          state.userChoice.myGoals?.serverValue?.splice(
            state.userChoice.myGoals?.serverValue?.indexOf(action.payload?.serverValue),
            1
          );
        }
        if (state.userChoice.myGoals?.selection?.length === 0) {
          state.userChoice.myGoals.isDisabled = true;
        }
        if (
          state.userChoice.myGoals?.selection?.length !== 0 &&
          state.userChoice.myGoals?.isDisabled === true
        ) {
          state.userChoice.myGoals.isDisabled = false;
        }
        return;
      }
      if (state.currentQuestion === 6) {
        // alert("here")
        state.userChoice.maintenance = action.payload;
        // if(action.payload.selection.includes("low")){
        // state.currentQuestion = 10
        //   state.numOfSteps++
        //   return
        // }
        // state.currentQuestion++
        return;
      }
      if (state.currentQuestion === 7) {
        state.userChoice.productForm = action.payload;
        state.currentQuestion++

        return;
      }
      if (state.currentQuestion === 8) {
        state.userChoice.duration = action.payload;
        state.currentQuestion++
        return;
      }
      if (state.currentQuestion === 9) {
        state.userChoice.flavour = action.payload;
        state.currentQuestion++
        state.numOfSteps++
        return;
      }
      if (state.currentQuestion === 11) {
        state.userChoice.fixedBadge.selection = action.payload.selection;
        // state.currentQuestion++
        return;
      }
      if (state.currentQuestion === 12) {
        //   state.userChoice.shareProgress = action.payload;
        //   if (
        //     !state.userChoice.shareProgress.memberDetails &&
        //     state.userChoice.shareProgress.isDisabled === true
        //   ) {
        //     state.userChoice.shareProgress.isDisabled = false;
        //   }
        return;
      }
    },
    addToCart: (state, action) => {
      if (state.cart.length) {
        state.cart = []
      }
      state.cart.push(action.payload);
      return;
    },
    setLoading: (state, data) => {
      state.loading = data.payload
    },
    setUserProfileData: (state, data) => {
      state.userProfileData = data.payload
    },
    setIsLoggedIn: (state, data) => {
      state.isLoggedIn = data.payload
    },
    setFlavours: (state, action) => {
      state.questionnaire["productFlavour"].Options = action.payload
    },
    setGoalImages: (state, action) => {
      let len = ((state.questionnaire["myGoals"].Options.length) - 1);
      for (let i = 0; i < len; i++) {
        if (len === action.payload.length) {
          state.questionnaire["myGoals"].Options[i].pictureUrl = action.payload[i].pictureUrl
          state.questionnaire["myGoals"].Options[i].myGoalsId = action.payload[i].pictureId
        }
      }

      state.questionnaire["myGoals"].Options[len].pictureUrl = action.payload[len - 1].pictureUrl
      state.questionnaire["myGoals"].Options[len].myGoalsId = action.payload[len - 1].pictureId

    },
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload
    },
    setAppConfig: (state, action) => {
      state.questionnaire = action.payload
    },
    setUserChoiceBadgeData: (state, action) => {
      state.userChoice.fixedBadge.selection = action.payload
    },
    setUserChoiceMotivationData: (state, action) => {
      state.userChoice.motivations.serverValue = action.payload.serverValue
      state.userChoice.motivations.selection = action.payload.selection
    },
    setUserChoiceMyGoalsData: (state, action) => {
      state.userChoice.myGoals.serverValue = action.payload.serverValue
      state.userChoice.myGoals.selection = action.payload.selection
    },
    setSmsNotificationsData: (state, action) => {
      state.smsNotification.otp = action.payload
    }
  },
});

export const {
  increment,
  decrement,
  incrementCurrentQuestion,
  decrementCurrentQuestion,
  saveAnswer,
  gotoQuestion,
  addToCart,
  setLoading,
  setUserProfileData,
  setIsLoggedIn,
  setFlavours,
  setGoalImages,
  setIsNewUser,
  setAppConfig,
  setUserChoiceBadgeData,
  setUserChoiceMotivationData,
  setUserChoiceMyGoalsData,
  setSmsNotificationsData
} = stepSlice.actions;
export default stepSlice.reducer;
