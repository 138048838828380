import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { CustomButton } from "../common/CustomButton";
import { ROUTES } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLogoutPopupOpen } from "../slices/dashboardSlice";
import { resetState } from "../api/common";
import { trackEvents } from "../common/utils/mixpanel";
import { trackEventsPendo } from "../common/utils/pendo";


const LogoutPopup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let isLogoutPopupOpen = useSelector(
        (state: any) => state.dashboard.isLogoutPopupOpen
    );

    useEffect(() => {
        trackEvents('on_logout_popup')
        trackEventsPendo('on_logout_popup')
    }, [])

    return <Dialog
        open={isLogoutPopupOpen}
        onClose={() => dispatch(setIsLogoutPopupOpen(false))}
        className="absolute top-0 z-50 w-full md:w-96 md:mx-[40%]"
    >
        <div
            className="fixed w-full md:w-96  h-full md:h-[80vh] md:mt-8 bg-black/30 "
            aria-hidden="true"
        >
            <Dialog.Panel className="relative">
                <div className="h-72 bg-[#F5F5F5] short:h-60 border rounded-lg shadow-md mx-5 my-[50%]">
                    <div className="px-[30px] py-[30px]">
                        <p className="subtitle">Are you sure you want to Log out?</p>
                        <p className="custom-bodyText text-[#8C8C8C] pt-[15px]">You will have to login again to continue
                            with your journey</p>
                        <div className="pt-[40px] short:pt-[5px] ">
                            <CustomButton label={'Yes'}
                                buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-1"
                                buttonStyleClass="custom-subtitle-2 py-2 px-6 text-secondary border w-40 border-secondary rounded-full"
                                handleClick={() => {
                                    trackEvents('handled_logout')
                                    trackEventsPendo('handled_logout')
                                    dispatch(setIsLogoutPopupOpen(false))
                                    dispatch(resetState())
                                    localStorage.clear()
                                    sessionStorage.clear()
                                    navigate(ROUTES.LOGIN)
                                }} />
                        </div>
                        <CustomButton
                            label={'Cancel'}
                            buttonStyleClass="custom-subtitle-2 text-secondary px-5"
                            buttonContainerClass="flex justify-center mb-2 px-3 short:px-6 py-3 short:py-0"
                            handleClick={() => {
                                dispatch(setIsLogoutPopupOpen(false))
                            }} />
                    </div>
                </div>
            </Dialog.Panel>
        </div>
    </Dialog>
}
export default LogoutPopup