import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { increment, incrementCurrentQuestion } from "../../slices/stepSlice";
import { sendProgressMail } from "../../api/onboarding";
import { CustomButton } from "../../common/CustomButton";
import mixpanel from "mixpanel-browser";
import { store } from "../../store";
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function NextButton() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let getUserId = store.getState().steps.userProfileData.id

  const { currentQuestion, userChoice, questionnaire } = useSelector(
    (state: any) => state.steps
  );

  let choice = useSelector(
    (state: any) => state.steps.userChoice.maintenance["selection"]
  );
  let currentIndex = Object.keys(userChoice)[currentQuestion];

  if (currentQuestion === 11) {
    currentIndex = "fixedBadge"
  }
  if (currentQuestion === 12) {
    currentIndex = "shareProgress"
  }
  let buttonDisabled = userChoice[currentIndex]?.isDisabled;
  async function handleNext() {
    let type = Object.keys(questionnaire)[currentQuestion]
    let question = questionnaire[type]?.Question
    let answer = ''
    // alert("next "+ currentQuestion)
    switch (currentQuestion) {
      case 0:
        answer = userChoice.gender.selection
        break
      case 1:
        answer = userChoice.age.selection
        break
      case 2:
        answer = userChoice.currentUsage.selection
        break
      case 3:
        answer = userChoice.motivations.selection
        break
      case 4:
        answer = userChoice.habbit_behaviour.selection
        break
      case 5:
        answer = userChoice.myGoals.selection
        break
      case 6:
        answer = userChoice.maintenance.selection
        break
      case 7:
        answer = userChoice.productForm.selection
        break
      case 8:
        answer = userChoice.duration.selection
        break
      case 9:
        answer = userChoice.flavour.selection
        break
      case 11:
        answer = userChoice.fixedBadge.selection
        break
      case 12:
        answer = userChoice.shareProgress.memberDetails?.length
        break
      default:
        answer = ''
    }

    console.log("currentQuestion", currentQuestion)
    
    if (currentQuestion === 6) {
      navigate(ROUTES.DASHBOARD)
    }
    if (currentQuestion === 5) {
      dispatch(increment());
      if (getUserId) {
        dispatch(incrementCurrentQuestion());
      } else {
        navigate(ROUTES.SIGNUP);
      }
      return;
    }
    if (currentQuestion === 10) {
      dispatch(increment());
      dispatch(incrementCurrentQuestion());
      return;
    }
    if (currentQuestion === 11) {
      dispatch(incrementCurrentQuestion());
      navigate(ROUTES.EARNBADGE);
      return;
    }
    if (currentQuestion === 12) {
      let result: any = await sendProgressMail(userChoice.shareProgress.memberDetails)
      if (result.status === 200) {
        trackEvents('share_progress_clicked')
        trackEventsPendo('share_progress_clicked')
        navigate(ROUTES.DASHBOARD)
      }
      return;
    }
    if (
      currentQuestion === 4
    ) {
      dispatch(increment());
      dispatch(incrementCurrentQuestion());
      return;
    }
    dispatch(incrementCurrentQuestion());
  }
  const steps = useSelector((state: any) => state.steps.currentQuestion);

  return (
    <CustomButton
    centeralign={true}
      label={currentQuestion === 12 ? 'Share' : `Next (${steps+1}/6)`}
      buttonStyleClass="bg-secondary disabled:bg-[#E4E4E4] disabled:text-[#434343] md:w-full w-[43vw]  max-w-[465px] py-[10px] px-[24px] md:px-[36px] md:py-[16px] rounded-full text-white md:h-[60px] h-[45px]"
      handleClick={() => handleNext()}
      isDisabled={currentQuestion !== 10 ? buttonDisabled : false}
    />
  );
}