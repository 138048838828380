import { useSelector } from 'react-redux'
import cart from '../../assets/icons/wishlist.svg'

export default function Cart() {
  const cartState = useSelector((state:any)=>{
    return state.steps.cart
  })
  return (
    <div className='relative'>
      {/* <img src={cart} alt="cart" /> */}
      {
        cartState.length !== 0 && (
          <div className='bg-white rounded-full absolute w-5 h-5 m-1 text-center -right-3 -top-4'>{cartState.length}</div>
        )
      }
    </div>
  )
}
