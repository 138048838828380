import React from "react";

export const CustomButton=(props:any)=> {
    const {handleClick, label, buttonStyleClass, isDisabled, buttonContainerClass, maxwidth, centeralign} = props;
    return <div className={buttonContainerClass ?? centeralign?"flex justify-center w-full m-auto":"flex w-full"}>
        <button
            disabled={isDisabled}
            className={ buttonStyleClass ?? "disabled:bg-[#E4E4E4] disabled:text-[#434343] subtitle-2 bg-secondary text-white py-[20px] px-[24px] rounded-full"}
            onClick={handleClick}>
            {label}
        </button>
    </div>
}