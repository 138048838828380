import { useDispatch, useSelector } from "react-redux";
import { saveAnswer } from "../../slices/stepSlice";
import { RadioGroup } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { setUserBadge } from "../../api/onboarding";
import _ from 'lodash';
import { trackEvents } from "../../common/utils/mixpanel";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import { trackEventsPendo } from "../../common/utils/pendo";
export default function Badges() {
    const isMobile = useCheckMobileScreen();
    const dispatch = useDispatch();
    const { currentQuestion, questionnaire, userChoice } = useSelector((state: any) => state.steps);
    const type = Object.keys(questionnaire)[currentQuestion];
    const navigate = useNavigate();
    async function handleClick(item: any) {
        let response = {
            serverValue: "fixedBadge",
            selection: item,
        };
        localStorage.setItem("selectedBuddy", JSON.stringify(response));
        let signupData = localStorage.getItem('signupData')
        signupData = JSON.parse(signupData)

        let badgeData = localStorage.getItem("selectedBuddy")
        badgeData = JSON.parse(badgeData)
        
        // await dispatch(saveAnswer(response));

        const payload = {
            userId: _.get(signupData, 'id'),
            badgeId: _.get(badgeData, 'selection.id')
        }
        await setUserBadge(payload)
        trackEvents( "selected_buddy_icon_after_signup",{"answer":badgeData})    
        trackEventsPendo( "selected_buddy_icon_after_signup",{"answer":badgeData})   
        // navigate(ROUTES.DASHBOARD)


        if(!isMobile){
            navigate(ROUTES.DESKTOP_LANDING);
          }
          else{
            navigate(ROUTES.DASHBOARD)
          }
        
        // 
    }

    return (
        <div className="flex flex-col h-full gap-2">
            <RadioGroup value={userChoice.fixedBadge["selection"]?.id}>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-5">
                    {questionnaire[type].Options.map(
                        (item: any, index: any, array: any[]) => {
                            return (
                                <RadioGroup.Option
                                    value={item.id}
                                    key={index}
                                    onClick={() => handleClick(item)}
                                >
                                    {({ checked }) => (
                                        <div
                                            className={
                                                checked
                                                    ? "h-[124px] md:h-[200px] border-[1.5px] border-secondary rounded-lg flex justify-center items-center py-[5px] relative"
                                                    : "h-[124px] md:h-[200px]  flex justify-center items-center border border-[#BFBFBF] rounded-lg py-[5px] relative"
                                            }
                                        >
                                            <div className="absolute top-1 left-1">
                                                <input
                                                    type="radio"
                                                    checked={checked}
                                                    onChange={() => !checked}
                                                    className="accent-secondary w-[20px] h-[20px] m-1"
                                                />
                                            </div>
                                            <div className="flex justify-center h-full">

                                                <img
                                                    src={item.batchUrl}
                                                    alt="icon"
                                                    className={"block"}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </RadioGroup.Option>
                            );
                        }
                    )}
                </div>
            </RadioGroup>
        </div>
    );
}