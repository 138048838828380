import BottomNav from "./BottomNav";
import DashboardHeader from "./DashboardHeader";
import support from "../../assets/images/support.png";
import cdc from "../../assets/images/cdc.png";
import minute from "../../assets/images/minute.png";
import nicoratte from "../../assets/images/nicoratte.png";
import cvs from "../../assets/icons/branding_details.svg";
import { Listbox, Tab, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { setIsLeftDrawerOpen, setShowCart } from "../../slices/dashboardSlice";
import LeftMenu from "../../common/LeftMenu";
import LogoutPopup from "../LogoutPopup";
import { Fragment, useEffect, useState, useRef } from "react";
import { get } from "../../api/common";
import { getArticleOptions, getArticles } from "../../api/constants";
import Loader from "../../common/spinner/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import {
  setArticlesTheme,
  setSelectedIndex,
  setSelectedValue,
} from "../../slices/articleSlice";
import Cart from "./Cart";
import { trackScroll } from "./Dashboard";
import { Dialog } from "@headlessui/react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { HowItWorks, SupportInfo } from "./supportGuide";
import buttonArrow from '../../assets/icons/buttonArrow.png'
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { trackEvents } from "../../common/utils/mixpanel";
import { Events } from "../../common/Mixpanel_Events_constant";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function Support() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected, articleThemes, selectedIndex } = useSelector(
    (state: any) => state.articleState
  );
  const { isLeftDrawerOpen, isLogoutPopupOpen, showCart } = useSelector(
    (state: any) => state.dashboard
  );
  const { isNewUser } = useSelector((state: any) => state.steps);
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(isNewUser ? true : false);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (articleThemes.length <= 1) {
      setLoading(true);
      get(getArticleOptions)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch(setArticlesTheme(res.data.data));
            setLoading(false);
          }
        })
        .catch((error: any) => {
          alert(" Get Articles request failed with message : " + error.message);
        });
    }
    setLoading(true);
    get(getArticles, { params: { theme: selected } })
      .then((res: any) => {
        if (res.status === 200) {
          setArticles(res.data.data);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        alert("Articles request failed with message : " + err.message);
        setLoading(false);
      });
  }, [selected]);

  useEffect(() => {
    trackScroll("articles", false, "articles-scroll");
  }, []);
  // Articles Section

  function setSelected(value: any) {
    dispatch(setSelectedValue(value));
  }
  function handleCardClick(article: any) {
    navigate(ROUTES.ARTICLEDETAILS, { state: { article } });
  }

  function handleTab(index: any) {
    if(index){
      trackEvents("on_article_tab")
      trackEventsPendo("on_article_tab")
    }else{
      trackEvents("on_resource_tab")
      trackEventsPendo("on_resource_tab")
    }
    dispatch(setSelectedIndex(index));
  }

  // Left menu
  const toggleMenuDrawer = () => {
    dispatch(setIsLeftDrawerOpen(!isLeftDrawerOpen));
  };
  return (
    <>
      <div className="fixed flex h-full flex-col bg-sky-200 w-full">
        <DashboardHeader
          menu={true}
          title="My Journey"
          cart={true}
          handleMenu={() => toggleMenuDrawer()}
          handleCart={() => {
            dispatch(setShowCart(!showCart));
          }}
        />
        <div className="h-full overflow-clip bg-white">
          <Tab.Group
            defaultIndex={selectedIndex}
            onChange={(index: any) => handleTab(index)}
          >
            <Tab.List className=" mt-5 flex justify-around short:mt-2">
              <Tab className="w-full pl-2">
                {({ selected }) => (
                  <div>
                    <p
                      className={
                        selected
                          ? "subtitle-2 text-secondary"
                          : "subtitle-2 text-[#8C8C8C]"
                      }
                    >
                      Resources
                    </p>
                    <div
                      className={
                        selected
                          ? "mt-4  h-1 bg-secondary"
                          : "mt-4 h-1 bg-[#8C8C8C]"
                      }
                    />
                  </div>
                )}
              </Tab>
              <Tab className="w-full pr-2">
                {({ selected }) => (
                  <div>
                    <p
                      className={
                        selected
                          ? "subtitle-2 text-secondary"
                          : "subtitle-2 text-[#8C8C8C]"
                      }
                    >
                      Articles
                    </p>
                    <div
                      className={
                        selected
                          ? "mt-4  h-1 bg-secondary"
                          : "mt-4 h-1 bg-[#8C8C8C]"
                      }
                    />
                  </div>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="h-full px-5 pt-6 short:px-4 short:py-3">
              <Tab.Panel className="flex h-full flex-col gap-3">
                <h2 className="custom-h2 text-primary">
                Tools & Support to Help You Quit
                </h2>
                <div className="m-2 flex justify-center">
                  <img src={support} alt="support" className="short:h-36" />
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <a
                    href="https://www.cvs.com/shop/merch/stop-smoking-resources"
                    target="_blank"
                    onClick={()=>trackEvents(Events.CLICKED_ON_CVS_RESOURCE)}
                  >
                    <div className="flex h-20 items-center justify-center rounded-lg border border-[#D9D9D9] short:h-12 short:w-40">
                      <img
                        src={cvs}
                        alt="cvs-logo"
                        className="short:h-5 short:w-14"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.cvs.com/minuteclinic/services/smoking-cessation"
                    target="_blank"
                    onClick={()=>trackEvents(Events.CLICKED_ON_MINUTE_RESOURCE)}
                  >
                    <div className=" flex h-20 items-center justify-center rounded-lg border border-[#D9D9D9] short:h-12 short:w-40">
                      <img
                        src={minute}
                        alt="minute clinic"
                        className="short:h-5 short:w-auto"
                      />
                    </div>
                  </a>
                  <a href="https://www.nicorette.com/" target="_blank" onClick={()=>trackEvents(Events.CLICKED_ON_NICORETTE_RESOURCE)}>
                    <div className="flex h-20 items-center justify-center rounded-lg border border-[#D9D9D9] short:h-12 short:w-40">
                      <img
                        src={nicoratte}
                        alt="nicoratte"
                        className="short:h-7 short:w-auto"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.cdc.gov/tobacco/quit_smoking/"
                    target="_blank"
                    onClick={()=>trackEvents(Events.CLICKED_ON_CDC_RESOURCE)}
                  >
                    <div className="flex h-20 items-center justify-center rounded-lg border border-[#D9D9D9] short:h-12 short:w-40">
                      <img
                        src={cdc}
                        alt="health Hub"
                        width="88px"
                        className="short:h-auto short:w-14"
                      />
                    </div>
                  </a>
                </div>
              </Tab.Panel>
              <Tab.Panel className="h-full px-4 short:px-2 short:py-1">
                {isLoading && (
                  <div className="flex h-full items-center justify-center">
                    <Loader />
                  </div>
                )}
                {/* Select */}
                <div className="my-1">
                  <h2 className="custom-h2 text-primary">Motivation for today</h2>
                  <Listbox
                    value={selected}
                    onChange={(value: any) => setSelected(value)}
                  >
                    <div className="relative mt-2 rounded-lg border">
                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-secondary sm:text-sm">
                        <span className="block truncate">{selected}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <i className="fa-solid fa-caret-down"></i>
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opacity-100 focus:outline-none sm:text-sm ">
                          {articleThemes.map((article: any, index: number) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                  ? "bg-secondary text-white"
                                  : "text-gray-900"
                                }`
                              }
                              value={article.theme}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? "font-medium" : "font-normal"
                                      }`}
                                  >
                                    {article.theme}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div
                  className="h-[75%] overflow-auto short:h-[70%]"
                  id="articles"
                >
                  {articles &&
                    articles.length > 0 &&
                    articles.map((article: any) => {
                      return (
                        <div
                          className="my-2"
                          onClick={() => handleCardClick(article)}
                          key={article.id}
                        >
                          <div className="flex flex-col">
                            <div className="relative flex h-80 flex-col rounded-lg border border-[#BFBFBF] short:h-72">
                              <div
                                className="basis-2/3"
                                style={{
                                  backgroundImage: `url(${article.image_link})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                }}
                              ></div>
                              <div className="flex basis-1/3 items-center justify-center px-3">
                                <p className="block font-[Helvetica-Neue] font-medium text-primary short:text-sm">
                                  {article.title}
                                </p>
                              </div>
                              <div className="absolute top-2 right-2 h-6 rounded-full bg-[#00000066] px-3 py-1 text-xs text-[#F0F0F0]">
                                Sponsored
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <BottomNav supportIsActive />
        <LeftMenu />
        {isLogoutPopupOpen && <LogoutPopup />}
        {!isLeftDrawerOpen && (isNewUser || showCart) && <Cart />}
      </div>

      {/* support Introduction modal */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50 bg-blue-100"
      >
        <div className="fixed inset-0 flex h-screen items-center justify-center bg-black bg-opacity-80 p-4">
          <Dialog.Panel className="h-[80%] w-full max-w-sm rounded bg-white">
            <div className="flex h-full flex-col items-center">
              <Swiper
                ref={swiperRef}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="mySwiper pop-up"
              >
                <SwiperSlide>
                  <SupportInfo />
                </SwiperSlide>
              </Swiper>
              <button
                onClick={() => {
                  // Check if it is the last slide
                  if (swiperRef.current.swiper.isEnd) {
                    setIsOpen(!isOpen);
                    // setBuddyPopUpIsOpen(true);
                  }
                  else {
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideNext();
                      return;
                    }
                  }

                }}
                className="mb-[20px] h-14 w-14 flex justify-center items-center text-2xl rounded-full bg-secondary font-medium text-white"
              >
                <img src={buttonArrow} alt="forword button" />
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
