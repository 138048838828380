import { useDispatch, useSelector } from "react-redux";
import AgeGender from "./AgeGender";
import Why from "./Why";
import Usage from "./Usage";
import Habbits from "./Habbits";
import MyGoals from "./MyGoals";
import Replacement from "./Replacement";
import ProductOptions from "./ProductOptions";
import Badges from "./Badges";
import ShareProgress from "./ShareProgress";
import ProductCard from "../../common/productCard/productCard";
import { useEffect, useState } from "react";
import { store } from "../../store";
import { get } from "../../api/common";
import { GETGOALIMAGE } from "../../api/constants";
import { Product } from "../../types/product";
import Loader from "../../common/spinner/Loader";
import { setGoalImages } from "../../slices/stepSlice";
import {ROUTES} from "../../routes";
import {getFilteredProducts} from "../../api/onboarding";
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function QuestionOptions() {
  let dispatch = useDispatch()
  const [productData,setProductData] = useState([])
  const [isLoading ,setIsLoading] = useState(false)
  let errorMessage = ""
  const {questionnaire, currentQuestion} = useSelector((state: any) => state.steps);
  let gender = useSelector((state:any) => state.steps.userChoice.gender.serverValue)
  let age = useSelector((state:any) => state.steps.userChoice.age.serverValue)
  
  useEffect(()=>{
    let productRequest: any = {};

    // My Goals API call 
    if(currentQuestion === 5){
      setIsLoading(true)
      get(GETGOALIMAGE,{params:{gender:gender,age:age}}).then((res)=>{
        console.log('------------------------', res);
        
         if(res.status === 200 && res.data.response.length > 0){
            dispatch(setGoalImages(res.data.response))
            setIsLoading(false)
         }
      }).catch((error)=>{
        setIsLoading(false)
        console.log(error.message)
      })
    }
    async function fetchData() {
      if (currentQuestion === 10) {
        let selectedOptions = store.getState().steps.userChoice;
        if (
            store
                .getState()
                .steps.userChoice.maintenance.serverValue.includes("Maintenance")
        ) {
          productRequest.user_id = null;
          productRequest.phase = selectedOptions.maintenance.serverValue;
          productRequest.currentUsage = selectedOptions.currentUsage.serverValue;
        }
        if (
            store
                .getState()
                .steps.userChoice.maintenance.serverValue.includes("InControl")
        ) {
          productRequest.phase = selectedOptions.maintenance.serverValue;
          productRequest.currentUsage = selectedOptions.duration.serverValue;
          productRequest.productForm = selectedOptions.productForm.serverValue;
          productRequest.productFlavor = selectedOptions.flavour.serverValue;
          productRequest.isFinal = true;
          productRequest.user_id = null;
        }
        setIsLoading(true)
        try {
          const res: any = await getFilteredProducts(productRequest)
          if (res.status === 200) {
            trackEvents('on_productList_basedOn_prodPref', {availableProducts: res.data.result.map((p:any)=>p.productSku) })
            trackEventsPendo('on_productList_basedOn_prodPref', {availableProducts: res.data.result.map((p:any)=>p.productSku) })
            setProductData(res.data.result)
            setIsLoading(false)
          }
        } catch (error: any) {
          errorMessage = error.message
          setIsLoading(false)
        }
      }
    }
    fetchData()
  },[currentQuestion])
  let type = Object.keys(questionnaire)[currentQuestion];
  if(isLoading){
    return(
      <div className="h-[50vh] flex justify-center items-center">
        <Loader/>
      </div>
    )
  }
  if(errorMessage.length > 0){
    return(
      <div className="h-full flex justify-center items-center">
        {errorMessage}
      </div>
    )
  }
  switch (type) {
    case "gender":
      return (
          <AgeGender showIcon={true}/>
      );
    case "ageRange":
      return (
          <AgeGender showIcon={false}/>
      );
    case "currentUsage":
      return (
          <Usage />
      );
    case "motivations":          
      return(
        <>
        <p className="text-right text-xs text-[#595959] pr-6">Select all that apply</p>
        {/* <div className="flex flex-col gap-4"> */}
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 md:gap-5 gap-3">
           {
            questionnaire[type].Options.map((item:any)=>{
              return( 
                <div key={item.motivationId} >
                  <Why id={item.motivationId} value={item.motivationTitle} description={item.motivationDesc} image={item.motivationUrl}/>
                </div>
              )
           })
           }
        </div>
        </>
      )
    case "habbit_behaviour":
      return (
        <>
        <p className="text-right text-xs text-[#595959] pr-6">Select all that apply</p>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 ">
           {
            questionnaire[type].Options.map((item:any)=>{
              return(
                <div key={item.habbitBehaviorId}>
                  <Habbits id={item.habbitBehaviorId} value={item.habbitBehaviorTitle} description={item.habbitBehaviorDesc} image={item.habbitBehaviorUrl}/>
                </div>
              )
           })
           }
        </div>
        </>
      );
    case "myGoals":
      return (
        <>
        <p className="text-right text-xs text-[#595959] pr-6">Select all that apply</p>
        {/* <div className="flex flex-col gap-4"> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap:3 md:gap-5">
           {
            questionnaire[type].Options.map((item:any)=>{
              return(
                <div key={item.myGoalsId} className="">
                  <MyGoals id={item.myGoalsId} value={item.value} image={item.pictureUrl}/>
                </div>
              )
           })
           }
        </div>
        </>
      );
    case "nicotineReplacement":
      return (
        <div className="h-full">
          <Replacement />
        </div>
      );
    case "productForm":
      return (
        <div className="h-full">
          <ProductOptions />
        </div>
      );
    case "duration":
      return (
        <div className="h-full">
          <ProductOptions />
        </div>
      );
    case "productFlavour":
      return (
        <div className="h-full">
          <ProductOptions />
        </div>
      );
    case "product":
      return(
        <div className="grid grid-cols-2 px-2 gap-1">
          {productData?.map((item:Product ,index:number) =>{
            return(
              <ProductCard key={index} product={item} parentPath={ROUTES.QUESTIONCARD}/>
            )
          })}
        </div>
      )
    case "fixedBadge":
      return (
        <div className="h-full">
          <Badges />
        </div>
      );
    case "shareProgress":
      return (
        <div className="h-full">
          <ShareProgress />
        </div>
      );
    default:
      return (
        <div className="custom-h2 text-gray-400 flex justify-center items-center">
          Somthing went wrong
        </div>
      );
  }
}
