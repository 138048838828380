import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { InputField } from "../../common/InputField";
import footer from "../../assets/images/journey_logo.png";
import { userEmailIdRegex } from "../../common/utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getAppConfig, onLoginUser } from "../../api/onboarding";
import { resetState } from "../../api/common";
import Branding from "../../common/header/Branding";
import { trackEvents } from "../../common/utils/mixpanel";
import Footer from "../../common/footer";
import navlogo from "../../assets/images/nicoretteupNew.png";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import logo from "../../assets/images/journey_logo.png";
import { trackEventsPendo } from "../../common/utils/pendo";

export default function LoginComponent() {
    const isMobile= useCheckMobileScreen()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [checked, setChecked] = useState(false)
    let userProfileData = useSelector(
        (state: any) => state.steps.userProfileData
    );

    useEffect(() => {
        trackEvents('on_login_page')
        trackEventsPendo('on_login_page')
        getAppConfig()
    }, [])

    const handleChange = (e: any) => {
        setError('')
        setEmail(e.target.value)
    }
    const handlePasswordChange = (e: any) => {
        let value = e.target.value.trim()
        setError('')
        setPassword(value)
    }

    const handleGetStarted = () => {
        dispatch(resetState())
        navigate(ROUTES.GETSTARTED)
    }
    const handleLoginClick = async () => {
        if (email && password) {
            const result: boolean = userEmailIdRegex.test(email);
            if (result && password.length >= 8) {
                const payload: object = {
                    email_id: email,
                    password: password,
                    checkedIn: checked
                }
                try {
                    const result: any = await onLoginUser(payload)
                    console.log("result", result);
                    console.log("userProfileData", userProfileData, result?.data?.response?.loginDetail[0]?.sms_notification);
                    
                    
                    if (result.status === 200) {
                        trackEvents('login successful')
                        trackEventsPendo('login successful')
                        if (result?.data?.response?.loginDetail[0]?.sms_notification == false) {
                            if(isMobile){
                                navigate(ROUTES.OPTIN)
                            }
                            else{
                                navigate(ROUTES.DESKTOP_LANDING)
                            }
                            // navigate(ROUTES.SMS_TIME)
                        } else {
                            navigate(ROUTES.DASHBOARD)
                        }
                        /** code for navigating to notifications */
                    }
                } catch (e: any) {
                    setError(e ?? 'Something went wrong. Please try again.')
                }
            } else {
                if (!result) {
                    setError('Invalid Email Id.')
                } else if (password.length < 8) {
                    setError('Invalid Password')
                }
            }
        } else {
            setError('Email & Password is required.')
        }
    }

    return (
        <>
            <div className="bg-primary-blue flex min-h-screen flex-col  justify-between ">
                {/* nav for mobile */}
        <div className="flex sm:flex md:hidden  bg-custom-blue text-white h-[70px] px-5 justify-around items-center">
          <img src={navlogo} alt="cvs logo" className="h-[22px]" />
        </div>

        {/* nav for Desktop */}
        <div className="hidden px-10 md:flex  md:items-center md:justify-between bg-custom-blue text-white h-[80px]">
          <img src={navlogo} alt="Logo" />
        </div>
                {/* <Branding /> */}

                <div className="flex flex-col  justify-center items-center grow">
                <div>
            <img
              src={logo}
              alt="journey to freedom"
              className="h-[100px] md:h-[180px]  object-contain"
            />
          </div>
                <div className="px-4">           
                    <div className="flex justify-center px-5 py-2">
                        
                        {/* <h2 className="custom-h2 text-slate-700">Welcome back!</h2> */}
                        <p
              className="text-custom-dark-blue  custom-h1 text-center"
              style={{ fontSize: isMobile? "38px":"54px", fontWeight: 500,  }}
            >
              {" "}
              Welcome back!
            </p>
                    </div>
                    <div className="flex justify-center md:mb-5 md:mt-2 py-0">
                        {/* <p className="text-slate-700 custom-text text-sm">Login to your account</p> */}
                        <h2
              className="custom-h1 text-custom-dark-blue "
              style={{ fontSize: isMobile?'18px':"28px" }}
            >
              Login to your account
            </h2>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <InputField
                            type={'email'}
                            placeholderText={'Email Address'}
                            handleChange={handleChange}
                            className="px-4"
                            inputStyleClass={"form-input text-slate-700 md-h[50px]"}
                inputContainerClass={
                  "flex justify-end items-center  w-full m-auto"
                } 
                        />
                        <InputField
                            type={'password'}
                            value={password}
                            placeholderText={'Password'}
                            handleChange={handlePasswordChange}
                            className="px-4"
                            inputStyleClass="form-input text-slate-700"
                            inputContainerClass="flex justify-end items-center  w-full m-auto mt-3"
                             />
                    </div>
                    {error && <div className="flex justify-center">
                        <h5 className="text-secondary custom-text">{error}</h5>
                    </div>}
                    <div className="flex justify-end px-2">
                        <p className="custom-text text-slate-700 text-base text" onClick={() => { navigate(ROUTES.FORGOTPASSWORD) }}>Forgot password?</p>
                    </div>
                    <div className="flex justify-start px-6">
                        <InputField
                            type={'checkbox'}
                            inputStyleClass={"justify-start accent-secondary w-[18px] h-[18px] bg-sky-200 border-[#BFBFBF]"}
                            handleChange={() => setChecked(!checked)}
                            inputContainerClass={"flex justify-start items-center"}
                        />
                        <p className="custom-text text-slate-700 px-2 py-2">Stay signed in</p>
                    </div>
                    <div className="flex justify-end items-center max-w-[450px] w-full m-auto mt-3">
                        <button
                            className="subtitle-2 bg-secondary px-8 py-3 short:px-7 short:py-2 w-[340px] text-white rounded-full md:h-[60px] md:w-[450px]"
                            onClick={handleLoginClick}>
                            Login
                        </button>
                    </div>

                    {/* <div className="flex justify-center">
                        <hr className="border-[#8C8C8C] w-11/12" />
                    </div> */}

                    <div className="flex justify-center pt-3">
                        <span className="text-slate-700  custom-text py-1 px-3">New user? </span>
                        <button
                            className="text-secondary custom-text "
                            onClick={handleGetStarted}>
                            Get started
                        </button>
                    </div>
                </div>
                {/* <div className="flex justify-center items-center p-7 short:p-4 bg-sky-200">
                    <div>
                        <div className="flex justify-center">
                            <div className="" style={{ }}>
                                <img src={footer} alt="journey to freedom" className="h-[120px] object-contain"/>
                            </div>
                        </div>
                    </div>
                    
                </div> */}
                </div>
                {/* <Footer showBehaviouralText={true}/> */}
                <div className="bg-custom-dark-blue flex  w-full items-center justify-center text-center text-white md:h-[150px] h-[100px]">
          <Footer showLinks={true} showBehaviouralText={false} />
        </div>
            </div>
        </>
    );
}