import React, { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import Why from "../onboarding/Why";
import { CustomButton } from "../../common/CustomButton";
import MyGoals from "../onboarding/MyGoals";
import { RadioGroup } from "@headlessui/react";
import {
    gotoQuestion, saveAnswer, setGoalImages,
    setUserChoiceBadgeData,
    setUserChoiceMotivationData,
    setUserChoiceMyGoalsData
} from "../../slices/stepSlice";
import { editMotivationDetails, getUserDetails } from "../../api/dashboard";
import { get } from "../../api/common";
import { GETGOALIMAGE } from "../../api/constants";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import Footer from "../../common/footer";
import { trackEventsPendo } from "../../common/utils/pendo";

const MyMotivations = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userProfileData, questionnaire, userChoice } = useSelector((state: any) => state.steps);
    const [showMotivationScreen, setShowMotivationScreen] = useState(false)
    const [showMyGoalsScreen, setShowMyGoalsScreen] = useState(false)
    const [showBadgesScreen, setShowBadgesScreen] = useState(false)

    useEffect(() => {
        trackEvents('on_my_motivations_home')
        trackEventsPendo('on_my_motivations_home')
        getUserDetails({ email_id: userProfileData?.email })
        get(GETGOALIMAGE, { params: { gender: userProfileData?.gender, age: userProfileData?.age } }).then((res) => {
            if (res.status === 200 && res.data.response.length > 0) {
                dispatch(setGoalImages(res.data.response))
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }, [])
    useEffect(() => {
        dispatch(setUserChoiceMotivationData({
            serverValue: userProfileData?.motivationDetails.map((m: any) => m.id),
            selection: userProfileData?.motivationDetails.map((m: any) => m.motivationTitle)
        }))
        dispatch(setUserChoiceMyGoalsData({
            serverValue: userProfileData?.myGoalsDetails.map((m: any) => m.id),
            selection: userProfileData?.myGoalsDetails.map((m: any) => m.pictureName)
        }))
        dispatch(setUserChoiceBadgeData(userProfileData.Badges))
    }, [userProfileData])

    const handleSaveClick = async () => {
        showMotivationScreen ? trackEvents('updated_motivations', { selectedMotivations: userChoice?.motivations?.serverValue })
            : showMyGoalsScreen ? trackEvents('updated_goals', { selectedGoals: userChoice?.myGoals?.serverValue })
                : trackEvents('updated_my_buddy_image', { selectedBuddy: userChoice?.fixedBadge?.selection?.id })

        showMotivationScreen ? trackEventsPendo('updated_motivations', { selectedMotivations: userChoice?.motivations?.serverValue })
            : showMyGoalsScreen ? trackEventsPendo('updated_goals', { selectedGoals: userChoice?.myGoals?.serverValue })
                : trackEventsPendo('updated_my_buddy_image', { selectedBuddy: userChoice?.fixedBadge?.selection?.id })

        const payload = showMotivationScreen ? {
            userId: userProfileData?.id,
            motivations: userChoice?.motivations?.serverValue
        } : showMyGoalsScreen ? {
            userId: userProfileData?.id,
            my_goals: userChoice?.myGoals?.serverValue
        } : {
            userId: userProfileData?.id,
            badgeId: userChoice?.fixedBadge?.selection?.id
        }
        try {
            const result: any = await editMotivationDetails(payload)
            if (result.status === 200) {
                // showMotivationScreen ? setShowMotivationScreen(false) : showMyGoalsScreen? setShowMyGoalsScreen(false): setShowBadgesScreen(false)
                getUserDetails({ email_id: userProfileData?.email })
                setShowMotivationScreen(false)
                setShowMyGoalsScreen(false)
                setShowBadgesScreen(false)
            }
        } catch (e) {
            console.log('Error')
        }
    }
    const setSelectedBadge = (item: any) => {
        let response = {
            serverValue: "fixedBadge",
            selection: item,
        };
        dispatch(saveAnswer(response));
    }

    return <>
        {showMotivationScreen ? <div className="flex flex-col bg-primary h-full relative">
            <div className="bg-sky-200"><DashboardHeader menu={false} title="My Motivations" back={true}
                handleBack={() => {
                    setShowMotivationScreen(false)
                }} />
            </div>
            <div className="bg-white h-full overflow-clip">
                <div className={"py-4 px-6 flex flex-col gap-3"}>
                    <div className="custom-h2 text-primary">{questionnaire?.motivations?.Question}</div>
                    <div className="custom-text text-[#595959]">{questionnaire?.motivations?.Description}</div>
                </div>
                <div className={"basis-[70%] h-[70%] short:h-[62%] short:basis-2/3 overflow-auto relative"}>
                    <p className="text-right text-xs text-[#595959] pr-6">Select all that apply</p>
                    <div className=" h-full px-6 flex flex-col gap-4">
                        {
                            questionnaire?.motivations.Options.map((item: any) => {
                                return (
                                    <div key={item.motivationId}>
                                        <Why id={item.motivationId} value={item.motivationTitle}
                                            description={item.motivationDesc} image={item.motivationUrl} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="w-screen md:w-80 md:bottom-28 md:ml-8 bottom-0 fixed flex justify-center">
                <div className="flex justify-center left-2/4 basis-auto m-2">
                    <CustomButton label={'Save'} handleClick={() => { handleSaveClick() }} />
                </div>
            </div>
        </div> :
            showMyGoalsScreen ? <div className="flex flex-col bg-primary h-full relative">
                <div className="bg-sky-200"><DashboardHeader menu={false} title="My Motivations" back={true}
                    handleBack={() => {
                        setShowMyGoalsScreen(false)
                    }} />
                </div>
                <div className="bg-white h-full overflow-clip">
                    <div className={"py-4 px-6 flex flex-col gap-3"}>
                        <div className="custom-h2 text-primary">{questionnaire?.myGoals?.Question}</div>
                        <div className="custom-text text-[#595959]">{questionnaire?.myGoals?.Description}</div>
                    </div>
                    <div className={"basis-[70%] h-[70%] short:h-[55%] short:basis-2/3 overflow-auto relative"}>
                        <p className="text-right text-xs text-[#595959] pr-6">Select all that apply</p>
                        <div className=" h-full px-6 flex flex-col gap-4">
                            {
                                questionnaire?.myGoals.Options.map((item: any) => {
                                    return (
                                        <div key={item.myGoalsId}>
                                            <MyGoals id={item.myGoalsId} value={item.value} image={item.pictureUrl} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="w-screen md:w-80 md:bottom-28 md:ml-8 bottom-0 fixed flex justify-center">
                    <div className="flex justify-center left-2/4 basis-auto m-2">
                        <CustomButton label={'Save'} handleClick={() => { handleSaveClick() }} />
                    </div>
                </div>
            </div> :
                showBadgesScreen ? <div className="flex flex-col  h-full relative">
                    <div className="bg-sky-200">
                        <DashboardHeader menu={false} title="My Motivations" back={true}
                            handleBack={() => setShowBadgesScreen(false)} />
                    </div>
                    <div className="bg-white overflow-scroll pb-[60px]">
                        <div className={"py-4 px-6 flex flex-col gap-3"}>
                            <div className="custom-h2 text-primary">{questionnaire?.fixedBadge?.Question}</div>
                            <div className="custom-text text-[#595959]">{questionnaire?.fixedBadge?.Description}</div>
                        </div>
                        <div className={"overflow-scroll relative"}>
                            <div className=" h-full px-6 flex flex-col gap-4">
                                <div key={0}>
                                    <div className="flex flex-col h-full gap-2">
                                        <RadioGroup value={userChoice.fixedBadge["selection"]?.id}>
                                            <div className="grid grid-cols-2 gap-3">
                                                {questionnaire?.fixedBadge.Options.map(
                                                    (item: any, index: any, array: any[]) => {
                                                        return (
                                                            <RadioGroup.Option
                                                                value={item.id}
                                                                key={index}
                                                                onClick={() => { setSelectedBadge(item) }}
                                                            >
                                                                {({ checked }) => (
                                                                    <div
                                                                        className={
                                                                            checked
                                                                                ? "h-[124px]  border-[1.5px] border-secondary rounded-lg flex justify-center items-center py-[5px] relative"
                                                                                : "h-[124px]  flex justify-center items-center border border-[#BFBFBF] rounded-lg py-[5px] relative"
                                                                        }
                                                                    >
                                                                        <div className="absolute top-1 left-1">
                                                                            <input
                                                                                type="radio"
                                                                                checked={checked}
                                                                                onChange={() => !checked}
                                                                                className="accent-secondary w-[20px] h-[20px] m-1"
                                                                            />
                                                                        </div>
                                                                        <div className="flex justify-center h-full">

                                                                            <img
                                                                                src={item.batchUrl}
                                                                                alt="icon"
                                                                                className={"block"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </RadioGroup.Option>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-screen md:w-80 md:bottom-28 md:ml-8 bottom-0 fixed flex justify-center bg-white">
                        <div className="flex justify-center left-2/4 basis-auto m-2">
                            <CustomButton label={'Save'} handleClick={() => { handleSaveClick() }} />
                        </div>
                    </div>
                </div> : <div>
                    <DashboardHeader back={true} handleBack={() => {
                        navigate(ROUTES.DASHBOARD)
                    }} />
                    <div className="bg-white h-full px-[10px] pt-[10px]">
                        <div className="flex justify-between px-[20px]">
                            <p className="custom-h2">{'My Motivations'}</p>
                            <span className="fa fa-pencil text-[#FF0000]" onClick={() => {
                                trackEvents('on_my_motivations')
                                trackEventsPendo('on_my_motivations')
                                dispatch(gotoQuestion(3))
                                setShowMotivationScreen(true)
                            }} />
                        </div>
                        <div className="flex overflow-auto py-[10px] px-[10px]">
                            {userProfileData?.motivationDetails?.length ? userProfileData?.motivationDetails.map((option: any) => {
                                return <div className="px-[5px] h-[240px]">
                                    <div
                                        className="h-[240px] w-[150px] justify-center items-center border border-[#BFBFBF] rounded-lg py-[16px] px-[10px]">
                                        <img src={option.motivationUrl} />
                                        <p>{option.motivationTitle}</p>
                                        <p className="custom-smallText">{option.motivationDesc}</p>
                                    </div>
                                </div>
                            }) :
                                <p className="custom-subtitle px-[20px]">{'No motivations are selected. Please click on edit to update.'}</p>
                            }
                        </div>
                    </div>
                    <div className="h-[10px] w-full bg-[#F5F5F5]" />
                    <div className="bg-white h-full px-[10px] pt-[10px]">
                        <div className="flex justify-between px-[20px]">
                            <p className="custom-h2">{'My Goals'}</p>
                            <span className="fa fa-pencil text-[#FF0000]" onClick={() => {
                                trackEvents('on_my_goals')
                                trackEventsPendo('on_my_goals')
                                dispatch(gotoQuestion(5))
                                setShowMyGoalsScreen(true)
                            }} />
                        </div>
                        <div className="flex overflow-auto py-[10px] px-[10px]">
                            {userProfileData?.myGoalsDetails.length ? userProfileData?.myGoalsDetails.map((option: any) => {
                                return <div className="px-[5px] h-[200px]">
                                    <div
                                        className="h-[200px] w-[165px] justify-center items-center border border-[#BFBFBF] rounded-lg">
                                        <img style={{ height: '106px', width: '165px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                                            src={option.pictureUrl} />
                                        <p className="flex justify-center text-center custom-text pt-[5px]">{option?.pictureName}</p>
                                    </div>
                                </div>
                            }) :
                                <p className="custom-subtitle px-[20px]">{'No goals are selected. Please click on edit to update.'}</p>
                            }
                        </div>
                    </div>
                    <div className="h-[10px] w-full bg-[#F5F5F5]" />
                    <div className="bg-white h-full px-[10px] pt-[10px]">
                        <div className="flex justify-between px-[20px]">
                            <p className="custom-h2">{'My Buddy'}</p>
                            <span className="fa fa-pencil text-[#FF0000]" onClick={() => {
                                trackEvents('on_my_buddy')
                                trackEventsPendo('on_my_buddy')
                                dispatch(gotoQuestion(11))
                                setShowBadgesScreen(true)
                            }} />
                        </div>
                        {userProfileData?.Badges && <div className="flex py-[10px] px-[15px]">
                            <div
                                className="flex h-[110px] w-[150px] text-center justify-center items-center border border-[#BFBFBF] rounded-lg">
                                <img className="h-[100px] w-[100px]" src={userProfileData?.Badges?.batchUrl} />
                            </div>
                        </div>}
                    </div>
                    <Footer />
                </div>}
    </>
}
export default MyMotivations