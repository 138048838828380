import React, {Fragment, useEffect, useState} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { resetState } from "../../api/common"
import { resetPassword } from "../../api/onboarding"
import { CustomButton } from "../../common/CustomButton"
import { InputField } from "../../common/InputField"
import { ROUTES } from "../../routes"
import { store } from "../../store"
import DashboardHeader from "../dashboard/DashboardHeader"
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel"
import { trackEventsPendo } from "../../common/utils/pendo"

export default function ChangePassword() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let [isOpen, setIsOpen] = useState(false);

    const [error,setError] = useState<any>({validateOld:"",validateNew:"",validateConfirm:""})
    const [oldPassword ,setOldPassword] = useState("")
    const [newPassword ,setNewPassword] = useState("")
    const [confirmPassword,setConfirmPassword] = useState("")

    useEffect(()=>{
        trackEvents('on_change_password')
        trackEventsPendo('on_change_password')
    },[])

    let getUserId = store.getState().steps.userProfileData.id

    function handleOldPasswordChange(e:any){
            setOldPassword(e.target.value)
    }
    function handleNewPasswordChange(e:any){
            setNewPassword(e.target.value)
    }
    function handleConfirmPasswordChange(e:any){
            setConfirmPassword(e.target.value)
    }
    function closeModal() {
        setIsOpen(false);
        dispatch(resetState())
        localStorage.clear()
        sessionStorage.clear()
        navigate(ROUTES.LOGIN)
      }
    function handleSubmit(){
        if(newPassword === oldPassword){

          setError((prev:any) =>{
            return {
              ...prev,
              validateNew:"Old password and new password are same. Request failed"
            }})
            return
        }
        if(newPassword === confirmPassword){
            trackEvents('updated_password')
            trackEventsPendo('updated_password')
            let payload = {userId:getUserId,oldPassword:oldPassword,password:newPassword}
            resetPassword(payload).then((res:any) =>{
                if(res.status === 200 && res.data.response ==="success"){
                    setIsOpen(true)
                }
                else if(res.status === 200 && res.data.response.response ==="old passowrd incorrect"){

                    setError((prev:any) =>{
                       return {
                         ...prev,
                         validateOld:"Wrong Password"
                       }})
                }
            }).catch((error) => console.log(error))
        }
        else{
            setError( (prev:any) =>{
               return {
                 ...prev,
                 validateConfirm:"Password and confirm password does not match"
               }
            })
        }
    }

    return (
        <div className={"h-full bg-white"}>
            <div className="myContactsDashboardContainer">
                <DashboardHeader back={true} title="My Account" handleBack={() => {
                    navigate(ROUTES.HOME)
                }}/>
            </div>
            <div className="pageHeader">
                <p className="custom-h3 pageHeaderText">
                    {'Change my Password'}
                </p>
            </div>
            <div className="myContactsContainer">
                <div className="h-full bg-white">
                    <div className={"flex justify-center"}>
                        <InputField
                            type={'password'}
                            placeholderText={'Enter Old Password*'}
                            inputStyleClass="px-[32px] short:w-[320px] short:py-[10px] short:px-[28px] py-[15px] w-[340px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                            handleChange={handleOldPasswordChange}/>
                        {error && error.validateOld.length > 0 &&
                        <div className="text-red-500">{error.validateOld}</div>}
                    </div>
                    <div className="w-full my-2">
                        <hr/>
                    </div>
                    <div className={"flex justify-center"}>
                        <InputField
                            type={'password'}
                            placeholderText={'Enter New Password*'}
                            inputStyleClass="px-[32px] short:w-[320px] short:py-[10px] short:px-[28px] py-[15px] w-[340px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                            handleChange={handleNewPasswordChange}/>
                        {error && error.validateNew.length > 0 &&
                        <div className="text-red-500 text-[12px]">{error.validateNew}</div>}
                    </div>
                    <div className={"flex justify-center"}>
                        <InputField
                            type={'password'}
                            placeholderText={'Confirm New Password*'}
                            inputStyleClass="px-[32px] short:w-[320px] short:py-[10px] short:px-[28px] py-[15px] w-[340px] border-[0.75px] text-[#434343] rounded-full border-[#8C8C8C]"
                            handleChange={handleConfirmPasswordChange}/>
                        {error && error.validateConfirm.length > 0 &&
                        <div className="text-red-500">{error.validateConfirm}</div>}
                    </div>
                </div>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25"/>
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Password Updated Successfully
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={closeModal}
                                            >
                                                Ok!
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
            <div className="myContactsButtonContainer">
                <div className="myContactsButtonDiv">
                    <CustomButton handleClick={handleSubmit} label="Save"
                                  isDisabled={newPassword.length >= 8 && oldPassword.length >= 8 && confirmPassword.length >= 8 ? false : true}
                                  buttonStyleClass="flex justify-center disabled:bg-[#E4E4E4] disabled:text-[#434343] subtitle-2 bg-secondary text-white py-[10px] px-[24px] rounded-full w-[200px]"/>
                </div>
            </div>
        </div>
    )
}
