import Drawer from "react-modern-drawer";
import closeWhiteIcon from "../assets/icons/closeWhiteIcon.svg";
import accounts from "../assets/icons/accounts.svg";
import smiley from "../assets/icons/smiley.svg";
import settings from "../assets/icons/settings.svg";
import help from "../assets/icons/help.svg";
import {ROUTES} from "../routes";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setIsLeftDrawerOpen} from "../slices/dashboardSlice";
import Footer from "./footer";

const LeftMenu=()=> {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    let isLeftDrawerOpen = useSelector(
        (state: any) => state.dashboard.isLeftDrawerOpen
    );
    let userProfileData = useSelector(
        (state: any) => state.steps.userProfileData
    );
    const toggleMenuDrawer = () => {
        dispatch(setIsLeftDrawerOpen(!isLeftDrawerOpen))
    }

    const MenuComponent = (props: any) => {
        const {leftIcon, title, handleMenuClick, isLastOption = false} = props
        return <div style={isLastOption ? {height: '77px', display: 'flex'} : {
            height: '60px',
            display: 'flex',
            borderBottom: '1px solid #D9D9D9'
        }} onClick={handleMenuClick}>
            <div className="flex items-center px-[10px]">
                <img src={leftIcon} alt="LeftIcon"/>
            </div>
            <div className="py-[10px] w-[80%] flex justify-center flex-col">
                <p style={{
                    fontSize: '14px',
                    fontWeight: 400
                }}>{title}</p>
            </div>
        </div>
    }

    const handleMenuClick = (title: string) => {
        dispatch(setIsLeftDrawerOpen(false));
        switch (title) {
            case 'Help':
                navigate(ROUTES.QUICKACCESSTUTORIAL)
                break;
            case 'Motivations':
                navigate(ROUTES.MYMOTIVATIONS)
                break;
            case 'MyAccount':
                navigate(ROUTES.HOME)
                break;
            case 'Products':
                navigate(ROUTES.PRODUCTS)
                break;
            case 'Settings':
                    navigate(ROUTES.SETTING)
                    break;
            default:
                navigate(ROUTES.DASHBOARD)
        }
    }

    return <div className="md:hidden">
        <Drawer
            open={isLeftDrawerOpen}
            onClose={toggleMenuDrawer}
            direction='left'
            size={280}
        >
            <div className="flex flex-col h-[100dvh] ">
                <div className="bg-sky-200 h-[200px]">
                    <div className={"px-[20px] py-[20px]"} onClick={() => {
                        toggleMenuDrawer()
                    }}><img src={closeWhiteIcon}/></div>
                    <div style={{height: '80%', position: 'relative'}}>
                        <div style={{position: 'absolute', bottom: 0, padding: '20px', width: '100%'}}>
                            <p className="custom-h2 text-left text-slate-700">{userProfileData?.user_name}</p>
                            <div className="flex justify-between">
                                <p className="custom-smallText text-left text-slate-700" style={{overflowWrap:'anywhere'}}>{userProfileData?.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: '30px'}}>
                    <div>
                        <MenuComponent leftIcon={accounts} title={'My Account'} handleMenuClick={() => {
                            handleMenuClick('MyAccount')
                        }}/>
                        {/* <MenuComponent leftIcon={shoppingBag} title={'Products'} handleMenuClick={() => {
                            handleMenuClick('Products')
                        }}/> */}
                        <MenuComponent leftIcon={smiley} title={'My Motivations'} handleMenuClick={() => {
                            handleMenuClick('Motivations')
                        }}/>
                        <MenuComponent leftIcon={settings} title={'Settings'} handleMenuClick={() => {
                            handleMenuClick('Settings')
                        }}/>
                        <MenuComponent leftIcon={help} title={'Help'} handleMenuClick={() => {
                            handleMenuClick('Help')
                        }}/>
                    </div>
                  
                </div>
                <Footer styles={{fontSize:'10px',padding:'0 5px',marginBottom:'5px'}}/>
            </div>
        </Drawer>
    </div>
}
export default LeftMenu