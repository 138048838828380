import useCheckMobileScreen from "../useCheckMobileScreen";
import Copyright from "./copyright";

const Footer = ({
  showLinks = true,
  styles = {},
  showBehaviouralText = false,
}) => {
  const isMobile = useCheckMobileScreen();
  return (
    <div className="">
      {showLinks && (
        <div className="flex justify-center items-center">
          <a
            href="https://www.privacy.haleon.com/en-us/general/general-full-text/"
            target="_blank"
            className={`px-[10px] text-[#999] border-r-[1px] border-[#999] uppercase md:text-[18px] text-[10px]`}
            rel="noreferrer"
            style={styles}
          >
            Privacy Notice
          </a>
          <a
            className={`px-[10px] text-[#999] border-r-[1px] border-[#999] uppercase md:text-[18px] text-[10px]`}
            target="_blank"
            href="https://haleon-privacy.my.onetrust.com/webform/9ff761e6-6f16-4e20-800d-92706b153697/aab0d980-b305-4dc6-ac75-08f4a44b3442"
            rel="noreferrer"
            style={styles}
          >
            privacy Choices
          </a>
          <a
            className={`px-[10px] text-[#999] uppercase md:text-[18px] text-[10px]`}
            target="_blank"
            href="https://terms.haleon.com/en-us/"
            rel="noreferrer"
            style={styles}
          >
            Terms of Use
          </a>
        </div>
      )}
      {showBehaviouralText ? (
<>
<p className="my-[5px] px-[10px] text-center md:text-[18px] text-[10px]  text-[#999] ">
            Behavioral support program increases chances of success. Use as
            directed. {!isMobile && <><br/></>}The content of this website is intended for US audiences
            only.©2023 Haleon group of companies or its licensor. All rights
            reserved.
          </p>
</>
       
      ) : (
        <Copyright />
      )}
    </div>
  );
};

Footer.propTypes = {};

export default Footer;