import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../common/spinner/Loader";
import { Product } from "../../types/product";
import ProductCard from "../../common/productCard/productCard";
import DashboardHeader from "../dashboard/DashboardHeader";
import { ROUTES } from "../../routes";
import { useNavigate } from "react-router-dom";
import nico from "../../assets/images/nico_brand.png";
import { getFilteredProducts } from "../../api/onboarding";
import mixpanel from "mixpanel-browser";
import { trackEvents } from "../../common/utils/mixpanel";
import { trackEventsPendo } from "../../common/utils/pendo";

const Products = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [productData, setProductData] = useState([])
    let errorMessage = ""
    let productRequest: any = {};

    const { questionnaire, userProfileData } = useSelector(
        (state: any) => state.steps
    );

    useEffect(() => {
        async function fetchData() {
            try {
                if (questionnaire?.nicotineReplacement?.Options.find((f: any) => f.name === userProfileData.maintainance)?.serverValue === 'Maintenance') {
                    productRequest.phase = questionnaire.nicotineReplacement?.Options?.find((f: any) => f.name === userProfileData.maintainance)?.serverValue ?? '';
                    productRequest.currentUsage = questionnaire?.currentUsage?.Options?.find((f: any) => f.name === userProfileData.currentUsage)?.payloadValue ?? '';
                    productRequest.user_id = userProfileData?.id
                } else {
                    productRequest.phase = questionnaire?.nicotineReplacement?.Options?.find((f: any) => f.name === userProfileData.maintainance)?.serverValue ?? '';
                    productRequest.currentUsage = questionnaire?.duration?.Options?.find((f: any) => f.value === userProfileData.duration)?.serverValue ?? '';
                    productRequest.productForm = questionnaire?.productForm?.Options?.find((f: any) => f.value === userProfileData.productForm)?.serverValue ?? '';
                    productRequest.productFlavor = userProfileData?.flavour;
                    productRequest.isFinal = true;
                    productRequest.user_id = userProfileData?.id
                }
                setIsLoading(true)
                const res: any = await getFilteredProducts(productRequest)
                if (res.status === 200) {
                    trackEvents('on_product_available_under_myAccount', { availableProducts: res.data.result.map((p: any) => p.productSku) })
                    trackEventsPendo('on_product_available_under_myAccount', { availableProducts: res.data.result.map((p: any) => p.productSku) })

                    setProductData(res.data.result)
                    setIsLoading(false)
                }
            } catch (error: any) {
                errorMessage = error.message
                setIsLoading(false)
            }
        }
        fetchData()
    }, [])

    if (isLoading) {
        return (
            <div className="h-full flex justify-center items-center">
                <Loader />
            </div>
        )
    }
    if (errorMessage.length > 0) {
        return (
            <div className="h-full flex justify-center items-center">
                {errorMessage}
            </div>
        )
    }
    return <div>
        <div className="dashboardContainer">
            <DashboardHeader back={true} title="Products" handleBack={() => { navigate(ROUTES.DASHBOARD) }} />
        </div>
        <div className="grid grid-cols-2 py-4 px-2 gap-1">
            {productData?.map((item: Product, index: number) => {
                return (
                    <ProductCard key={index} product={item} parentPath={ROUTES.PRODUCTS} />
                )
            })}
        </div>
        
        <div className="flex justify-center">
            <img src={nico} alt="Nicorette Brand" className="p-2" />
        </div>
    </div>
}
export default Products;